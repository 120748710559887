import { banners_collection, sccorners_collection } from "../../../../../../assets/invitationcard"//sconesided_collection
import { banners, corners } from "../../../../../../assets/invitationcard/index1"

const TOP_PLACE_HOLDER = `SEATING ARRANGEMENT`

const MAIN_PLACE_HOLDER = `Alice & Bob`

const T0_TABLETEMPLATEFORMAT_CSS = {
    '3_0': { display: 'grid', gridTemplateColumns: '50% 50%', gridTemplateRows: '50% 50%', height: '100%' },
    '3_1': { display: 'grid', gridTemplateColumns: '33.33% 33.33% 33.33%', height: '100%' },
    '4_0': { display: 'grid', gridTemplateColumns: '50% 50%', height: '100%' },
    '5_0': { display: 'grid', gridTemplateColumns: '33.33% 33.33% 33.33%', height: '100%' },
    '6_0': { display: 'grid', gridTemplateColumns: '33.33% 33.33% 33.33%', height: '100%' },
    '7_0': { display: 'grid', gridTemplateColumns: '33.33% 33.33% 33.33%', height: '100%' },
    '8_0': { display: 'grid', gridTemplateColumns: '25% 25% 25% 25%', gridTemplateRows: '50% 50%', height: '100%' },
    '8_1': { display: 'grid', gridTemplateColumns: '33.33% 33.33% 33.33%', gridTemplateRows: '33.33% 33.33% 33.33%', height: '100%' },
    '9_0': { display: 'grid', gridTemplateColumns: '33.33% 33.33% 33.33%', gridTemplateRows: '33.33% 33.33% 33.33%', height: '100%' },
    '9_1': { display: 'grid', gridTemplateColumns: '25% 25% 25% 25%', gridTemplateRows: '33.33% 33.33% 33.33%', height: '100%' },
    '10_0': { display: 'grid', gridTemplateColumns: '25% 25% 25% 25%', gridTemplateRows: '33.33% 33.33% 33.33%', height: '100%' },
    '11_0': { display: 'grid', gridTemplateColumns: '25% 25% 25% 25%', gridTemplateRows: '33.33% 33.33% 33.33%', height: '100%' },
    '12_0': { display: 'grid', gridTemplateColumns: '25% 25% 25% 25%', gridTemplateRows: '33.33% 33.33% 33.33%', height: '100%' },
}
const ASSIGN_CSS = { // styling of each assigned tablecss or seatcss based on template type
    '3_0': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 30, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Satisfy', fontSize: 19, fontWeight: '', fontStyle: '', textDecoration: '', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '3_1': { // might not need this template
        'tablecss': { fontFamily: "Great Vibes", fontSize: 35, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Kaushan Script', fontSize: 30, fontWeight: '', fontStyle: '', textDecoration: '', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '4_0': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 33, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Satisfy', fontSize: 20, fontWeight: '', fontStyle: '', textDecoration: '', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '5_0': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 33, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Satisfy', fontSize: 19, fontWeight: '', fontStyle: '', textDecoration: '', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '6_0': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 30, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Satisfy', fontSize: 19, fontWeight: '', fontStyle: '', textDecoration: '', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '7_0': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 25, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Satisfy', fontSize: 13, fontWeight: '', fontStyle: '', textDecoration: '', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '8_0': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 30, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Satisfy', fontSize: 20, fontWeight: '', fontStyle: '', textDecoration: '', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '8_1': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 20, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Nanum Myeongjo', fontSize: 13, fontWeight: 'bold', fontStyle: '', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '9_0': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 20, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Nanum Myeongjo', fontSize: 13, fontWeight: 'bold', fontStyle: '', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '9_1': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 20, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Nanum Myeongjo', fontSize: 13, fontWeight: 'bold', fontStyle: '', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '10_0': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 20, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Nanum Myeongjo', fontSize: 13, fontWeight: 'bold', fontStyle: '', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '11_0': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 20, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Nanum Myeongjo', fontSize: 13, fontWeight: 'bold', fontStyle: '', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 }
    },
    '12_0': {
        'tablecss': { fontFamily: "Dancing Script", fontSize: 20, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 },
        'seatcss': { fontFamily: 'Nanum Myeongjo', fontSize: 13, fontWeight: 'bold', fontStyle: '', textDecoration: 'none', padding: 0, color: '#000000', letterSpacing: 0 }
    }
}

const RANDOM_NAMES = ['Jing Yi', 'Yi Wei', 'Jun Kai', 'Jia Wei', 'Mary', 'Xiao Ming', 'Xiao Hua', 'Shantha', 'Shanti', 'Jasper', 'Jonathan', 'Lim Jun Jie', 'Andy', 'Ali', 'Nur Fadilah', 'Yusof', 'Haliq', 'Hui Ming', 'Jia Ming', 'James', 'Shabir', 'Prem Kumar']

const BGCOLOR = [
    '#ffffff',
    '#fffef8',
    '#fffbf7',
    '#2a3044',
    '#576d7e',
    '#000000',

]

const FONTCOLOR = [
    '#ffffff',
    '#707070',
    '#000000',
]

const TEMPLATE_SHOWCASING_ARR = [
    {
        cornerimg: corners[1],
        banner: banners[1],
        topff: "Nanum Myeongjo",
        mainff: "Dancing Script",
        tableff: "Dancing Script",
        seatsff: "Nanum Myeongjo",
        topfc: '#000000',
        mainfc: '#000000',
        tablefc: '#000000',
        seatsfc: '#000000',
        templatecolor: '#fffff8',
        floralplacement: 0,
        jccanva: 'flex-start',
        aligncanva: 'flex-start',
    },
    {
        cornerimg: corners[2],
        banner: banners[2],
        topff: "Nanum Myeongjo",
        mainff: "Dancing Script",
        tableff: "Dancing Script",
        seatsff: "Nanum Myeongjo",
        topfc: '#3e5d37',
        mainfc: '#3e5d37',
        tablefc: '#3e5d37',
        seatsfc: '#3e5d37',
        templatecolor: '#fffef8',
        floralplacement: 0,
        jccanva: 'flex-start',
        aligncanva: 'flex-start'
    },
    {
        cornerimg: corners[3],
        banner: banners[3],
        topff: "Nanum Myeongjo",
        mainff: "Dancing Script",
        tableff: "Dancing Script",
        seatsff: "Nanum Myeongjo",
        topfc: '#000000',
        mainfc: '#000000',
        tablefc: '#000000',
        seatsfc: '#000000',
        templatecolor: '#fffef8',
        floralplacement: 0,
        jccanva: 'flex-start',
        aligncanva: 'flex-start'
    },
    {
        cornerimg: corners[4],
        banner: banners[4],
        topff: "Nanum Myeongjo",
        mainff: "Dancing Script",
        tableff: "Dancing Script",
        seatsff: "Nanum Myeongjo",
        topfc: '#686665',
        mainfc: '#686665',
        tablefc: '#686665',
        seatsfc: '#686665',
        templatecolor: '#fffef8',
        floralplacement: 0,
        jccanva: 'flex-start',
        aligncanva: 'flex-start'
    },
    {
        cornerimg: corners[5],
        banner: banners[5],
        topff: "Nanum Myeongjo",
        mainff: "Dancing Script",
        tableff: "Dancing Script",
        seatsff: "Nanum Myeongjo",
        topfc: '#ac1c1d',
        mainfc: '#ac1c1d',
        tablefc: '#ac1c1d',
        seatsfc: '#ac1c1d',
        templatecolor: '#fffbf7',
        floralplacement: 0,
        jccanva: 'center',
        aligncanva: 'flex-start'
    },
    {
        cornerimg: corners[9],
        banner: banners[9],
        topff: "Nanum Myeongjo",
        mainff: "Dancing Script",
        tableff: "Dancing Script",
        seatsff: "Nanum Myeongjo",
        topfc: '#e1d9ca',
        mainfc: '#e1d9ca',
        tablefc: '#e1d9ca',
        seatsfc: '#e1d9ca',
        templatecolor: '#2a3044',
        floralplacement: 0,
        jccanva: 'center',
        aligncanva: 'flex-start'
    },
    {
        cornerimg: corners[12],
        banner: banners[12],
        topff: "Playfair Display",
        mainff: "Great Vibes",
        tableff: "Great Vibes",
        seatsff: "Playfair Display",
        topfc: '#f5f5f5',
        mainfc: '#f5f5f5',
        tablefc: '#f5f5f5',
        seatsfc: '#f5f5f5',
        templatecolor: '#576d7e',
        floralplacement: 0,
        jccanva: 'center',
        aligncanva: 'flex-start'
    }
]

const FORMAT_SHOWCASE_MAP = {
    '3_0': {
        tabletitlefs: 30,
        seatsfs: 20
    },
    '3_1': {
        tabletitlefs: 30,
        seatsfs: 20
    },
    '4_0': {
        tabletitlefs: 30,
        seatsfs: 20
    },
    '5_0': {
        tabletitlefs: 30,
        seatsfs: 20
    },
    '6_0': {
        tabletitlefs: 30,
        seatsfs: 20
    },
    '7_0': {
        tabletitlefs: 23,
        seatsfs: 13
    },
    '8_0': {
        tabletitlefs: 23,
        seatsfs: 13
    },
    '8_1': {
        tabletitlefs: 23,
        seatsfs: 13
    },
    '9_0': {
        tabletitlefs: 23,
        seatsfs: 13
    },
    '9_1': {
        tabletitlefs: 23,
        seatsfs: 13
    },
    '10_0': {
        tabletitlefs: 23,
        seatsfs: 13
    },
    '11_0': {
        tabletitlefs: 23,
        seatsfs: 13
    },
    '12_0': {
        tabletitlefs: 23,
        seatsfs: 13
    },
    '13_0': {
        tabletitlefs: 17,
        seatsfs: 9
    },
    '14_0': {
        tabletitlefs: 17,
        seatsfs: 9
    },
    '15_0': {
        tabletitlefs: 17,
        seatsfs: 9
    },
    '16_0': {
        tabletitlefs: 17,
        seatsfs: 9
    },
}

export {
    TOP_PLACE_HOLDER,
    MAIN_PLACE_HOLDER,
    T0_TABLETEMPLATEFORMAT_CSS,
    RANDOM_NAMES,
    BGCOLOR,
    FONTCOLOR,
    ASSIGN_CSS,
    TEMPLATE_SHOWCASING_ARR,
    FORMAT_SHOWCASE_MAP
}