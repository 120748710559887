import React, { useEffect, useState } from 'react'
import { EMPTYSPACE, EMPTY, production, STARTUP_NAME, WEBSITE_URL } from '../../../../../../config/constants'
import { Calendar, Modcfm, Timelineobj } from '../../../../../../components'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { ADD_ICON, BLOCKLARGE_ICON, CROSS_ICON, DRAG_ICON, PENCIL_ICON, CROSSPENCIL_ICON } from '../../../../../../config/svgicons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { input_dom, inputdescribe_dom, multiplechoice_dom, partialtitle_dom, textarea_dom, twobtn_dom } from '../Commonelements/Commonelements'
import Explain from './../Explain'
import "react-datepicker/dist/react-datepicker.css";
import './../../Invitationedit.css'
import '../components.css'
import './Schedules.css'

const input_css = { marginLeft: 0, marginRight: 0, width: '100%', margin: 0 }
const txtarea_css = { marginLeft: 0, marginRight: 0, width: '100%', margin: 0 }
const inputhalf_css = { width: '100%', margin: 0, }
const dragsvgcss = { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5 }
const crosssvgcss = { ...dragsvgcss, color: 'var(--mainpink)' }
const blocksvgcss = { ...dragsvgcss, color: 'var(--subdark)' }
const addsvgcss = { width: 23, height: 23, display: 'flex', placeContent: 'center', }

const TITLE = 'Schedules'
const TEXT = `Create your event's schedules, give your guests a guide of what to expect at the event.`

const DEFAULT_PAGE = 0
const SWAP_PAGE = 1
const CREATE_PAGE = 2
const EDIT_PAGE = 3

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'white',
    padding: isDraggingOver ? 5 : 0,
});




// const defaultmapobj = { mapstate: false, title: '', descript: '', lat: '1.3521', long: '103.8198', url: '' }
const defaultmapobj = { mapstate: false, maptype: 'GOOGLE', googleiframesrc: '', title: '', locdescript: '', leafletlat: '1.123', leafletlong: '1.223', leafletlocurl: '', leafletmapmode: 0 }
const defaultitineraryobj = { itinerarystate: false, title: '', link: '', url: '', linkorurl: '' }
const defaultwhocanviewkey = 'ANYONE'

function Schedules(props) {
    const { device, partialwt, showsave, setShowsave, updateBackup, clearBackup, restoreBackup, updateSchedules, retrieveitineraries, guestids, guestid_guestinfo_map } = props
    const [firsttime, setFirsttime] = useState(true)
    const [page, setPage] = useState(DEFAULT_PAGE) // 0 : default schedule page, 1 : swapping page, 2 : create schedule page, 3 : openedit page

    // single schedule object
    const [schedules, setSchedules] = useState([])
    const [date_s, setDate_s] = useState(EMPTY) // date start
    const [date_e, setDate_e] = useState(EMPTY) // date end
    const [time_s, setTime_s] = useState(EMPTY)
    const [time_e, setTime_e] = useState(EMPTY)
    const [name, setName] = useState(EMPTY)
    const [content, setContent] = useState(EMPTY)
    const [whocanviewkey, setWhocanviewkey] = useState(defaultwhocanviewkey)
    const [selectedguestids, setSelectedguestids] = useState([])// ['ALL VISITORS'], ['All'] or ['some uuid', 'some uuid', ... ]  // need to first check the first element to see if is All
    const [mapobj, setMapobj] = useState(defaultmapobj)
    const [itineraryobj, setItineraryobj] = useState(defaultitineraryobj)
    const [editindex, setEditindex] = useState(-1) // index that user want to openedit
    const [rmvcfm, setRmvcfm] = useState(false) // user click cfm or not cfm delete
    const [rmvindex, setRmvindex] = useState(-1) // index to remove
    const [showrmvmod, setShowrmvmod] = useState(false) // toggle rmv modal to qn user
    const [itineraries, setItineraries] = useState([])

    // for schedule edit and create
    const [cat_guestid_map, setCat_guestid_map] = useState({})
    const [grp_guestid_map, setGrp_guestid_map] = useState({})
    const [whocanviewmode, setWhocanviewmode] = useState('All') // All, CAT, GRP

    useEffect(() => {
        if (firsttime) {
            setSchedules(props.schedules)
            setFirsttime(false)
            setPage(DEFAULT_PAGE)
            setShowsave(false)

            retrieveitineraries().then((result) => {
                var urls = []
                var pcs = [] // passcode
                for (let i = 0; i < result.length; i++) {
                    const { urlhandler, passcode } = result[i]
                    urls.push(WEBSITE_URL + '/timelinelookup/' + urlhandler + (passcode.length > 0 ? ('/passcode=' + passcode) : ''))
                }
                setItineraries(urls)
            })
        }
    }, [])

    useEffect(() => {
        if (!firsttime) {
            setSchedules(props.schedules)
        }
    }, [props.schedules])

    useEffect(() => {
        if (rmvcfm) {
            props.rmvSchedule(rmvindex).then((result) => {
                if (result === 'SUCCESS') {
                    setRmvcfm(false)
                    setShowrmvmod(false)
                }
            })
        }
    }, [rmvcfm])

    useEffect(() => {
        // sucks
        // console.log('guestids', guestids)
        // console.log('guestid_guestinfo_map', guestid_guestinfo_map)
        // console.log('schedules', schedules)
        console.log('props', props)
    }, [props])

    const NAME_DIV = <div className="Details_grp" >
        {input_dom(
            'Title',
            '',
            name,
            (e) => {
                if (e.target.value.length < 100) {
                    if (!showsave) { updateBackup(); setShowsave(true); }
                    setName(e.target.value)
                }
            },
            'nameschedule_id',
            {},
            false,
            null,
            {},
            input_css)}
    </div>

    const DATE_DIV = <div className='Details_grp' style={{ flexDirection: 'row', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            {partialtitle_dom('Date Start', {})}
            <Calendar
                check={date_s.toString().length > 0}
                selected={date_s}
                onChange={(e) => {
                    if (!showsave) { updateBackup(); setShowsave(true); }
                    setDate_s(e)
                }}
                updateDate={() => {
                    if (!showsave) { updateBackup(); setShowsave(true); }
                    setDate_s('')
                }}
            />
        </div>
        {EMPTYSPACE}
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            {partialtitle_dom('Date End', {})}
            <Calendar
                check={date_e.toString().length > 0}
                selected={date_e}
                onChange={(e) => {
                    if (!showsave) { updateBackup(); setShowsave(true); }
                    setDate_e(e)
                }}
                updateDate={() => {
                    if (!showsave) { updateBackup(); setShowsave(true); }
                    setDate_e('')
                }}
            />
        </div>

    </div>

    const TIME_DIV = <div className='Details_grp' style={{ flexDirection: 'row', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            {input_dom(
                'Time Start',
                '',
                time_s,
                (e) => {
                    if (e.target.value.length < 50) {
                        if (!showsave) { updateBackup(); setShowsave(true); }
                        setTime_s(event.target.value);
                    }
                },
                'timestart_id', {}, false, null, {}, inputhalf_css)}
        </div>
        {EMPTYSPACE}
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            {input_dom(
                'Time End',
                '',
                time_e,
                (e) => {
                    if (e.target.value.length < 50) {
                        if (!showsave) { updateBackup(); setShowsave(true); }
                        setTime_e(e.target.value);
                    }
                },
                'timestart_id', {}, false, null, {}, inputhalf_css)}
        </div>
    </div>

    const title_dom = (txt) => <h4 style={{ placeContent: 'start', alignItems: 'center', display: 'flex', borderLeft: '5px solid var(--maindark)', padding: 3, fontWeight: 'bold' }}>{txt}</h4>

    const titleonoff_dom = (txt) => <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <h4 style={{ placeContent: 'start', alignItems: 'center', display: 'flex', borderLeft: '5px solid var(--maindark)', padding: 3, fontWeight: 'bold' }}> {txt}</h4>
        <div style={{ width: '100%', display: 'flex', placeContent: 'flex-end' }}>
            <label className="Details_switch">
                <input
                    type="checkbox"
                    checked={txt === 'Itinerary' ? itineraryobj['itinerarystate'] : mapobj['mapstate']}
                    onChange={() => {
                        if (!showsave) { updateBackup(); setShowsave(true); }
                        if (txt === 'Itinerary') {
                            let new_itineraryobj = JSON.parse(JSON.stringify(itineraryobj))
                            new_itineraryobj['itinerarystate'] = !new_itineraryobj['itinerarystate']
                            setItineraryobj(new_itineraryobj)
                        }
                        else if (txt === 'Map') {
                            let new_mapobj = JSON.parse(JSON.stringify(mapobj))
                            new_mapobj['mapstate'] = !new_mapobj['mapstate']
                            setMapobj(new_mapobj)
                        }
                    }}
                />
                <span className="Details_slider round" />
            </label>
        </div>
    </div>

    const CONTENT_DIV = <div className='Details_grp'>
        {textarea_dom(
            'Content',
            '',
            content,
            (e) => {
                if (e.target.value.length < 1000) {
                    if (!showsave) { updateBackup(); setShowsave(true); }
                    setContent(e.target.value)
                }
            },
            'contenttxtarea_id',
            {},
            false,
            null,
            {},
            txtarea_css,
            4,
            null
        )}
    </div>

    const MAP_DIV = <div className='Details_grp'>
        {titleonoff_dom('Map')}
        {mapobj && mapobj['mapstate']
            ? <>

                <div className="Details_grp">{/* Map Type */}
                    {partialtitle_dom(`Map Type`, {})}
                    {inputdescribe_dom(`Choose the type of map you want to use`, { margin: 0 })}
                    {twobtn_dom(
                        'Google',
                        'Leaflet',
                        'GOOGLE',
                        'LEAFLET',
                        mapobj['maptype'],
                        (e) => {
                            if (!showsave) { updateBackup(); setShowsave(true); }
                            const val = e.target.value;
                            let new_mapobj = JSON.parse(JSON.stringify(mapobj))
                            new_mapobj['maptype'] = val
                            setMapobj(new_mapobj)
                        },
                        { height: 40 }
                    )}
                </div>

                <div className="Details_grp">
                    {input_dom(
                        'Title',
                        'Title of your Map',
                        mapobj['title'],
                        (event) => {
                            if (!showsave) { updateBackup(); setShowsave(true); }
                            const val = event.target.value;
                            let new_mapobj = JSON.parse(JSON.stringify(mapobj))
                            new_mapobj['title'] = val
                            setMapobj(new_mapobj)
                        },
                        'maptitle_id',
                        {},
                        false,
                        null,
                        {},
                        input_css)}
                </div>
                <div className="Details_grp">
                    {textarea_dom(
                        'Description',
                        '',
                        mapobj['locdescript'],
                        (e) => {
                            if (e.target.value.length < 50) {
                                if (!showsave) { updateBackup(); setShowsave(true); }
                                let new_mapobj = JSON.parse(JSON.stringify(mapobj))
                                new_mapobj['locdescript'] = e.target.value
                                setMapobj(new_mapobj)
                            }
                        },
                        'descript_id',
                        {},
                        false,
                        null,
                        {},
                        txtarea_css,
                        4,
                        null
                    )}
                </div>

                {{
                    'GOOGLE': <div>
                        {textarea_dom(
                            `Google Map Iframe`,
                            '',
                            mapobj['googleiframesrc'],
                            (e) => {
                                if (!showsave) { updateBackup(); setShowsave(true); }
                                if (e.target.value.length < 1001) {
                                    let new_mapobj = JSON.parse(JSON.stringify(mapobj))
                                    new_mapobj['googleiframesrc'] = e.target.value
                                    setMapobj(new_mapobj)
                                }
                            },
                            'googleiframesrc_id',
                            {},
                            false,
                            null,
                            {},
                            { ...txtarea_css, marginBottom: 15 },
                            5,
                            (e) => { }
                        )}
                    </div>,
                    'LEAFLET': <div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div className="Details_grp">
                                {input_dom(
                                    'Latitude',
                                    '1.11232',
                                    mapobj['leafletlat'],
                                    (event) => {
                                        if (!showsave) { updateBackup(); setShowsave(true); }
                                        const val = event.target.value;
                                        if (event.target.value.length < 20 && isFinite(val) && Math.abs(val) <= 90) {
                                            let new_mapobj = JSON.parse(JSON.stringify(mapobj))
                                            new_mapobj['leafletlat'] = val
                                            setMapobj(new_mapobj)
                                        }
                                    },
                                    'lat_id',
                                    {},
                                    false,
                                    null,
                                    {},
                                    input_css)}
                            </div>
                            {EMPTYSPACE}
                            <div className="Details_grp">
                                {input_dom(
                                    'Longitude',
                                    '1.11232',
                                    mapobj['leafletlong'],
                                    (event) => {
                                        if (!showsave) { updateBackup(); setShowsave(true); }
                                        const val = event.target.value;
                                        // if (event.target.value.length < 20 && isFinite(val) && Math.abs(val) <= 90) {
                                        let new_mapobj = JSON.parse(JSON.stringify(mapobj))
                                        new_mapobj['leafletlong'] = val
                                        setMapobj(new_mapobj)
                                        // } 
                                    },
                                    'long_id',
                                    {},
                                    false,
                                    null,
                                    {},
                                    input_css)}
                            </div>
                        </div>
                        <div className="Details_grp">
                            {input_dom(
                                'Google Location URL',
                                '',
                                mapobj['leafletlocurl'],
                                (e) => {
                                    if (e.target.value.length < 400) {
                                        if (!showsave) { updateBackup(); setShowsave(true); }
                                        let new_mapobj = JSON.parse(JSON.stringify(mapobj))
                                        new_mapobj['leafletlocurl'] = e.target.value
                                        setMapobj(new_mapobj)
                                    }
                                },
                                'googleurlid', {}, false, null, {}, inputhalf_css)}
                        </div>
                    </div>
                }[mapobj['maptype']]}




            </>
            : null}
    </div>


    const ITINERARY_DOM = <div className='Details_grp'>
        {titleonoff_dom('Itinerary')}
        {itineraryobj && itineraryobj['itinerarystate']
            ? <div className="Details_grp">
                <div className="Details_grp">
                    <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
                        <button
                            value={'link'}
                            className={`schedules_leftbtn ${itineraryobj['linkorurl'] === 'link' ? 'schedules_btnselected' : ''}`}
                            style={{ borderRight: '1px solid var(--maindark)' }}
                            onClick={(e) => {
                                if (!showsave) { updateBackup(); setShowsave(true); }
                                let new_itineraryobj = JSON.parse(JSON.stringify(itineraryobj))
                                new_itineraryobj['linkorurl'] = e.target.value
                                setItineraryobj(new_itineraryobj)
                            }}
                        >
                            {`${STARTUP_NAME} Itinerary`}
                        </button>
                        <button
                            value={'url'}
                            className={`schedules_rightbtn ${itineraryobj['linkorurl'] === 'url' ? 'schedules_btnselected' : ''}`}
                            onClick={(e) => {
                                if (!showsave) { updateBackup(); setShowsave(true); }
                                let new_itineraryobj = JSON.parse(JSON.stringify(itineraryobj))
                                new_itineraryobj['linkorurl'] = e.target.value
                                setItineraryobj(new_itineraryobj)
                            }}
                        >
                            External Itinerary
                        </button>
                    </div>

                    <div className="Details_grp">
                        {input_dom(
                            'Title',
                            'Title of your event',
                            itineraryobj['title'],
                            (event) => {
                                if (!showsave) { updateBackup(); setShowsave(true); }
                                const val = event.target.value;
                                let new_itineraryobj = JSON.parse(JSON.stringify(itineraryobj))
                                new_itineraryobj['title'] = val
                                setItineraryobj(new_itineraryobj)
                            },
                            'itinerarytitle_id',
                            {},
                            false,
                            null,
                            {},
                            input_css)}

                    </div>

                    <div className="Details_grp">
                        {itineraryobj['linkorurl'] === 'link'
                            ? multiplechoice_dom(
                                'Link',
                                itineraries,
                                (e) => {
                                    if (!showsave) { updateBackup(); setShowsave(true); }
                                    const val = e.target.value;
                                    let new_itineraryobj = JSON.parse(JSON.stringify(itineraryobj))
                                    new_itineraryobj['link'] = val
                                    setItineraryobj(new_itineraryobj)
                                },
                                'itineraries_ID',
                                {},
                                itineraryobj['link'],
                                null,
                                null,
                                { marginLeft: 0, marginRight: 0, width: '100%', margin: 0 }
                            )
                            : input_dom(
                                'Url',
                                'Url to your external itinerary.',
                                itineraryobj['url'],
                                (event) => {
                                    if (!showsave) { updateBackup(); setShowsave(true); }
                                    const val = event.target.value;
                                    let new_itineraryobj = JSON.parse(JSON.stringify(itineraryobj))
                                    new_itineraryobj['url'] = val
                                    setItineraryobj(new_itineraryobj)

                                },
                                'lat_id',
                                {},
                                false,
                                null,
                                {},
                                input_css)
                        }
                    </div>
                    {/* <div className="Details_grp">
                        {textarea_dom(
                            'Description',
                            '',
                            content,
                            (e) => {
                                if (e.target.value.length < 1000) {
                                    if (!showsave) { updateBackup(); setShowsave(true); }
                                    setContent(e.target.value)
                                }
                            },
                            'contenttxtarea_id',
                            {},
                            false,
                            null,
                            {},
                            txtarea_css,
                            4,
                            null
                        )}
                    </div> */}

                </div>

            </div>
            : null}
    </div>

    const WHOCANVIEW_DOM =
        <div className='Details_grp'>
            {title_dom('Who Can View?')}
            {EMPTYSPACE}
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 40 }}>
                <button
                    className={`schedules_btn ${whocanviewkey === 'ANYONE' ? 'schedules_btnhighlight' : ''}`}
                    style={{ borderRadius: '5px 0px 0px 5px', borderRight: 'none' }}
                    onClick={() => {
                        if (!showsave) { updateBackup(); setShowsave(true); }
                        setWhocanviewkey('ANYONE')
                    }}
                >
                    Anyone
                </button>
                <button
                    className={`schedules_btn ${whocanviewkey === 'GUESTSONLY' ? 'schedules_btnhighlight' : ''}`}
                    style={{ borderRadius: 0, }}
                    onClick={() => {
                        if (!showsave) { updateBackup(); setShowsave(true); }
                        setWhocanviewkey('GUESTSONLY')
                    }}
                >
                    Guests Only
                </button>
                <button
                    className={`schedules_btn ${whocanviewkey === 'SELECTEDGUESTS' ? 'schedules_btnhighlight' : ''}`}
                    style={{ borderRadius: '0px 5px 5px 0px', borderLeft: 'none' }}
                    onClick={() => {
                        if (!showsave) { updateBackup(); setShowsave(true); }
                        setWhocanviewkey('SELECTEDGUESTS')
                        setSelectedguestids(guestids.slice())
                    }}
                >
                    Selected Guests
                </button>
            </div>

            <div className="Details_grp">

                {/* {multiplechoice_dom(
                    'Search',
                    ['All', 'Group', 'Category'],
                    (e) => {
                        if (!showsave) { updateBackup(); setShowsave(true); }

                        const val = e.target.value
                        setWhocanviewmode(val)

                    },
                    'searchtype_id',
                    {},
                    whocanviewmode,
                    null,
                    null,
                    { marginLeft: 0, marginRight: 0, width: '100%', margin: 0 }
                )} */}


                {EMPTYSPACE}
                {{
                    'ANYONE': <>Anyone who can access to your web can view the schedule</>,
                    'GUESTSONLY': <>All guests on your guestlist can view this schedule</>,
                    'SELECTEDGUESTS': <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                            {multiplechoice_dom(
                                'Search',
                                ['All', 'Group', 'Category'],
                                (e) => {
                                    if (!showsave) { updateBackup(); setShowsave(true); }

                                    const val = e.target.value
                                    setWhocanviewmode(val)

                                },
                                'searchtype_id',
                                {},
                                whocanviewmode,
                                null,
                                null,
                                { marginLeft: 0, marginRight: 0, width: '100%', margin: 0 }
                            )}
                            <button
                                className='schedules_clear'
                                onClick={() => {
                                    if (!showsave) { updateBackup(); setShowsave(true); }
                                    setSelectedguestids([])
                                }}
                            >
                                Clear
                            </button>
                        </div>
                        {{
                            'Category': Object.keys(cat_guestid_map).map((cat, index) => {
                                return <div key={index + 'cat'} style={{ width: '100%' }}>
                                    <div style={{ fontWeight: 'bold' }}>{cat}</div>
                                    {cat_guestid_map[cat].map((guestid, gindex) => {
                                        const name = guestid_guestinfo_map[guestid]['1-name']
                                        return <button
                                            key={guestid + gindex}
                                            className='schedules_guestitem'
                                            style={{
                                                ...selectedguestids.includes(guestid)
                                                    ? { border: '1px solid var(--mainpink)', color: 'var(--mainpink)' }
                                                    : { border: '1px solid var(--maindark)', color: 'var(--maindark)' }
                                            }}
                                            value={guestid}
                                            onClick={(e) => {
                                                // curious
                                                const { value } = e.target
                                                if (!showsave) { updateBackup(); setShowsave(true); }

                                                if (!selectedguestids.includes(value)) {
                                                    setSelectedguestids([...selectedguestids.slice(), value])
                                                }
                                                else {
                                                    const curr_selectedguest_ids = selectedguestids.slice()
                                                    curr_selectedguest_ids.splice(selectedguestids.indexOf(value), 1)
                                                    setSelectedguestids(curr_selectedguest_ids)
                                                }
                                            }}
                                        >
                                            <div className='schedules_nametxt' >{'Name : ' + name}</div>
                                            <div className='schedules_idtxt' >{'Unique ID : ' + guestid}</div>
                                        </button>
                                    })}

                                </div>
                            }),
                            'Group': Object.keys(grp_guestid_map).map((grp, index) => {
                                return <div key={index + 'grp'} style={{ width: '100%' }}>
                                    <div style={{ fontWeight: 'bold' }}>{grp}</div>
                                    {grp_guestid_map[grp].map((guestid, gindex) => {
                                        const name = guestid_guestinfo_map[guestid]['1-name']
                                        return <button
                                            key={guestid + gindex}
                                            className='schedules_guestitem'
                                            style={{
                                                ...selectedguestids.includes(guestid)
                                                    ? { border: '1px solid var(--mainpink)', color: 'var(--mainpink)' }
                                                    : { border: '1px solid var(--maindark)', color: 'var(--maindark)' }
                                            }}
                                            value={guestid}
                                            onClick={(e) => {
                                                // curious
                                                const { value } = e.target
                                                if (!showsave) { updateBackup(); setShowsave(true); }

                                                if (!selectedguestids.includes(value)) {
                                                    setSelectedguestids([...selectedguestids.slice(), value])
                                                }
                                                else {
                                                    const curr_selectedguest_ids = selectedguestids.slice()
                                                    curr_selectedguest_ids.splice(selectedguestids.indexOf(value), 1)
                                                    setSelectedguestids(curr_selectedguest_ids)
                                                }
                                            }}
                                        >
                                            <div className='schedules_nametxt' >{'Name : ' + name}</div>
                                            <div className='schedules_idtxt' >{'Unique ID : ' + guestid}</div>
                                        </button>
                                    })}

                                </div>
                            }),
                            'All': guestids.map((guestid, index) => {
                                const name = guestid_guestinfo_map[guestid]['1-name']

                                return <button
                                    key={guestid + index}
                                    className='schedules_guestitem'
                                    style={{
                                        ...selectedguestids.includes(guestid)
                                            ? { border: '1px solid var(--mainpink)', color: 'var(--mainpink)' }
                                            : { border: '1px solid var(--maindark)', color: 'var(--maindark)' }
                                    }}
                                    value={guestid}
                                    onClick={(e) => {
                                        // curious
                                        const { value } = e.target
                                        if (!showsave) { updateBackup(); setShowsave(true); }

                                        if (!selectedguestids.includes(value)) {
                                            setSelectedguestids([...selectedguestids.slice(), value])
                                        }
                                        else {
                                            const curr_selectedguest_ids = selectedguestids.slice()
                                            curr_selectedguest_ids.splice(selectedguestids.indexOf(value), 1)
                                            setSelectedguestids(curr_selectedguest_ids)
                                        }
                                    }}
                                >
                                    <div className='schedules_nametxt' >{'Name : ' + name}</div>
                                    <div className='schedules_idtxt' >{'Unique ID : ' + guestid}</div>
                                </button>
                            })
                        }[whocanviewmode]}
                        {/* {guestids.map((guestid, index) => {
                            const name = guestid_guestinfo_map[guestid]['1-name']
                            return <button
                                key={guestid + index}
                                className='schedules_guestitem'
                                style={{
                                    ...selectedguestids.includes(guestid)
                                        ? { border: '1px solid var(--mainpink)', color: 'var(--mainpink)' }
                                        : { border: '1px solid var(--maindark)', color: 'var(--maindark)' }
                                }}
                                value={guestid}
                                onClick={(e) => {
                                    // curious
                                    const { value } = e.target
                                    if (!showsave) { updateBackup(); setShowsave(true); }

                                    if (!selectedguestids.includes(value)) {
                                        setSelectedguestids([...selectedguestids.slice(), value])
                                    }
                                    else {
                                        const curr_selectedguest_ids = selectedguestids.slice()
                                        curr_selectedguest_ids.splice(selectedguestids.indexOf(value), 1)
                                        setSelectedguestids(curr_selectedguest_ids)
                                    }
                                }}
                            >
                                <div className='schedules_nametxt' >{'Name : ' + name}</div>
                                <div className='schedules_idtxt' >{'Unique ID : ' + guestid}</div>
                            </button>
                        })} */}
                    </div>

                    // 'SELECTEDGUESTS': {
                    //     'Category': Object.keys(cat_guestid_map).map((cat, index) => {
                    //         return <div key={index + 'cat'} style={{ width: '100%' }}>
                    //             <div style={{ fontWeight: 'bold' }}>{cat}</div>
                    //             {cat_guestid_map[cat].map((guestid, gindex) => {
                    //                 const name = guestid_guestinfo_map[guestid]['1-name']
                    //                 return <button
                    //                     key={guestid + gindex}
                    //                     className='schedules_guestitem'
                    //                     style={{
                    //                         ...selectedguestids.includes(guestid)
                    //                             ? { border: '1px solid var(--mainpink)', color: 'var(--mainpink)' }
                    //                             : { border: '1px solid var(--maindark)', color: 'var(--maindark)' }
                    //                     }}
                    //                     value={guestid}
                    //                     onClick={(e) => {
                    //                         // curious
                    //                         const { value } = e.target
                    //                         if (!showsave) { updateBackup(); setShowsave(true); }

                    //                         if (!selectedguestids.includes(value)) {
                    //                             setSelectedguestids([...selectedguestids.slice(), value])
                    //                         }
                    //                         else {
                    //                             const curr_selectedguest_ids = selectedguestids.slice()
                    //                             curr_selectedguest_ids.splice(selectedguestids.indexOf(value), 1)
                    //                             setSelectedguestids(curr_selectedguest_ids)
                    //                         }
                    //                     }}
                    //                 >
                    //                     <div className='schedules_nametxt' >{'Name : ' + name}</div>
                    //                     <div className='schedules_idtxt' >{'Unique ID : ' + guestid}</div>
                    //                 </button>
                    //             })}

                    //         </div>
                    //     }),
                    //     'Group': Object.keys(grp_guestid_map).map((grp, index) => {
                    //         return <div key={index + 'grp'} style={{ width: '100%' }}>
                    //             <div style={{ fontWeight: 'bold' }}>{grp}</div>
                    //             {grp_guestid_map[grp].map((guestid, gindex) => {
                    //                 const name = guestid_guestinfo_map[guestid]['1-name']
                    //                 return <button
                    //                     key={guestid + gindex}
                    //                     className='schedules_guestitem'
                    //                     style={{
                    //                         ...selectedguestids.includes(guestid)
                    //                             ? { border: '1px solid var(--mainpink)', color: 'var(--mainpink)' }
                    //                             : { border: '1px solid var(--maindark)', color: 'var(--maindark)' }
                    //                     }}
                    //                     value={guestid}
                    //                     onClick={(e) => {
                    //                         // curious
                    //                         const { value } = e.target
                    //                         if (!showsave) { updateBackup(); setShowsave(true); }

                    //                         if (!selectedguestids.includes(value)) {
                    //                             setSelectedguestids([...selectedguestids.slice(), value])
                    //                         }
                    //                         else {
                    //                             const curr_selectedguest_ids = selectedguestids.slice()
                    //                             curr_selectedguest_ids.splice(selectedguestids.indexOf(value), 1)
                    //                             setSelectedguestids(curr_selectedguest_ids)
                    //                         }
                    //                     }}
                    //                 >
                    //                     <div className='schedules_nametxt' >{'Name : ' + name}</div>
                    //                     <div className='schedules_idtxt' >{'Unique ID : ' + guestid}</div>
                    //                 </button>
                    //             })}

                    //         </div>
                    //     }),
                    //     'All': guestids.map((guestid, index) => {
                    //         const name = guestid_guestinfo_map[guestid]['1-name']

                    //         return <button
                    //             key={guestid + index}
                    //             className='schedules_guestitem'
                    //             style={{
                    //                 ...selectedguestids.includes(guestid)
                    //                     ? { border: '1px solid var(--mainpink)', color: 'var(--mainpink)' }
                    //                     : { border: '1px solid var(--maindark)', color: 'var(--maindark)' }
                    //             }}
                    //             value={guestid}
                    //             onClick={(e) => {
                    //                 // curious
                    //                 const { value } = e.target
                    //                 if (!showsave) { updateBackup(); setShowsave(true); }

                    //                 if (!selectedguestids.includes(value)) {
                    //                     setSelectedguestids([...selectedguestids.slice(), value])
                    //                 }
                    //                 else {
                    //                     const curr_selectedguest_ids = selectedguestids.slice()
                    //                     curr_selectedguest_ids.splice(selectedguestids.indexOf(value), 1)
                    //                     setSelectedguestids(curr_selectedguest_ids)
                    //                 }
                    //             }}
                    //         >
                    //             <div className='schedules_nametxt' >{'Name : ' + name}</div>
                    //             <div className='schedules_idtxt' >{'Unique ID : ' + guestid}</div>
                    //         </button>
                    //     })
                    // }[whocanviewmode]
                }[whocanviewkey]}


            </div>
        </div>

    const onDragend = (result) => {
        const { source, destination } = result
        if (!destination) { return }
        else {
            if (page !== SWAP_PAGE && source.index !== destination.index) {
                setPage(SWAP_PAGE)
            }
            // else if (page === SWAP_PAGE && source.index === destination.index) {
            //     return
            // }
            if (!showsave) { updateBackup(); setShowsave(true); }
            let curr_schedules = props.schedules.slice() // must use props.schedules instead of schedules
            const [removed] = curr_schedules.splice(source.index, 1);
            curr_schedules.splice(destination.index, 0, removed);
            updateSchedules(curr_schedules)
        }
    }

    const openadd = () => {
        if (props.schedules.length < 20) {
            if (!showsave) { updateBackup(); setShowsave(true); }
            setPage(CREATE_PAGE)
        }
    }

    const openedit = (x) => {

        setPage(EDIT_PAGE)
        setEditindex(x)


        // [ 
        //   [  
        //      DATE START, 
        //      DATE END, 
        //      TIME START, 
        //      TIME END, 
        //      NAME OF EVENT, 
        //      CONTENT, 
        //      type of people who can view,  // ANYONE, GUESTONLY, SELECTEDGUESTS
        //      ids OF PPL WHO CAN VIEW,
        //      { mapstate : true, title: '', lat : 0, long : 0, url : '', map }, // MAP 
        //      { itinerarystate : true, title: '', link: '', url : '' , linkorurl:'' } // ITINERARY LINK
        //   ]    
        // ]

        const values = schedules[x]
        setDate_s(values[0].length > 0 ? new Date(values[0]) : '')
        setDate_e(values[1].length > 0 ? new Date(values[1]) : '')
        setTime_s(values[2])
        setTime_e(values[3])
        setName(values[4])
        setContent(values[5])
        setWhocanviewkey(values[6])
        setSelectedguestids(values[7])
        setMapobj(values[8])
        setItineraryobj(values[9])

        let cat_guestid_map = {}
        let grp_guestid_map = {}
        for (let i = 0; i < guestids.length; i++) {
            const guestid = guestids[i]
            const grp = guestid_guestinfo_map[guestid]['5-group'] // string
            const cats = guestid_guestinfo_map[guestid]['6-category'] // array
            if (!Object.keys(grp_guestid_map).includes(grp) && grp !== '') {
                grp_guestid_map[grp] = []
            }
            for (let j = 0; j < cats.length; j++) {
                const cat = cats[j]
                if (!Object.keys(cat_guestid_map).includes(cat) && cat !== '') {
                    cat_guestid_map[cat] = []
                }
            }

            if (Object.keys(grp_guestid_map).includes(grp)) {
                grp_guestid_map[grp].push(guestid)
            }

            for (let j = 0; j < cats.length; j++) {
                const cat = cats[j]
                if (Object.keys(cat_guestid_map).includes(cat)) {
                    cat_guestid_map[cat].push(guestid)
                }
            }
        }
        setCat_guestid_map(cat_guestid_map)
        setGrp_guestid_map(grp_guestid_map)
        setWhocanviewmode('All') // BRAVE
    }

    const revert = (e) => {
        if (e.target.value === 'swapcancel') {
            setShowsave(false)
            restoreBackup()
        }
        else if (e.target.value === 'editcancel' || e.target.value === 'createcancel') {
            setDate_s('')
            setDate_e('')
            setTime_s('')
            setTime_e('')
            setName('')
            setContent('')
            setSelectedguestids([])
            setMapobj({})
            setItineraryobj({})
            restoreBackup()
            if (e.target.value === 'editcancel') {
                setEditindex(-1)
            }
            setShowsave(false)
        }

        setPage(DEFAULT_PAGE)
    }

    const save = () => {
        props.dragSaveschedule(schedules).then((result) => {
            if (result === 'SUCCESS') {
                setPage(DEFAULT_PAGE)
                setShowsave(false)
                clearBackup()
            }
        })
    }

    const create = () => {

        let newschedule = [
            date_s.length === 0 ? '' : date_s,
            date_e.length === 0 ? '' : date_e,
            time_s.length === 0 ? '' : time_s,
            time_e.length === 0 ? '' : time_e,
            name.length === 0 ? '' : name,
            content.length === 0 ? '' : content,
            whocanviewkey,
            selectedguestids.length === 0 ? [] : selectedguestids,
            mapobj, // MAP 
            itineraryobj // ITINERARY 
        ]

        props.addSchedule(newschedule).then((result) => {
            if (result === 'SUCCESS') {
                setPage(DEFAULT_PAGE)
                setDate_s('')
                setDate_e('')
                setTime_s('')
                setTime_e('')
                setName('')
                setWhocanviewkey(defaultwhocanviewkey)
                setContent('')
                setSelectedguestids([])
                setMapobj(JSON.parse(JSON.stringify(defaultmapobj)))
                setItineraryobj(JSON.parse(JSON.stringify(defaultitineraryobj)))
                setShowsave(false)
                // clearBackup()
                // setSchedules([...schedules, newschedule])
            }
        })
    }

    const edit = () => {
        let newschedules = schedules.slice()
        if (!showsave) { updateBackup(); setShowsave(true); }
        newschedules[editindex] = [
            date_s.length === 0 ? '' : date_s.toString(),
            date_e.length === 0 ? '' : date_e.toString(),
            time_s.length === 0 ? '' : time_s,
            time_e.length === 0 ? '' : time_e,
            name.length === 0 ? '' : name,
            content.length === 0 ? '' : content,
            whocanviewkey,
            selectedguestids.length === 0 ? [] : selectedguestids,
            mapobj,
            itineraryobj
        ]
        props.editSchedule(newschedules).then((result) => {
            if (result === 'SUCCESS') {
                setPage(DEFAULT_PAGE)
                setDate_s('')
                setDate_e('')
                setTime_s('')
                setTime_e('')
                setName('')
                setContent('')
                setSelectedguestids([])
                setItineraryobj({})
                setMapobj({})
                setEditindex(-1)
                setShowsave(false)
            }
        })
    }

    const remove = (i) => {
        if (page === DEFAULT_PAGE) {
            setShowrmvmod(() => {
                setRmvindex(i)
                return true
            })
        }
    }

    const btmbarstyle = {
        'MOBILE': { position: 'fixed', bottom: 0, left: 0, width: '100%' },
        'TABLET': { position: 'fixed', bottom: 10, left: 0, width: partialwt - 1 },
        'WEB': { position: 'fixed', bottom: 0, left: 0, width: partialwt - 1 }
    }[device]

    const btmbar = {
        // display schedule
        0: <div className='iedit_swaproot' style={btmbarstyle}>
            <button className='iedit_add' onClick={openadd}>{iconwrapper(ADD_ICON, addsvgcss)}</button>
        </div>,
        // swap schedule
        1: <div className='iedit_swaproot' style={btmbarstyle}>
            <button className='iedit_cancel' value={'swapcancel'} onClick={revert}>Revert</button>
            <button className='iedit_save' disabled={!showsave} onClick={save}>Save</button>
        </div>,
        // create new schedule
        2: <div className='iedit_swaproot' style={btmbarstyle}>
            <button className='iedit_cancel' value={'createcancel'} onClick={revert}>Cancel</button>
            <button className='iedit_save' onClick={create}>Create</button>
        </div>,
        // openedit existing schedule
        3: <div className='iedit_swaproot' style={btmbarstyle}>
            <button className='iedit_cancel' value={'editcancel'} onClick={revert}>Cancel</button>
            <button className='iedit_save' disabled={!showsave} style={{ opacity: !showsave ? 0.5 : 1 }} onClick={edit}>Update</button>
        </div>
    }[page]

    const similar_0_1_page = <div style={{ display: 'flex', flexDirection: 'column' }}>
        <DragDropContext onDragEnd={onDragend}>
            <Droppable droppableId={'droppable'} >
                {(provided, snapshot) => (
                    <div className='Detailsdrag_root' ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        {schedules.map((item, x) => {
                            const i = x
                            return <Draggable key={x + '_'} draggableId={x + 'id'} index={i}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                        <div key={i + 'qna'} style={{ display: 'flex', flexDirection: 'row', border: '1px solid #cccccc', backgroundColor: 'white', overflow: 'hidden', marginBottom: 15, borderRadius: 10 }}>

                                            <span
                                                style={{ minWidth: 35, placeContent: 'center', backgroundColor: 'transparent', border: 'none', height: '100%', display: 'flex', placeSelf: 'center', cursor: 'pointer' }}
                                                onClick={() => { remove(i) }}
                                            >
                                                {page === 1 ? iconwrapper(BLOCKLARGE_ICON, blocksvgcss) : iconwrapper(CROSS_ICON, crosssvgcss)}
                                            </span>

                                            <div style={{ minWidth: 'calc( 100% - 70px )', borderRight: '1px solid rgb(204, 204, 204)', borderLeft: '1px solid rgb(204, 204, 204)', width: '100%', overflow: 'hidden', padding: 10 }}>
                                                <Timelineobj
                                                    date_s={item[0]}
                                                    date_e={item[1]}
                                                    time_s={item[2]}
                                                    time_e={item[3]}
                                                    name={item[4]}
                                                    content={item[5]}
                                                    mapobj={item[8]}
                                                    itineraryobj={item[9]}
                                                    from={'Edit'}
                                                    pri_fs={14}
                                                    sec_fs={14}
                                                    mapmode={props.mapmode}
                                                    expandbtn={true}
                                                />
                                                <div className='schedules_whocanviewroot'>
                                                    {partialtitle_dom('Who can view?', { color: '#ffffff' })}
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        {{
                                                            'ANYONE': `Anyone`,
                                                            'SELECTEDGUESTS': `Selected Guests ( ${item[7] && item[7].length > 0 ? item[7].length : 0} )`,
                                                            'GUESTSONLY': `Guests Only`
                                                        }[item[6]]}
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'column', placeContent: 'center', alignItems: 'center', }}>
                                                <span
                                                    style={{ minWidth: 35, height: '75%', placeContent: 'center', backgroundColor: 'transparent', border: 'none', height: '100%', display: 'flex', placeSelf: 'center', alignItems: 'center' }}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {iconwrapper(DRAG_ICON, dragsvgcss)}
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        page === 1
                                                            ? () => { }
                                                            : openedit(x)

                                                    }}
                                                    style={{ minWidth: 35, height: 'max-content', padding: '10px 0px', placeContent: 'center', backgroundColor: 'transparent', border: 'none', display: 'flex', placeSelf: 'center', alignItems: 'center', borderTop: '1px solid #cccccc', cursor: 'pointer' }}
                                                >
                                                    {page === 1 ? iconwrapper(CROSSPENCIL_ICON, dragsvgcss) : iconwrapper(PENCIL_ICON, dragsvgcss)}
                                                </span>
                                            </div>


                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        {btmbar}
    </div>

    const maincontent = {// 0 : default schedule page, 1 : swapping page, 2 : create schedule page, 3 : openedit page
        0: similar_0_1_page,
        1: similar_0_1_page,
        2: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ padding: 10, margin: '5px 0px 5px 0px', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', border: '1px solid rgb(230, 230, 230)', borderRadius: 5 }}>
                <div style={{ display: 'flex', height: '50%', alignItems: 'center', }}>
                    <Timelineobj
                        date_s={date_s}
                        date_e={date_e}
                        time_s={time_s}
                        time_e={time_e}
                        name={name}
                        content={content}
                        mapobj={mapobj}
                        itineraryobj={itineraryobj}
                        pri_fs={14}
                        sec_fs={14}
                        from="Edit"
                        mapmode={props.mapmode}
                        expandbtn={true}
                    />
                </div>
                {EMPTYSPACE}
                {title_dom('Details')}
                {NAME_DIV}
                {DATE_DIV}
                {TIME_DIV}
                {CONTENT_DIV}

                {EMPTYSPACE}
                {EMPTYSPACE}

                {MAP_DIV}

                {EMPTYSPACE}
                {EMPTYSPACE}

                {ITINERARY_DOM}

                {EMPTYSPACE}
                {EMPTYSPACE}

                {WHOCANVIEW_DOM}

                {EMPTYSPACE}
            </div>
            {btmbar}
        </div>,
        3: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ padding: 10, margin: '5px 0px 5px 0px', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', border: '1px solid rgb(230, 230, 230)', borderRadius: 5 }}>
                <div style={{ display: 'flex', height: '50%', alignItems: 'center', }}>
                    <Timelineobj
                        date_s={date_s}
                        date_e={date_e}
                        time_s={time_s}
                        time_e={time_e}
                        name={name}
                        content={content}
                        mapobj={mapobj}
                        itineraryobj={itineraryobj}
                        pri_fs={14}
                        sec_fs={14}
                        from="Edit"
                        mapmode={props.mapmode}
                        expandbtn={true}
                    />
                </div>
                {EMPTYSPACE}
                {title_dom('Details')}
                {NAME_DIV}
                {DATE_DIV}
                {TIME_DIV}
                {CONTENT_DIV}

                {EMPTYSPACE}
                {EMPTYSPACE}

                {MAP_DIV}

                {EMPTYSPACE}
                {EMPTYSPACE}

                {ITINERARY_DOM}

                {EMPTYSPACE}
                {EMPTYSPACE}

                {WHOCANVIEW_DOM}

                {EMPTYSPACE}

            </div>
            {btmbar}
        </div>
    }

    return (
        <div className='Ieditcomp_div'
            style={{
                height: {
                    'WEB': 'calc(100% - 70px - 65px)',
                    'TABLET': 'calc(100% - 70px - 65px)',
                    'MOBILE': 'calc(100% - 70px)'
                }[device],
                marginBottom: {
                    'MOBILE': 65
                }[device]
            }}>
            <Explain title={TITLE} txt={TEXT} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>{maincontent[page]}</div>
            {showrmvmod
                ? <Modcfm
                    onHide={() => { setShowrmvmod(false) }}
                    onOk={() => { setShowrmvmod(false); setRmvcfm(true); }}
                    onNo={() => { setShowrmvmod(false); setRmvcfm(false); setRmvindex(-1); }}
                    txt={'Once you delete this, there is no going back. Please be certain.'}
                />
                : null}
        </div>
    )
}

export default Schedules