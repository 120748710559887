import React, { useState, useEffect } from 'react'
import { changeDpiBlob } from 'changedpi';
import { DOWNLOAD_SMALL, ENGLISH_FF } from '../../../../../../config/constants';
import { Icon } from 'react-icons-kit/Icon'
import { bottom } from 'react-icons-kit/entypo/bottom'
import { top } from 'react-icons-kit/entypo/top'
import { verticalMiddle } from 'react-icons-kit/entypo/verticalMiddle'
import { horizontalMiddle } from 'react-icons-kit/entypo/horizontalMiddle'
import { left } from 'react-icons-kit/entypo/left'
import { right } from 'react-icons-kit/entypo/right'
import { androidAdd } from 'react-icons-kit/ionicons/androidAdd'
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash'
import { Numtable, Classictemplate0, Template1 } from './Components';
import { TOP_PLACE_HOLDER, MAIN_PLACE_HOLDER, RANDOM_NAMES, BGCOLOR, FONTCOLOR, ASSIGN_CSS, TEMPLATE_SHOWCASING_ARR, FORMAT_SHOWCASE_MAP } from './constants.js'
import { zoomIn } from 'react-icons-kit/feather/zoomIn';
import { Checkdevice, Modcfm, Portalmodal } from '../../../../../../components';
import { banners, corners } from '../../../../../../assets/invitationcard/index1';
import { checkbox_dom, input_dom, partialtitle_dom, title_dom, twobtn_dom } from '../../../Invitationmaker/components/Commonelements/Commonelements';
import { iconwrapper } from '../../../../../../config/commonfunctions';
import { DOWNLOAD_ICON } from '../../../../../../config/svgicons';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import html2canvas from 'html2canvas';
import './Scdesign.css'

const BANNER_SELECT_CSS = '3px solid #eb5d7b'
const BANNER_UNSELECT_CSS = '1px solid #707070'
function Scdesign(props) {

    const { tableobjs, guests } = props

    const [gtprops, setGtprops] = useState(false)
    const [pages, setPages] = useState([]) // all templates
    const [currpage, setCurrpage] = useState(0) // index 

    const [paperfocus, setPaperfocus] = useState(false)

    // empty start modal, user first enter clean, show empty start modal
    const [emptypageshow, setEmptypageshow] = useState(false)

    const [templatetype, setTemplatetype] = useState('CLASSIC')// CLASSIC or ALPHABETICAL [ "Classic" is usual table and name, whereas "Alphabetical" is completely based on alphabetical order  ]
    const [template, setTemplate] = useState(-1) // 0, 1 [ ready to used template for user to choose ]
    const [floralplacement, setFloralplacement] = useState(-1) // 0 , 1 [ this determine the placement of flower on the top part of seat chart]

    // let user 
    const [seatmodal, setSeatmodal] = useState(false)

    // showing on editbar
    const [showedittools, setShowedittools] = useState(false)
    const [showingff, setShowingff] = useState('')
    const [showingfsize, setShowingfsize] = useState('')
    const [showinglineheight, setShowinglineheight] = useState('')
    const [showingletterspacing, setShowingletterspacing] = useState('')

    // table padding
    const [showingpadding, setShowingpadding] = useState('')

    // title that is being clicked/editing
    const [selectedpart, setSelectedpart] = useState('')
    const [selectedtableindex, setSelectedtableindex] = useState(-1) // when user click/focus on table obj

    // indicate something got edited, need re render 
    const [somethingedited, setSomethingedited] = useState(false)

    // sidebar
    const [sidebar, setSidebar] = useState('') // FF, TEMPLATE, ASSET
    const [sidebartemplatetype, setSidebartemplatetype] = useState('CLASSIC')// CLASSIC or ALPHABETICAL

    // assign table modal
    const [assignmodal, setAssignmodal] = useState({ bool: false, tableindex: -1 })

    // const [zoom, setZoom] = useState(0)
    const [zoom, setZoom] = useState(1)

    // remove a page 
    const [rmvmod, setRmvmod] = useState(false)

    // toggle download portal
    const [downloadmodal, setDownloadmodal] = useState(false)
    const [downloadsize, setDownloadsize] = useState('A3')
    const [downloadtransparent, setDownloadtransparent] = useState(false) // transparent if is true, for download
    const [downloadchart, setDownloadchart] = useState(false)

    const device = Checkdevice()

    const portalmidcss = { width: { 'WEB': 500, 'TABLET': 450, 'MOBILE': '100%' }[device], height: { 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[device], backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }


    useEffect(() => {
        if (props && !gtprops) {
            const { pages } = props
            if (pages && pages.length > 0) {
                setPages(pages)
                setCurrpage(0) // set current page to the first page
                setEmptypageshow(false) // dont show empty page
            }
            else {
                setPages([])
                setEmptypageshow(true)
            }
            setGtprops(true)
        }
    }, [props])

    useEffect(() => {
        if (gtprops && pages) {
            const { updatePages } = props
            updatePages(pages)
        }
    }, [pages])

    useEffect(() => {
        if (downloadchart) {
            //  setTimeout(() => {
            // need the corner image to be cor ready
            html2canvas(
                document.getElementById('shiny'),
                {
                    allowTaint: true,
                    useCORS: true,
                    logging: true,
                    scrollX: 0,
                    scrollY: -window.scrollY,
                    scale: downloadsize === 'A4' ? 3.30459293 : 4.6744,
                    backgroundColor: downloadtransparent ? null : pages[currpage]['backgroundColor'],
                })
                .then((canvas) => {// download happens here
                    const link = document.createElement("a");
                    link.download = Math.random() + ".png";
                    document.body.appendChild(link);
                    canvas.toBlob(function (b) {
                        changeDpiBlob(b, 300)
                            .then(function (blob) {
                                link.href = window.URL.createObjectURL(blob);
                                link.click();
                                setDownloadchart(false)
                            })
                    })
                    document.body.removeChild(link);
                })
                .catch(e => { alert('Fail to download.') })
            //    }, 5000)



        }
    }, [downloadchart])

    useEffect(() => {
        if (somethingedited) { setSomethingedited(false) }
    }, [somethingedited])

    const clicktooblivion = (e) => {

        // if (e.target !== e.currentTarget) return; // Allow interactions with child elements
        // setShowingff('')
        // setShowingfsize('')
        // setSelectedpart('')
        // setSelectedtableindex(-1)

        // bear


        if (e.target.className === 'scdesign_colorinput' && sidebar === 'BGCOLOR') return;
        if (e.target.className === 'scdesign_colorinput' && sidebar === 'FONTCOLOR') return; // this will result in close when input clicked
        if (e.target.className === 'react-colorful__interactive' && sidebar === 'FONTCOLOR') return;
        e.preventDefault();

    }

    useEffect(() => {
        if (!downloadmodal) {
            setDownloadtransparent(false)
        }
    }, [downloadmodal])

    // doms 
    const page_dom = emptypageshow
        ? <div className='Scdesign_emptystart'>
            <button className='Scdesign_templatebtn' onClick={() => {
                if (sidebar === 'TEMPLATE') {
                    setSidebar('')
                }
                else {
                    setSidebar('TEMPLATE');
                    setEmptypageshow(false);
                }
            }}>
                Select a Template
            </button>
        </div>
        : (pages.length > 0)
            ? <div className="Scdesign_pagedom"
                key={currpage + '_viewingpage_' + pages.length}
                onClick={(e) => {
                    if (e.currentTarget === e.target) { // this will detect if the user click on the div itself (scdesign_pagedom)
                        setPaperfocus(false);
                        document.activeElement.blur();
                        setShowedittools(false);
                        setSidebar('')
                    }
                }}>
                {pages[currpage]['templatetype'] === 'CLASSIC'
                    ? [
                        <Classictemplate0
                            data={pages[currpage]}
                            tableobjs={tableobjs}
                            downloadtransparent={downloadtransparent}
                            downloadmodal={downloadmodal}
                            guests={guests}
                            paperfocus={paperfocus}
                            zoom={zoom}
                            updatePaperfocus={() => { setPaperfocus(false) }}

                            // update txt data
                            updateMain={(val) => {
                                setPages((pages) => {
                                    pages[currpage]['defaultmain'] = val
                                    return pages
                                })
                            }}
                            updateTop={(val) => {
                                setPages((pages) => {
                                    pages[currpage]['defaulttop'] = val
                                    return pages
                                })
                            }}

                            // for top editbar
                            openEdittools={() => { setShowedittools(true) }}
                            closeEdittools={() => {setShowedittools(false) }}
                            updateShowingff={(ff) => { setShowingff(ff); }}
                            updateShowingfs={(fs) => { setShowingfsize(fs) }}
                            updateShowinglineheight={(lh) => { setShowinglineheight(lh) }}
                            updateShowingletterspacing={(ls) => { setShowingletterspacing(ls) }}
                            updateShowingpadding={(p) => { setShowingpadding(p) }}
                            updateSelectedpart={(part) => { setSelectedpart(part) }}
                            updateSidebar={(e, sb, floralplacement) => {

                                // if (e) {
                                //     console.log('------')
                                //     console.log('e', e)
                                //     console.log('sidebar', sidebar)
                                //     e.relatedTarget ? console.log('e.relatedTarget.className', e.relatedTarget.className) : ''
                                //     // console.log('sidebar', sidebar)
                                //     // console.log('e.currentTarget', e.currentTarget)
                                //     // console.log('e.target.className', e.target.className)
                                //     // console.log('e.relatedTarget', e.relatedTarget)
                                //     console.log('showedittools', showedittools)
                                //     console.log('------')
                                // }

                                if (sidebar === 'FONTCOLOR' && e.relatedTarget && (e.relatedTarget.className === 'colorful__interactive' || e.relatedTarget.className === 'scdesign_colorinput')) {
                                    setSidebar('FONTCOLOR')
                                    return
                                }

                                // bear
                                if (sb === 'ASSET') {
                                    setSidebar('ASSET')
                                    setFloralplacement(floralplacement)
                                    return
                                }

                                setSidebar(sb)
                            }}
                            updateAssignmodal={(bool, tableindex) => setAssignmodal({ bool: bool, tableindex: tableindex })}
                            updateAlltablenames={(new_alltablename_single, tableindex) => {
                                setPages((pages) => {
                                    pages[currpage]['alltablenames'][tableindex] = new_alltablename_single
                                    return pages
                                })
                            }}
                            updateAllseatnames={(new_allseatname_single, tableindex) => {
                                setPages((pages) => {
                                    pages[currpage]['allseatnames'][tableindex] = new_allseatname_single
                                    return pages
                                })
                            }}
                            updateSelectedtableindex={(i) => { setSelectedtableindex(i) }}

                            // for download
                            downloadchart={downloadchart}
                        />,
                        <Template1
                            data={pages[currpage]}
                            tableobjs={tableobjs}
                            guests={guests}
                        />
                    ][pages[currpage]['template']]
                    : [
                        <div>Alphabetical Template 0 </div>,
                        <div>Alphabetical Template 2 </div>
                    ][pages[currpage]['template']]}
            </div>
            : null

    const seatnumselect_dom = seatmodal
        ? <Numtable
            create={(totaltables, tablecss, tableformat) => {
                // INITIATE
                if (pages.length < 10) {

                    setSidebar('')
                    setSeatmodal(false)
                    var new_pages = pages.slice()
                    const { cornerimg, onesidedimg, banner, topff, mainff, tableff, seatsff, templatecolor, floralplacement, jccanva, aligncanva, topfc, mainfc, tablefc, seatsfc } = TEMPLATE_SHOWCASING_ARR[template]
                    const { tabletitlefs, seatsfs } = FORMAT_SHOWCASE_MAP[tableformat] // format determine the fontsizes

                    var alltablenames = []
                    var alltablenamescss = []
                    var allseatnames = []
                    var allseatnamescss = [] // css string, same length as total number of tables

                    // push stub data for a start
                    for (let i = 0; i < totaltables; i++) {
                        alltablenamescss.push({
                            fontFamily: tableff,
                            fontStyle: '',
                            fontSize: tabletitlefs,
                            fontWeight: 'bold',
                            lineHeight: 1.5,
                            placeContent: 'center',
                            textAlign: 'center',
                            textDecoration: '',
                            padding: 0,
                            letterSpacing: 0,
                            color: tablefc,
                        })
                        allseatnamescss.push({
                            fontFamily: seatsff,
                            fontStyle: '',
                            fontSize: seatsfs,
                            fontWeight: '',
                            lineHeight: 1.5,
                            placeContent: 'center',
                            textAlign: 'center',
                            textDecoration: '',
                            padding: 0,
                            letterSpacing: 0,
                            color: seatsfc
                        })
                        alltablenames.push(`Table ${i + 1}`)

                        var allseatnames_dom = ''
                        for (let i = 0; i < 12; i++) {// random number of people
                            allseatnames_dom += `<div>${RANDOM_NAMES[Math.floor(Math.random() * RANDOM_NAMES.length)]}</div>`
                        }
                        allseatnames.push(allseatnames_dom)
                    }

                    new_pages.push(
                        {
                            'defaulttop': TOP_PLACE_HOLDER,
                            'defaultmain': MAIN_PLACE_HOLDER,
                            'topcss': {
                                fontFamily: topff,
                                fontStyle: '',
                                fontSize: 15,
                                lineHeight: 1.5,
                                fontWeight: '',
                                placeContent: floralplacement === 0 ? 'center' : 'flex-start',
                                textAlign: 'center',
                                textDecoration: '',
                                padding: 0,
                                letterSpacing: 0,
                                color: topfc
                            },
                            'maincss': {
                                fontFamily: mainff,
                                fontStyle: '',
                                fontSize: 80,
                                lineHeight: 1.5,
                                fontWeight: 'bold',
                                placeContent: floralplacement === 0 ? 'center' : 'flex-start',
                                textAlign: 'center',
                                textDecoration: '',
                                padding: 0,
                                letterSpacing: 0,
                                color: mainfc
                            },
                            'banner': banner,
                            'cornerimg': cornerimg,
                            'alltablenames': alltablenames,
                            'alltablenamescss': alltablenamescss,
                            'allseatnames': allseatnames,
                            'allseatnamescss': allseatnamescss,
                            'template': 0,
                            'totaltables': totaltables,
                            'tablecss': tablecss,
                            'tableformat': tableformat,
                            'templatetype': templatetype,
                            'templatecolor': templatecolor,
                            'floralplacement': floralplacement,
                            'onesidedimg': onesidedimg,
                            'jccanva': jccanva,
                            'aligncanva': aligncanva
                        },
                    )


                    setPages(new_pages)
                    setCurrpage(parseInt(new_pages.length - 1, 10))
                    setEmptypageshow(false)
                    setTemplate(-1)
                    setFloralplacement(-1)
                }
                else {
                    alert('You have reach the maximum number of pages.')
                }
            }}
            cancel={() => { setSeatmodal(false); setTemplate(-1); setFloralplacement(-1) }}
        />
        : null

    const assigntable_dom = assignmodal && assignmodal['bool'] && assignmodal['tableindex'] > -1
        ? <div className='Numtable_root'>
            <div className='Scdesign_seatmodal'>
                <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll', margin: 10, height: 'calc( 550px - 75px )', width: 'calc( 100% - 20px )' }}>
                    {(tableobjs && tableobjs.length > 0)
                        ? tableobjs.map((item, pretableindex) => {
                            const { name, } = item // cats, max, relas, seatnames
                            return <div
                                key={pretableindex + 'tableobj'}
                                className="Scdesign_seatmodalcontent"
                                onClick={() => { // assign stub data

                                    setPages((pgs) => {
                                        const { tableformat } = pgs[currpage] // totaltables, tablecss
                                        const { seatcss, tablecss } = ASSIGN_CSS[tableformat]
                                        const { tableindex } = assignmodal
                                        const { tableobjs } = props
                                        const { name, seatnames } = tableobjs[pretableindex] // cats, max, relas,

                                        // update alltablenames of specific table on printable canva
                                        pgs[currpage]['alltablenames'][tableindex] = `<div key=${tableindex + '_tablename'}>${name}</div>`

                                        // update alltablenames css TABLE 
                                        // pgs[currpage]['alltablenamescss'][tableindex] = tablecss
                                        var tablecss_arr = Object.keys(tablecss)
                                        for (let i = 0; i < tablecss_arr.length; i++) {
                                            const css_param = tablecss_arr[i]
                                            pgs[currpage]['alltablenamescss'][tableindex][css_param] = tablecss[css_param]
                                        }

                                        // update allseatnames of specific table on printable canva
                                        var allseatnames_dom = ''
                                        for (let i = 0; i < seatnames.length; i++) {
                                            allseatnames_dom += `<div>${seatnames[i]}</div>`
                                        }
                                        pgs[currpage]['allseatnames'][tableindex] = allseatnames_dom
                                        // pgs[currpage]['allseatnamescss'][tableindex] = seatcss
                                        var seatcss_arr = Object.keys(seatcss)
                                        for (let i = 0; i < seatcss_arr.length; i++) {
                                            const css_param = seatcss_arr[i]
                                            pgs[currpage]['allseatnamescss'][tableindex][css_param] = seatcss[css_param]
                                        }

                                        return pgs
                                    })

                                    // close assignmodal
                                    setAssignmodal({ bool: false, tableindex: -1 })
                                }}>
                                {name}
                            </div>
                        })
                        : null}

                </div>
                <div className='Numtable_btnrow'>
                    <button className='Scdesign_cancel' onClick={() => { setAssignmodal({ bool: false, tableindex: -1 }); }}>Cancel</button>
                </div>
            </div>
        </div>
        : null

    const zoom_btn = <div className='Scdesign_fs' style={{ height: '100%', placeContent: 'center', padding: 10 }}>
        <div className="Scdesign_topbarrow">
            <Icon size={23} icon={zoomIn} style={{ padding: 6 }} />
            <button
                className='Scdesign_fssub'
                style={{ zIndex: 2, height: 40 }}
                onMouseDown={(e) => {
                    e.preventDefault();
                    parseFloat(0.00) === parseFloat(parseFloat(zoom - 0.01).toFixed(2)) ? setZoom(1) : setZoom(parseFloat(zoom - 0.01).toFixed(2))
                }}>-</button>
            <div className='Scdesign_fsnum' style={{ height: 40 }} onMouseDown={(e) => { e.preventDefault() }}>{zoom}</div>
            <button
                className='Scdesign_fsadd'
                style={{ zIndex: 2, height: 40 }}
                onMouseDown={(e) => {
                    e.preventDefault();
                    if (parseFloat(1.01) === parseFloat(parseFloat(zoom + 0.01).toFixed(2))) {
                        setZoom(0)
                    }
                    else {
                        setZoom(parseFloat(parseFloat(zoom + 0.01).toFixed(2)))
                    }
                }}>+</button>
        </div>
    </div>

    const btmbar_dom = (pages.length > 0)
        ? <div className='Scdesign_btmpagebar' onClick={() => { setSidebar('') }}>
            <div className='Scdesign_delete Scdesign_hidden'>{zoom_btn}</div>
            <div className='Scdesign_btmbarroot'>
                {pages.map((page, index) => {
                    return <div
                        key={'page' + index}
                        className={`Scdesign_pageroot ${currpage === index ? 'Scdesign_pageselected' : ''}`}
                        onClick={() => { setCurrpage(index) }}
                    >
                        {index + 1}
                    </div>
                })}
                <div className='Scdesign_addbtn' onClick={(e) => { e.stopPropagation(); setSidebar('TEMPLATE'); }}>
                    <Icon size={20} icon={androidAdd} />
                </div>
                <div className='Scdesign_sep' />
                <div className='Scdesign_delete' onClick={() => setRmvmod(true)}> <Icon size={20} icon={iosTrash} />
                </div>
            </div>
            {zoom_btn}
        </div>
        : null

    const template_sample_dom = (index) => {
        const { cornerimg, onesidedimg, banner, topff, mainff, tableff, seatsff, topfc, mainfc, tablefc, seatsfc, templatecolor, floralplacement } = TEMPLATE_SHOWCASING_ARR[index]
        return <button
            className={`Scdesign_templateselection ${template === index ? 'Scdesign_templateselected' : ''}`}
            style={{ backgroundColor: templatecolor }}
            value={index}
            onClick={(e) => {
                setTemplate(parseInt(e.target.value, 10));
                setSeatmodal(true); // show seat modal choose number of seats
                setEmptypageshow(false); // close empty page 
                setTemplatetype(sidebartemplatetype) // set to selected templatetype
                setSidebartemplatetype('CLASSIC')// reset to default
                setFloralplacement(floralplacement)
            }}
        >
            {floralplacement === 0
                ? <div className='Scdesign_templatesampletop'>
                    <div className='Scdesign_templatesampleleftimg' style={{ backgroundImage: `url(${cornerimg})` }} />
                    <div className='Scdesign_templatesamplerightimg' style={{ backgroundImage: `url(${cornerimg})` }} />
                    <div className='Scdesign_templatesampletext'>
                        <div className="Scdesign_templatesampletexttop" style={{ fontFamily: topff, color: topfc }}>Seating Arrangement</div>
                        <div className="Scdesign_templatesampletextmaintitle" style={{ fontFamily: mainff, color: mainfc }}>{`Alice & Bob`}</div>
                        <img className="Scdesign_templatebanner" alt="" src={banner} />
                    </div>
                </div>
                : null}

            {floralplacement === 1
                ? <div className='Scdesign_templatesampletop'>
                    <div className='Scdesign_templatesampletext'>
                        <div className="Scdesign_templatesampletexttop" style={{ fontFamily: topff, color: topfc }}>Seating Arrangement</div>
                        <div className="Scdesign_templatesampletextmaintitle" style={{ fontFamily: mainff, color: mainfc }}>{`Alice & Bob`}</div>
                        <img src={onesidedimg} alt="" />
                    </div>
                </div>
                : null}

            <div className='Scdesign_templatesamplemid'>
                <div className='Scdesign_templaterow'>
                    <div className="Scdesign_templatetabletxt" style={{ fontFamily: tableff, color: tablefc }}>Table 1</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Christian Devdas</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Qun Qun</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Kaapro Nasrin</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Lupita Sans</div>
                </div>
                <div className='Scdesign_templaterow'>
                    <div className="Scdesign_templatetabletxt" style={{ fontFamily: tableff, color: tablefc }}>Table 2</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Christian Devdas</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Qun Qun</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Kaapro Nasrin</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Lupita Sans</div>
                </div>
            </div>
            <div className='Scdesign_templatesamplebtm'>
                <div className='Scdesign_templaterow'>
                    <div className="Scdesign_templatetabletxt" style={{ fontFamily: tableff, color: tablefc }}>Table 3</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Christian Devdas</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Qun Qun</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Kaapro Nasrin</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Lupita Sans</div>
                </div>
                <div className='Scdesign_templaterow'>
                    <div className="Scdesign_templatetabletxt" style={{ fontFamily: tableff, color: tablefc }}>Table 4</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Christian Devdas</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Qun Qun</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Kaapro Nasrin</div>
                    <div className="Scdesign_templateseattxt" style={{ fontFamily: seatsff, color: seatsfc }}>Lupita Sans</div>
                </div>
            </div>
        </button>
    }

    // sidebars
    const template_sidebar = (sidebar === 'TEMPLATE')
        ? <div className='Scdesign_choosetemplateroot'>
            <button className='Scdesign_templatetypebtn'
                onClick={() => {
                    const tt = sidebartemplatetype === 'CLASSIC' ? 'ALPHABETICAL' : 'CLASSIC'
                    setSidebartemplatetype(tt);
                    setTemplatetype(tt)
                }}>
                {sidebartemplatetype === 'CLASSIC' ? 'Classic' : 'Alphabetical Order'}
            </button>
            {{
                'CLASSIC': <div key={'templatesidebar' + templatetype} className='Scdesign_templategrid'>
                    {template_sample_dom(0)}
                    {template_sample_dom(1)}
                    {template_sample_dom(2)}
                    {template_sample_dom(3)}
                    {template_sample_dom(4)}
                    {template_sample_dom(5)}
                    {template_sample_dom(6)}
                </div>,
                'ALPHABETICAL': <div key={'templatesidebar' + templatetype} className='Scdesign_templategrid'>
                    <div>Coming Soon</div>
                </div>
            }[templatetype]}
            <button
                className='Scdesign_done'
                onMouseDown={(e) => {
                    e.preventDefault();
                    if (pages.length === 0) {
                        setEmptypageshow(true);
                    }
                    setSidebar('');
                }}> Done </button>
        </div> : null

    const asset_sidebar = (sidebar === 'ASSET')
        ? <span className='Scdesign_topeditimgdd' >
            {{
                0: banners.map((b, index) => {
                    const changebanner = () => {

                        if (floralplacement === 0) {
                            setPages((pg) => {
                                pg[currpage]['banner'] = b === pg[currpage]['banner'] ? '' : b
                                return pg
                            })
                        }
                        else if (floralplacement === 1) {
                            setPages((pg) => {
                                pg[currpage]['banner'] = b === pg[currpage]['banner'] ? '' : b
                                return pg
                            })
                        }

                        setSomethingedited(true)
                    }

                    const changecorner = () => {
                        if (floralplacement === 0) {
                            setPages((pg) => {
                                pg[currpage]['cornerimg'] = corners[index] === pg[currpage]['cornerimg'] ? '0' : corners[index]
                                return pg
                            })
                        }
                        else if (floralplacement === 1) {
                            setPages((pg) => {
                                pg[currpage]['cornerimg'] = corners[index] === pg[currpage]['cornerimg'] ? '0' : corners[index]
                                return pg
                            })
                        }
                        setSomethingedited(true)
                    }

                    const emptydom = <div className={`Scdesign_imgrow`} key={index + b} >
                        <div
                            className='Scdesign_imgsq'
                            onClick={changecorner}
                            style={{ border: corners[index] === pages[currpage]['cornerimg'] ? BANNER_SELECT_CSS : BANNER_UNSELECT_CSS, transform: 'scaleX(-1)' }}
                        />
                        <div
                            className='Scdesign_imgbanner'
                            onClick={changebanner}
                            style={{ border: b === pages[currpage]['banner'] ? BANNER_SELECT_CSS : BANNER_UNSELECT_CSS }}
                        />
                        <div
                            className='Scdesign_imgsq'
                            onClick={changecorner}
                            style={{ border: corners[index] === pages[currpage]['cornerimg'] ? BANNER_SELECT_CSS : BANNER_UNSELECT_CSS }}
                        />
                    </div>

                    return index === 0 && pages[currpage]['cornerimg'] !== ''
                        ? emptydom
                        : <div className={`Scdesign_imgrow`} key={index + b} >
                            <img
                                key={corners[index] + 'left'}
                                className='Scdesign_imgsq'
                                onClick={changecorner}
                                style={{ border: corners[index][0] === pages[currpage]['cornerimg'][0] ? BANNER_SELECT_CSS : BANNER_UNSELECT_CSS, transform: 'scaleX(-1)' }}
                                src={corners[index]}
                                alt=""
                            />
                            <img
                                className='Scdesign_imgbanner'
                                onClick={changebanner}
                                style={{ border: b === pages[currpage]['banner'] ? BANNER_SELECT_CSS : BANNER_UNSELECT_CSS }}
                                src={b}
                                alt=""
                            />
                            {/* {corners[index] + '_' + pages[currpage]['cornerimg']} */}
                            <img
                                key={corners[index] + 'right'}
                                className='Scdesign_imgsq'
                                onClick={changecorner}
                                style={{ border: corners[index][0] === pages[currpage]['cornerimg'][0] ? BANNER_SELECT_CSS : BANNER_UNSELECT_CSS }}
                                src={corners[index]}
                                alt=""
                            />
                        </div>
                }),
                // 1: sconesided_collection.map((o, index) => {
                //     const changebanner = () => {
                //         if (floralplacement === 0) {
                //             setPages((pg) => {
                //                 pg[currpage]['onesidedimg'] = o
                //                 return pg
                //             })
                //         }
                //         else if (floralplacement === 1) {
                //             setPages((pg) => {
                //                 pg[currpage]['onesidedimg'] = o
                //                 return pg
                //             })
                //         }

                //         setSomethingedited(true)
                //     }
                //     return o === ''
                //         ? <div className={`Scdesign_imgrow`} key={index + o} onClick={changebanner}>
                //             <div
                //                 className='Scdesign_imgbanner'
                //                 key={index + o}
                //                 onClick={changebanner}
                //                 style={{ border: o === pages[currpage]['onesidedimg'] ? BANNER_SELECT_CSS : BANNER_UNSELECT_CSS }}
                //                 src={""}
                //                 alt=""
                //             />
                //             <img
                //                 className='Scdesign_imgsq'
                //                 src={''}
                //                 alt=""
                //             />
                //         </div>
                //         : <div className={`Scdesign_imgrow`} key={index + o} onClick={changebanner} >
                //             <img
                //                 className='Scdesign_imgbanner'
                //                 style={{ border: o === pages[currpage]['onesidedimg'] ? BANNER_SELECT_CSS : BANNER_UNSELECT_CSS }}
                //                 src={o}
                //                 alt=""
                //             />
                //         </div>
                // })
            }[floralplacement]}
            <div className='Scdesign_empty' />
            <button
                className='Scdesign_done'
                onMouseDown={(e) => { e.preventDefault(); setSidebar(''); }}
            >
                Done
            </button>
        </span> : null

    const ff_sidebar = (sidebar === 'FF')
        ? <span className='Scdesign_topeditdd'>
            {['None', ...ENGLISH_FF].map((item, index) => {
                return <button
                    className={showingff === item ? 'Scdesign_ffbtn_selected' : 'Scdesign_ffbtn'}
                    key={item + index}
                    value={item}
                    onMouseDown={clicktooblivion}
                    onClick={(e) => {
                        const val = e.target.value
                        if (selectedpart === 'MAIN') {
                            setPages((pg) => {
                                pg[currpage]['maincss']['fontFamily'] = val
                                return pg
                            })
                        }
                        else if (selectedpart === 'TOP') {
                            setPages((pg) => {
                                pg[currpage]['topcss']['fontFamily'] = val
                                return pg
                            })
                        }
                        else if (selectedpart === 'TABLE') {
                            setPages((pg) => {
                                pg[currpage]['alltablenamescss'][selectedtableindex]['fontFamily'] = val
                                return pg
                            })
                        }
                        else if (selectedpart === 'SEATS') {
                            setPages((pg) => {
                                pg[currpage]['allseatnamescss'][selectedtableindex]['fontFamily'] = val
                                return pg
                            })
                        }
                        setShowingff(val)

                    }}
                >
                    {item}
                </button>
            })}
            <button className='Scdesign_empty' />
            <button className='Scdesign_done' onMouseDown={(e) => { e.preventDefault(); setSidebar(''); }}> Done </button>
        </span>
        : null

    const bgcolorclickableupdate = (bgcolor) => {
        if (pages && pages.length > 0) {
            setPages((pgs) => {
                pgs[currpage]['templatecolor'] = bgcolor
                setSomethingedited(true)
                return pgs
            })
        }
    }

    const bgcolor_sidebar = (sidebar === 'BGCOLOR')
        ? <div className='Scdesign_topeditdd'>
            <div className='Scdesign_colorpickerroot'>
                <HexColorPicker className='scdesign_colorpicker' color={pages[currpage]['templatecolor']} onChange={bgcolorclickableupdate} />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 10px' }}>
                    <div style={{ paddingRight: 3, fontSize: 18 }}>#</div>
                    <HexColorInput className='scdesign_colorinput' color={pages[currpage]['templatecolor']} onChange={bgcolorclickableupdate} />
                </div>
            </div>
            <div className='Scdesign_bggrid'>

                {BGCOLOR.map((bgcolor, index) => {
                    const bgonchange = () => {
                        if (pages && pages.length > 0) {
                            setPages((pgs) => {
                                pgs[currpage]['templatecolor'] = bgcolor
                                setSomethingedited(true)
                                return pgs
                            })
                        }
                    }

                    return <div
                        key={"Scdesignbgcolor" + index}
                        className="Scdesign_bgcoloritem"
                        style={{ border: pages[currpage]['templatecolor'] === bgcolor ? '1px solid #cbaf8a' : '1px solid transparent', }}
                        value={bgcolor}
                        onClick={bgonchange}
                    >
                        <div
                            className='Scdesign_bgcoloriteminner'
                            style={{ backgroundColor: bgcolor }}
                            onClick={bgonchange}
                        />
                    </div>

                })}
            </div>
            <button className='Scdesign_done' onMouseDown={(e) => { e.preventDefault(); setSidebar(''); }}> Done </button>
        </div>
        : null

    // bear
    const fontcolor_sidebar = sidebar === 'FONTCOLOR'
        ? <span className='Scdesign_topeditdd'>
            <div className='Scdesign_colorpickerroot'>

                <HexColorPicker
                    className='scdesign_colorpicker'
                    color={pages[currpage]['topcss']['color']}

                    onChange={(fc) => {
                        if (selectedpart === 'TOP') {
                            setPages((pgs) => {
                                pgs[currpage]['topcss']['color'] = fc
                                return pgs
                            })
                        }
                        else if (selectedpart === 'MAIN') {
                            setPages((pgs) => {
                                pgs[currpage]['maincss']['color'] = fc
                                return pgs
                            })
                        }
                        else if (selectedpart === 'TABLE') {
                            setPages((pgs) => {
                                pgs[currpage]['alltablenamescss'][selectedtableindex]['color'] = fc
                                return pgs
                            })
                        }
                        else if (selectedpart === 'SEATS') {
                            setPages((pgs) => {
                                pgs[currpage]['allseatnamescss'][selectedtableindex]['color'] = fc
                                return pgs
                            })
                        }
                        setSomethingedited(true)
                    }}
                />


                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 10px' }}>
                    <div style={{ paddingRight: 3, fontSize: 18 }}>#</div>
                    <HexColorInput
                        className='scdesign_colorinput'

                        color={pages[currpage]['topcss']['color']}

                        onChange={(fc) => {
                            if (selectedpart === 'TOP') {
                                setPages((pgs) => {
                                    pgs[currpage]['topcss']['color'] = fc
                                    return pgs
                                })
                            }
                            else if (selectedpart === 'MAIN') {
                                setPages((pgs) => {
                                    pgs[currpage]['maincss']['color'] = fc
                                    return pgs
                                })
                            }
                            else if (selectedpart === 'TABLE') {
                                setPages((pgs) => {
                                    pgs[currpage]['alltablenamescss'][selectedtableindex]['color'] = fc
                                    return pgs
                                })
                            }
                            else if (selectedpart === 'SEATS') {
                                setPages((pgs) => {
                                    pgs[currpage]['allseatnamescss'][selectedtableindex]['color'] = fc
                                    return pgs
                                })
                            }
                            setSomethingedited(true)
                        }}
                    />
                </div>

                {/* bull */}

            </div>
            <div className='Scdesign_bggrid'>
                {FONTCOLOR.map((fc, index) => {
                    const changecolor = () => {
                        if (selectedpart === 'TOP') {
                            setPages((pgs) => {
                                pgs[currpage]['topcss']['color'] = fc
                                return pgs
                            })
                        }
                        else if (selectedpart === 'MAIN') {
                            setPages((pgs) => {
                                pgs[currpage]['maincss']['color'] = fc
                                return pgs
                            })
                        }
                        else if (selectedpart === 'TABLE') {
                            setPages((pgs) => {
                                pgs[currpage]['alltablenamescss'][selectedtableindex]['color'] = fc
                                return pgs
                            })
                        }
                        else if (selectedpart === 'SEATS') {
                            setPages((pgs) => {
                                pgs[currpage]['allseatnamescss'][selectedtableindex]['color'] = fc
                                return pgs
                            })
                        }
                        setSomethingedited(true)
                    }

                    return <div
                        key={"Scdesignbgcolor" + index + selectedpart}
                        className="Scdesign_bgcoloritem"
                        style={{
                            border:
                                ((selectedpart === 'TOP' && pages[currpage]['topcss']['color'] === fc) ||
                                    (selectedpart === 'MAIN' && pages[currpage]['maincss']['color'] === fc) ||
                                    (selectedpart === 'TABLE' && pages[currpage] && pages[currpage]['alltablenamescss'] && pages[currpage]['alltablenamescss'][selectedtableindex]['color'] === fc) ||
                                    (selectedpart === 'SEATS' && pages[currpage] && pages[currpage]['allseatnamescss'] && pages[currpage]['allseatnamescss'][selectedtableindex]['color'] === fc))
                                    ? '1px solid #cbaf8a'
                                    : '1px solid transparent'
                        }}
                        onClick={changecolor}
                    >
                        <div
                            className='Scdesign_bgcoloriteminner'
                            key={"Scdesignbgcolor" + index + selectedpart}
                            style={{ backgroundColor: fc }}
                            onClick={changecolor}
                            value={fc}
                        />
                    </div>

                })}
            </div>
            <button className='Scdesign_done' onMouseDown={(e) => { e.preventDefault(); setSidebar(''); }}> Done </button>
        </span>
        : null


    // topbar btns
    const ff_btn = showedittools
        ? <button
            className="Scdesign_ffdropdown"
            onMouseDown={(e) => {
                e.preventDefault();
                setSidebar(sidebar === 'FF' ? '' : 'FF')
            }}>
            {showingff}
        </button>
        : null

    const incdecfs_btn = showedittools
        ? <div className='Scdesign_fs'>
            <div className='Scdesign_topbartitle'>Font Size</div>
            <div className="Scdesign_topbarrow">
                <button className='Scdesign_fssub' onMouseDown={(e) => {
                    e.preventDefault()
                    if (selectedpart === 'MAIN') {
                        const newfs = parseInt(pages[currpage]['maincss']['fontSize'], 10) - 1
                        setShowingfsize(newfs)
                        setPages((pg) => {
                            pg[currpage]['maincss']['fontSize'] = newfs
                            return pg
                        })
                    }
                    else if (selectedpart === 'TOP') {
                        const newfs = parseInt(pages[currpage]['topcss']['fontSize'], 10) - 1
                        setShowingfsize(newfs)
                        setPages((pg) => {
                            pg[currpage]['topcss']['fontSize'] = newfs
                            return pg
                        })
                    }
                    else if (selectedpart === 'TABLE') {
                        setPages((pg) => {
                            pg[currpage]['alltablenamescss'][selectedtableindex]['fontSize'] -= 1
                            setShowingfsize(pg[currpage]['alltablenamescss'][selectedtableindex]['fontSize'])
                            return pg
                        })
                    }
                    else if (selectedpart === 'SEATS') {
                        setPages((pg) => {
                            pg[currpage]['allseatnamescss'][selectedtableindex]['fontSize'] -= 1
                            setShowingfsize(pg[currpage]['allseatnamescss'][selectedtableindex]['fontSize'])
                            return pg
                        })
                    }
                }
                }>-</button>
                <div className='Scdesign_fsnum' onMouseDown={(e) => { e.preventDefault() }}>{showingfsize}</div>
                <button className='Scdesign_fsadd' onMouseDown={(e) => {
                    e.preventDefault()
                    if (selectedpart === 'MAIN') {
                        const newfs = parseInt(pages[currpage]['maincss']['fontSize'], 10) + 1
                        setShowingfsize(newfs)
                        setPages((pg) => {
                            pg[currpage]['maincss']['fontSize'] = newfs
                            return pg
                        })
                    }
                    else if (selectedpart === 'TOP') {
                        const newfs = parseInt(pages[currpage]['topcss']['fontSize'], 10) + 1
                        setShowingfsize(newfs)
                        setPages((pg) => {
                            pg[currpage]['topcss']['fontSize'] = newfs
                            return pg
                        })
                    }
                    else if (selectedpart === 'TABLE') {
                        setPages((pg) => {
                            const newfs = parseInt(pages[currpage]['alltablenamescss'][selectedtableindex]['fontSize'], 10) + 1
                            pg[currpage]['alltablenamescss'][selectedtableindex]['fontSize'] = newfs
                            setShowingfsize(newfs)
                            return pg
                        })
                    }
                    else if (selectedpart === 'SEATS') {
                        setPages((pg) => {
                            const newfs = parseInt(pages[currpage]['allseatnamescss'][selectedtableindex]['fontSize'], 10) + 1
                            pg[currpage]['allseatnamescss'][selectedtableindex]['fontSize'] = newfs
                            setShowingfsize(newfs)
                            return pg
                        })
                    }
                }}>+</button>
            </div>
        </div>
        : null

    const incdeclh_btn = showedittools // letter height
        ? <div className='Scdesign_fs'>
            <div className='Scdesign_topbartitle'>Line Height</div>
            <div className="Scdesign_topbarrow">
                <button className='Scdesign_fssub' onMouseDown={(e) => {
                    e.preventDefault()
                    if (selectedpart === 'MAIN') {
                        const newlh = (parseFloat(pages[currpage]['maincss']['lineHeight']) - 0.01).toFixed(2)
                        setShowinglineheight(newlh)
                        setPages((pg) => {
                            pg[currpage]['maincss']['lineHeight'] = newlh
                            return pg
                        })
                    }
                    else if (selectedpart === 'TOP') {
                        const newlh = (parseFloat(pages[currpage]['topcss']['lineHeight']) - 0.01).toFixed(2)
                        setShowinglineheight(newlh)
                        setPages((pg) => {
                            pg[currpage]['topcss']['lineHeight'] = newlh
                            return pg
                        })
                    }
                    else if (selectedpart === 'TABLE') {
                        const newlh = (parseFloat(pages[currpage]['alltablenamescss'][selectedtableindex]['lineHeight']) - 0.01).toFixed(2)
                        setShowinglineheight(newlh)
                        setPages((pg) => {
                            pg[currpage]['alltablenamescss'][selectedtableindex]['lineHeight'] = newlh
                            return pg
                        })
                    }
                    else if (selectedpart === 'SEATS') {


                        const newlh = (parseFloat(pages[currpage]['allseatnamescss'][selectedtableindex]['lineHeight']) - 0.01).toFixed(2)
                        setShowinglineheight(newlh)
                        setPages((pg) => {
                            pg[currpage]['allseatnamescss'][selectedtableindex]['lineHeight'] = newlh
                            return pg
                        })
                    }


                }
                }>-</button>
                <div className='Scdesign_fsnum' onMouseDown={(e) => { e.preventDefault() }}>{showinglineheight}</div>
                <button className='Scdesign_fsadd' onMouseDown={(e) => {
                    e.preventDefault()
                    if (selectedpart === 'MAIN') {
                        const newlh = (parseFloat(pages[currpage]['maincss']['lineHeight']) + 0.01).toFixed(2)
                        setShowinglineheight(newlh)
                        setPages((pg) => {
                            pg[currpage]['maincss']['lineHeight'] = newlh
                            return pg
                        })
                    }
                    else if (selectedpart === 'TOP') {
                        const newlh = (parseFloat(pages[currpage]['topcss']['lineHeight']) + 0.01).toFixed(2)
                        setShowinglineheight(newlh)
                        setPages((pg) => {
                            pg[currpage]['topcss']['lineHeight'] = newlh
                            return pg
                        })
                    }
                    else if (selectedpart === 'TABLE') {
                        const newlh = (parseFloat(pages[currpage]['alltablenamescss'][selectedtableindex]['lineHeight']) + 0.01).toFixed(2)
                        setShowinglineheight(newlh)
                        setPages((pg) => {
                            pg[currpage]['alltablenamescss'][selectedtableindex]['lineHeight'] = newlh
                            return pg
                        })
                    }
                    else if (selectedpart === 'SEATS') {
                        const newlh = (parseFloat(pages[currpage]['allseatnamescss'][selectedtableindex]['lineHeight']) + 0.01).toFixed(2)
                        setShowinglineheight(newlh)
                        setPages((pg) => {
                            pg[currpage]['allseatnamescss'][selectedtableindex]['lineHeight'] = newlh
                            return pg
                        })
                    }
                }}>+</button>
            </div>
        </div>
        : null

    const incdecls_btn = showedittools // letter spacing
        ? <div className='Scdesign_fs'>
            <div className='Scdesign_topbartitle'>Letter Spacing</div>
            <div className="Scdesign_topbarrow">
                <button
                    className='Scdesign_fssub'
                    onMouseDown={(e) => {
                        e.preventDefault()
                        if (selectedpart === 'MAIN') {
                            const newls = (parseFloat(pages[currpage]['maincss']['letterSpacing']) - 0.1).toFixed(2)
                            setShowingletterspacing(parseFloat(newls))
                            setPages((pg) => {
                                pg[currpage]['maincss']['letterSpacing'] = parseFloat(newls)
                                return pg
                            })
                        }
                        else if (selectedpart === 'TOP') {
                            const newls = (parseFloat(pages[currpage]['topcss']['letterSpacing']) - 0.1).toFixed(2)
                            setShowingletterspacing(parseFloat(newls))
                            setPages((pg) => {
                                pg[currpage]['topcss']['letterSpacing'] = parseFloat(newls)
                                return pg
                            })
                        }
                        else if (selectedpart === 'TABLE') {
                            const newls = (parseFloat(pages[currpage]['alltablenamescss'][selectedtableindex]['letterSpacing']) - 0.1).toFixed(2)
                            setShowingletterspacing(parseFloat(newls))
                            setPages((pg) => {
                                pg[currpage]['alltablenamescss'][selectedtableindex]['letterSpacing'] = parseFloat(newls)
                                return pg
                            })
                        }
                        else if (selectedpart === 'SEATS') {
                            const newls = (parseFloat(pages[currpage]['allseatnamescss'][selectedtableindex]['letterSpacing']) - 0.1).toFixed(2)
                            setShowingletterspacing(parseFloat(newls))
                            setPages((pg) => {
                                pg[currpage]['allseatnamescss'][selectedtableindex]['letterSpacing'] = parseFloat(newls)
                                return pg
                            })
                        }

                    }
                    }>-</button>
                <div className='Scdesign_fsnum' onMouseDown={(e) => { e.preventDefault() }}>{showingletterspacing}</div>
                <button
                    className='Scdesign_fsadd'
                    onMouseDown={(e) => {
                        e.preventDefault()
                        if (selectedpart === 'MAIN') {
                            const newls = (parseFloat(pages[currpage]['maincss']['letterSpacing']) + 0.1).toFixed(2)
                            setShowingletterspacing(parseFloat(newls))
                            setPages((pg) => {
                                pg[currpage]['maincss']['letterSpacing'] = parseFloat(newls)
                                return pg
                            })
                        }
                        else if (selectedpart === 'TOP') {
                            const newls = (parseFloat(pages[currpage]['topcss']['letterSpacing']) + 0.1).toFixed(2)
                            setShowingletterspacing(parseFloat(newls))
                            setPages((pg) => {
                                pg[currpage]['topcss']['letterSpacing'] = parseFloat(newls)
                                return pg
                            })
                        }
                        else if (selectedpart === 'TABLE') {
                            const newls = (parseFloat(pages[currpage]['alltablenamescss'][selectedtableindex]['letterSpacing']) + 0.1).toFixed(2)
                            setShowingletterspacing(parseFloat(newls))
                            setPages((pg) => {
                                pg[currpage]['alltablenamescss'][selectedtableindex]['letterSpacing'] = parseFloat(newls)
                                return pg
                            })
                        }
                        else if (selectedpart === 'SEATS') {
                            const newls = (parseFloat(pages[currpage]['allseatnamescss'][selectedtableindex]['letterSpacing']) + 0.1).toFixed(2)
                            setShowingletterspacing(parseFloat(newls))
                            setPages((pg) => {
                                pg[currpage]['allseatnamescss'][selectedtableindex]['letterSpacing'] = parseFloat(newls)
                                return pg
                            })
                        }
                    }}>+</button>
            </div>
        </div>
        : null


    const italic_btn = showedittools
        ? <button
            key={somethingedited + 'italic'}
            className={(
                (selectedpart === 'MAIN' && pages[currpage]['maincss']['fontStyle'] === 'italic')
                || (selectedpart === 'TOP' && pages[currpage]['topcss']['fontStyle'] === 'italic')
                || (selectedpart === 'TABLE' && pages[currpage]['alltablenamescss'][selectedtableindex] && pages[currpage]['alltablenamescss'][selectedtableindex]['fontStyle'] === 'italic')
                || (selectedpart === 'SEATS' && pages[currpage]['allseatnamescss'][selectedtableindex] && pages[currpage]['allseatnamescss'][selectedtableindex]['fontStyle'] === 'italic')
            ) ? 'Scdesign_editbtn_selected' : 'Scdesign_editbtn'}
            onMouseDown={(e) => {
                e.preventDefault();
                if (selectedpart === 'MAIN') {
                    if (pages[currpage]['maincss']['fontStyle'] === 'italic') {
                        setPages((pg) => {
                            pg[currpage]['maincss']['fontStyle'] = ''
                            return pg
                        })
                    }
                    else {
                        setPages((pg) => {
                            pg[currpage]['maincss']['fontStyle'] = 'italic'
                            return pg
                        })
                    }
                }
                else if (selectedpart === 'TOP') {
                    if (pages[currpage]['topcss']['fontStyle'] === 'italic') {
                        setPages((pg) => {
                            pg[currpage]['topcss']['fontStyle'] = ''
                            return pg
                        })
                    }
                    else {
                        setPages((pg) => {
                            pg[currpage]['topcss']['fontStyle'] = 'italic'
                            return pg
                        })
                    }
                }
                else if (selectedpart === 'TABLE') {
                    setPages((pg) => {
                        if (pg[currpage]['alltablenamescss'][selectedtableindex]['fontStyle'] === 'italic') {
                            pg[currpage]['alltablenamescss'][selectedtableindex]['fontStyle'] = ""
                        }
                        else {
                            pg[currpage]['alltablenamescss'][selectedtableindex]['fontStyle'] = "italic"

                        }
                        return pg
                    })
                }
                else if (selectedpart === 'SEATS') {
                    setPages((pg) => {
                        if (pg[currpage]['allseatnamescss'][selectedtableindex]['fontStyle'] === 'italic') {
                            pg[currpage]['allseatnamescss'][selectedtableindex]['fontStyle'] = ""
                        }
                        else {
                            pg[currpage]['allseatnamescss'][selectedtableindex]['fontStyle'] = "italic"

                        }
                        return pg
                    })
                }

                setSomethingedited(true)
            }}
        >
            Italic
        </button>
        : null

    const fontweight_btn = showedittools
        ? <button
            key={somethingedited + 'boldness'}
            className={((
                selectedpart === 'MAIN' && pages[currpage]['maincss']['fontWeight'] === 'bold')
                || (selectedpart === 'TOP' && pages[currpage]['topcss']['fontWeight'] === 'bold')
                || (selectedpart === 'TABLE' && pages[currpage]['alltablenamescss'][selectedtableindex] && pages[currpage]['alltablenamescss'][selectedtableindex]['fontWeight'] === 'bold')
                || (selectedpart === 'SEATS' && pages[currpage]['allseatnamescss'][selectedtableindex] && pages[currpage]['allseatnamescss'][selectedtableindex]['fontWeight'] === 'bold')
            ) ? 'Scdesign_editbtn_selected' : 'Scdesign_editbtn'}
            onMouseDown={(e) => {
                e.preventDefault();
                if (selectedpart === 'MAIN') {
                    if (pages[currpage]['maincss']['fontWeight'] === 'bold') {
                        setPages((pg) => {
                            pg[currpage]['maincss']['fontWeight'] = ''
                            return pg
                        })
                    }
                    else {
                        setPages((pg) => {
                            pg[currpage]['maincss']['fontWeight'] = 'bold'
                            return pg
                        })
                    }
                }
                else if (selectedpart === 'TOP') {
                    if (pages[currpage]['topcss']['fontWeight'] === 'bold') {
                        setPages((pg) => {
                            pg[currpage]['topcss']['fontWeight'] = ''
                            return pg
                        })
                    }
                    else {
                        setPages((pg) => {
                            pg[currpage]['topcss']['fontWeight'] = 'bold'
                            return pg
                        })
                    }
                }
                else if (selectedpart === 'TABLE') {
                    setPages((pg) => {
                        if (pg[currpage]['alltablenamescss'][selectedtableindex]['fontWeight'] === 'bold') {
                            pg[currpage]['alltablenamescss'][selectedtableindex]['fontWeight'] = ""
                        }
                        else {
                            pg[currpage]['alltablenamescss'][selectedtableindex]['fontWeight'] = "bold"

                        }
                        return pg
                    })
                }
                else if (selectedpart === 'SEATS') {
                    setPages((pg) => {
                        if (pg[currpage]['allseatnamescss'][selectedtableindex]['fontWeight'] === 'bold') {
                            pg[currpage]['allseatnamescss'][selectedtableindex]['fontWeight'] = ""
                        }
                        else {
                            pg[currpage]['allseatnamescss'][selectedtableindex]['fontWeight'] = "bold"

                        }
                        return pg
                    })
                }

                setSomethingedited(true)
            }}
        >
            Bold
        </button>
        : null

    const underline_btn = showedittools
        ? <button
            key={somethingedited + 'underline'}
            className={((
                selectedpart === 'MAIN' && pages[currpage]['maincss']['textDecoration'] === 'underline')
                || (selectedpart === 'TOP' && pages[currpage]['topcss']['textDecoration'] === 'underline')
                || (selectedpart === 'TABLE' && pages[currpage]['alltablenamescss'][selectedtableindex] && pages[currpage]['alltablenamescss'][selectedtableindex]['textDecoration'] === 'underline')
                || (selectedpart === 'SEATS' && pages[currpage]['allseatnamescss'][selectedtableindex] && pages[currpage]['allseatnamescss'][selectedtableindex]['textDecoration'] === 'underline')
            ) ? 'Scdesign_editbtn_selected' : 'Scdesign_editbtn'}
            onMouseDown={(e) => {
                e.preventDefault();
                if (selectedpart === 'MAIN') {
                    if (pages[currpage]['maincss']['textDecoration'] === 'underline') {
                        setPages((pg) => {
                            pg[currpage]['maincss']['textDecoration'] = ''
                            return pg
                        })
                    }
                    else {
                        setPages((pg) => {
                            pg[currpage]['maincss']['textDecoration'] = 'underline'
                            return pg
                        })
                    }
                }
                else if (selectedpart === 'TOP') {
                    if (pages[currpage]['topcss']['textDecoration'] === 'underline') {
                        setPages((pg) => {
                            pg[currpage]['topcss']['textDecoration'] = ''
                            return pg
                        })
                    }
                    else {
                        setPages((pg) => {
                            pg[currpage]['topcss']['textDecoration'] = 'underline'
                            return pg
                        })
                    }
                }
                else if (selectedpart === 'TABLE') {
                    setPages((pg) => {
                        if (pg[currpage]['alltablenamescss'][selectedtableindex]['textDecoration'] === 'underline') {
                            pg[currpage]['alltablenamescss'][selectedtableindex]['textDecoration'] = ""
                        }
                        else {
                            pg[currpage]['alltablenamescss'][selectedtableindex]['textDecoration'] = 'underline'

                        }
                        return pg
                    })
                }
                else if (selectedpart === 'SEATS') {
                    setPages((pg) => {
                        if (pg[currpage]['allseatnamescss'][selectedtableindex]['textDecoration'] === 'underline') {
                            pg[currpage]['allseatnamescss'][selectedtableindex]['textDecoration'] = ""
                        }
                        else {
                            pg[currpage]['allseatnamescss'][selectedtableindex]['textDecoration'] = "underline"

                        }
                        return pg
                    })
                }
                setSomethingedited(true)
            }}
        >
            Underline
        </button>
        : null

    const padding_btn = showedittools
        ? <div className='Scdesign_fs'>
            <div className='Scdesign_topbartitle'>Padding</div>
            <div className="Scdesign_topbarrow">
                <button
                    className='Scdesign_fssub'
                    onMouseDown={(e) => {
                        e.preventDefault()
                        if (selectedpart === 'MAIN') {
                            const newfs = parseInt(pages[currpage]['maincss']['padding'], 10) - 1
                            setShowingpadding(newfs)
                            setPages((pg) => {
                                pg[currpage]['maincss']['padding'] = newfs
                                return pg
                            })
                        }
                        else if (selectedpart === 'TOP') {
                            const newfs = parseInt(pages[currpage]['topcss']['padding'], 10) - 1
                            setShowingpadding(newfs)
                            setPages((pg) => {
                                pg[currpage]['topcss']['padding'] = newfs
                                return pg
                            })
                        }
                        else if (selectedpart === 'TABLE') {
                            setPages((pg) => {
                                pg[currpage]['alltablenamescss'][selectedtableindex]['padding'] -= 1
                                setShowingpadding(pg[currpage]['alltablenamescss'][selectedtableindex]['padding'])
                                return pg
                            })
                        }
                        else if (selectedpart === 'SEATS') {
                            setPages((pg) => {
                                pg[currpage]['allseatnamescss'][selectedtableindex]['padding'] -= 1
                                setShowingpadding(pg[currpage]['allseatnamescss'][selectedtableindex]['padding'])
                                return pg
                            })
                        }
                    }}>-</button>
                <div className='Scdesign_fsnum' onMouseDown={(e) => { e.preventDefault(); }}>{showingpadding}</div>
                <button
                    className='Scdesign_fsadd'
                    onMouseDown={(e) => {
                        e.preventDefault()
                        if (selectedpart === 'MAIN') {
                            const newfs = parseInt(pages[currpage]['maincss']['padding'], 10) + 1
                            setShowingpadding(newfs)
                            setPages((pg) => {
                                pg[currpage]['maincss']['padding'] = newfs
                                return pg
                            })
                        }
                        else if (selectedpart === 'TOP') {
                            const newfs = parseInt(pages[currpage]['topcss']['padding'], 10) + 1
                            setShowingpadding(newfs)
                            setPages((pg) => {
                                pg[currpage]['topcss']['padding'] = newfs
                                return pg
                            })
                        }
                        else if (selectedpart === 'TABLE') {
                            setPages((pg) => {
                                pg[currpage]['alltablenamescss'][selectedtableindex]['padding'] += 1
                                setShowingpadding(pg[currpage]['alltablenamescss'][selectedtableindex]['padding'])
                                return pg
                            })
                        }
                        else if (selectedpart === 'SEATS') {
                            setPages((pg) => {
                                pg[currpage]['allseatnamescss'][selectedtableindex]['padding'] += 1
                                setShowingpadding(pg[currpage]['allseatnamescss'][selectedtableindex]['padding'])
                                return pg
                            })
                        }
                        setSomethingedited(true)
                    }}>+</button>
            </div>
        </div>
        : null


    // setTemplate(parseInt(e.target.value, 10));
    // setSeatmodal(true); // show seat modal choose number of seats
    // setEmptypageshow(false); // close empty page 
    // setTemplatetype(sidebartemplatetype) // set to selected templatetype
    // sidebartemplatetype('CLASSIC')// reset to default
    // setFloralplacement(floralplacement)

    const bgcolor_btn = !showedittools && sidebar !== 'TEMPLATE' && !emptypageshow// will only show if user click on nothing and not show anything if 
        // const bgcolor_btn = !showedittools// will only show if user click on nothing and not show anything if 
        ? <button
            key={somethingedited + 'bgcolor'}
            className={'Scdesign_editbtn'}
            style={{ margin: 0, whiteSpace: "pre-wrap", lineHeight: '16px' }}
            onMouseDown={(e) => {
                e.preventDefault();
                setSidebar(sidebar === 'BGCOLOR' ? '' : 'BGCOLOR');
                setSomethingedited(true);
            }}
        >
            Background Color
        </button>
        : null

    // const download_btn = <div className='Scdesign_topbarstaticitems'>
    //     {!showedittools // will only show if user click on nothing
    //         ? <button
    //             key={somethingedited + 'dlbtn'}
    //             className={'Scdesign_delete'}
    //             style={{ margin: ' 0px 0px 0px 5px', right: 0 }}
    //             onClick={() => { setDownloadchart(true) }}
    //         >
    //             {DOWNLOAD_SMALL}
    //         </button>
    //         : null}
    // </div>

    const download_btn = <div className='Scdesign_topbarstaticitems'>
        {/* {!showedittools && template > -1 // will only show if user click on nothing */}
        {!showedittools  // will only show if user click on nothing
            ? <button
                key={somethingedited + 'dlbtn'}
                className='scedit_qrbtn'
                style={{ margin: ' 0px 0px 0px 5px', right: 0 }}
                onClick={() => {
                    setDownloadmodal(true)
                    setZoom(1)
                }}
            >
                {iconwrapper(DOWNLOAD_ICON, { width: 30, height: 30 })}
            </button>
            : null}

    </div>

    const justifycenterseatnameandtable_btn = !showedittools // will only show if user click on nothing, for the purpose of centering block(table name & seats ) within canvas
        ? <div key={'jc' + somethingedited + (pages[currpage] ? pages[currpage]['jccanva'] : '')}>
            {{
                'center': <button className="Scdesign_icon" value="flex-start" onMouseDown={(e) => updatePlacecontent(e, 'flex-start')}>
                    <Icon size={20} icon={horizontalMiddle} />
                </button>,
                'flex-start': <button className="Scdesign_icon" value="flex-end" onMouseDown={(e) => updatePlacecontent(e, 'flex-end')}>
                    <Icon size={20} icon={left} />
                </button>,
                'flex-end': <button className="Scdesign_icon" value="center" onMouseDown={(e) => updatePlacecontent(e, 'center')}>
                    <Icon size={20} icon={right} />
                </button>,
            }[(pages[currpage] ? pages[currpage]['jccanva'] : '')]}
        </div>
        : null

    const aligncenterseatnameandtable_btn = !showedittools // will only show if user click on nothing, for the purpose of centering block(table name & seats ) within canvas
        ? <div key={'align' + somethingedited + (pages[currpage] ? pages[currpage]['aligncanva'] : '')}>
            {{
                'center': <button className="Scdesign_icon" value="flex-start" onMouseDown={(e) => updateAlign(e, 'flex-start')}>
                    <Icon size={20} icon={verticalMiddle} />
                </button>,
                'flex-start': <button className="Scdesign_icon" value="flex-end" onMouseDown={(e) => updateAlign(e, 'flex-end')}>
                    <Icon size={20} icon={top} />
                </button>,
                'flex-end': <button className="Scdesign_icon" value="center" onMouseDown={(e) => updateAlign(e, 'center')}>
                    <Icon size={20} icon={bottom} />
                </button>,
            }[(pages[currpage] ? pages[currpage]['aligncanva'] : '')]}
        </div>
        : null

    const color_btn = showedittools
        ? <button
            key={somethingedited + 'fontcolor'}
            className={'Scdesign_editbtn ScdesignFontColorbtn'}
            onMouseDown={(e) => {
                setSidebar(sidebar === 'FONTCOLOR' ? '' : 'FONTCOLOR');
                setSomethingedited(true);
            }}
        >
            {`Font
            Color`}
        </button>
        : null

    const applycss_func = (csskey) => {
        const currentcss = pages[currpage][csskey][selectedtableindex]
        const currentcss_keys = Object.keys(currentcss)
        var temp_pages = pages.slice()
        for (let j = 0; j < pages[currpage][csskey].length; j++) {
            for (let i = 0; i < currentcss_keys.length; i++) {
                const currentcss_key = currentcss_keys[i]
                const currentcss_param = currentcss[currentcss_key]
                temp_pages[currpage][csskey][j][currentcss_key] = currentcss_param
                // if (currentcss_key === 'padding') { // padding is apply to both seats and table header
                //     temp_pages[currpage]['alltablenamescss'][j]['padding'] = currentcss_param
                //     temp_pages[currpage]['allseatnamescss'][j]['padding'] = currentcss_param
                // }
                // else {
                //     temp_pages[currpage][csskey][j][currentcss_key] = currentcss_param
                // }
            }
        }
        setPages(temp_pages)
    }

    const applyall_btn = showedittools && (selectedpart === 'TABLE' || selectedpart === 'SEATS') ?
        <button
            key={somethingedited + 'applyallstyle'}
            className={'Scdesign_editbtn Scdesign_shorttext'}
            onMouseDown={() => {
                if (selectedpart === 'TABLE') {
                    applycss_func('alltablenamescss')
                }
                else if (selectedpart === 'SEATS') {
                    applycss_func('allseatnamescss')
                }
                setSomethingedited(true);
            }}
        >
            {`Apply To
All ${selectedpart === 'TABLE' ? 'Table' : selectedpart === 'SEATS' ? 'Seat' : ''}`}
        </button>
        : null

    const updateAlign = (e, val) => {
        e.preventDefault()
        if (selectedpart === 'TOP') {
            setPages((pg) => {
                pg[currpage]['topcss']['textAlign'] = val
                return pg
            })
        }
        else if (selectedpart === 'MAIN') {
            setPages((pg) => {
                pg[currpage]['maincss']['textAlign'] = val
                return pg
            })
        }
        else if (selectedpart === '') { // nothing is clicked, meaning is the table and seats
            setPages((pg) => {
                pg[currpage]['aligncanva'] = val
                return pg
            })
        }
        setSomethingedited(true)
    }

    const textalign_btn = showedittools
        ? <div>
            {{
                'center': <button className="Scdesign_icon" value="flex-start" onMouseDown={(e) => updateAlign(e, 'flex-start')}>
                    <Icon size={20} icon={verticalMiddle} />
                </button>,
                'flex-start': <button className="Scdesign_icon" value="flex-end" onMouseDown={(e) => updateAlign(e, 'flex-end')}>
                    <Icon size={20} icon={top} />
                </button>,
                'flex-end': <button className="Scdesign_icon" value="center" onMouseDown={(e) => updateAlign(e, 'center')}>
                    <Icon size={20} icon={bottom} />
                </button>,
            }[{
                'MAIN': pages[currpage]['maincss']['textAlign'],
                'TOP': pages[currpage]['topcss']['textAlign']
            }[selectedpart]]}
        </div>
        : null

    const updatePlacecontent = (e, val) => {
        e.preventDefault()
        if (selectedpart === 'TOP') {
            setPages((pg) => {
                pg[currpage]['topcss']['placeContent'] = val
                return pg
            })
        }
        else if (selectedpart === 'MAIN') {
            setPages((pg) => {
                pg[currpage]['maincss']['placeContent'] = val
                return pg
            })
        }
        else if (selectedpart === '') { // nothing is clicked, meaning is the table and seats
            setPages((pg) => {
                pg[currpage]['jccanva'] = val
                return pg
            })
        }
        setSomethingedited(true)
    }

    const placecontent_btn = showedittools
        ? <div >
            {{
                'center': <button className="Scdesign_icon" value="flex-start" onMouseDown={(e) => updatePlacecontent(e, 'flex-start')}>
                    <Icon size={20} icon={horizontalMiddle} />
                </button>,
                'flex-start': <button className="Scdesign_icon" value="flex-end" onMouseDown={(e) => updatePlacecontent(e, 'flex-end')}>
                    <Icon size={20} icon={left} />
                </button>,
                'flex-end': <button className="Scdesign_icon" value="center" onMouseDown={(e) => updatePlacecontent(e, 'center')}>
                    <Icon size={20} icon={right} />
                </button>,
            }[{
                'MAIN': pages[currpage]['maincss']['placeContent'],
                'TOP': pages[currpage]['topcss']['placeContent']
            }[selectedpart]]}
        </div>
        : null

    const rmv_modal = rmvmod
        ? <Modcfm
            onHide={() => { setRmvmod(false) }}
            onOk={() => {
                setPages((page) => {
                    page.splice(parseInt(currpage, 10), 1)
                    if (page.length === 0) { setEmptypageshow(true) }
                    setSomethingedited(true)
                    setCurrpage(0)
                    return page
                })
                setRmvmod(false)
            }}
            onNo={() => setRmvmod(false)}
            txt={'Once you delete this, there is no going back. Please be certain.'}
        />
        : null

    const download_modal = <Portalmodal
        portaltype={'commonportal'}
        open={downloadmodal}
        onClose={() => { setDownloadmodal(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'>
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'} >
            <div className="inviteedit_helproot">

                <div className="inviteedit_helptitle" >Seating Chart Download</div>

                <div className="Details_grp">
                    {partialtitle_dom('Paper Size', {})}
                    <div className="Scdesign_portalrow">
                        <button className={`Scdesign_portalleftbtn ${downloadsize === 'A4' ? 'Scdesign_btnselectd' : ''}`} onClick={() => { setDownloadsize('A4') }}>A4</button>
                        <div style={{ height: '100%', width: 1, backgroundColor: 'var(--maindark)', }} />
                        <button className={`Scdesign_portalrighttbtn  ${downloadsize === 'A3' ? 'Scdesign_btnselectd' : ''}`} onClick={() => { setDownloadsize('A3') }}>A3</button>
                    </div>
                </div>

                <div className="Details_grp" >
                    {checkbox_dom(
                        downloadtransparent,
                        () => {
                            const txt = document.querySelector(".ce_checkboxtxt");
                            const checkbox = document.querySelector(".ce_checkbox");
                            checkbox.checked = !checkbox.checked;
                            setDownloadtransparent(!downloadtransparent)
                        },
                        'Transparent Background',
                    )}
                </div>

                <div className="Details_grp" >
                    {input_dom(
                        'File Type',
                        '.PNG',
                        '.PNG',
                        (e) => {
                        },
                        'png_id',
                        {},
                        true,
                        null,
                        {},
                        { marginLeft: 0, marginRight: 0, width: '100%', margin: 0, }
                    )}
                </div>


                <button
                    className="scedit_modalbtn"
                    style={{ marginBottom: 0, marginTop: 0, borderRadius: 5 }}
                    disabled={downloadchart}
                    onClick={() => {
                        setDownloadchart(true)
                    }}>
                    {downloadchart ? 'Generating...' : 'Download'}
                </button>
            </div>
        </div>
    </Portalmodal>

    return <div className="Scedit_designroot">
        <div className='Scdesign_topeditbar' onMouseDown={clicktooblivion}>
            {template_sidebar}
            {asset_sidebar}
            {ff_sidebar}
            {bgcolor_sidebar}
            {fontcolor_sidebar}
            <div className='Scdesign_topbarbtnitems'>
                {ff_btn}
                {italic_btn}
                {fontweight_btn}
                {textalign_btn}
                {placecontent_btn}
                {underline_btn}
                {bgcolor_btn}
                {justifycenterseatnameandtable_btn}
                {aligncenterseatnameandtable_btn}
                {color_btn}
                {padding_btn}
                {incdecfs_btn}
                {incdeclh_btn}
                {incdecls_btn}
                {applyall_btn}
            </div>
            {download_btn}
        </div>
        {page_dom}
        {seatnumselect_dom}
        {btmbar_dom}
        {assigntable_dom}
        {rmv_modal}
        {download_modal}
    </div>

}
export default Scdesign