import React, { useEffect, useState } from 'react';
import { Header, MainMenu } from '../components';
import { auspiciousbool, EMPTY, guestbookbool, PLOBJ } from '../../../config/constants';
import { READ_USER_TOOLDETAILS } from '../../../config/api'; // READ_USER_MAINTOOL, READ_NONUSER_MAINTOOL, READ_NONUSER_AUSCIPIOUSTOOL, READ_USER_BUDGETESTIMATORTOOL, READ_NONUSER_BUDGETESTIMATORTOOL
import { Retrieve_personal_info } from '../../../config/commonfunctions';
import { Appcard, Checkdevice } from '../../../components';
import { tools } from '../../../assets/homeuser';
import axios from "axios";
import Checklistmaker from '../Planning/Checklistmaker/Checklistmaker';
import Budgetmaker from '../Planning/Budgetmaker/Budgetmaker';
import Itinerarymaker from '../Planning/Itinerarymaker/Itinerarymaker';
import Invitationmaker from '../Planning/Invitationmaker/Invitationmaker';
import Seatingchart from '../Planning/Seatingchart/Seatingchart';
import Guestbookbuilder from '../Planning/Guestbookmaker/Guestbookbuilder';
import Qrmaker from '../Planning/Qrmaker/Qrmaker';
import Community from '../Planning/Community/Community'
import Auspicious from '../Planning/Auspicious/Auspicious'
import Budgetestimator from '../Planning/Budgetestimator/Budgetestimator'
import { Link } from 'react-router-dom'

// import Hashtag from '../Planning/Hashtag/Hashtag'
import './Tools.css'

// const RAWLINK_VARNAME_MAP = {
//     'auspiciousdate': 'Auspicious Date',
//     'budgetestimator': 'Budget Estimator',
//     'hashtag': 'Hashtag Generator'
// }


const Comparevenue = <div id='planningedit_root' style={{ display: 'flex', placeContent: 'flex-end', margin: 10 }}>
    <Link id='comparevenuelink' style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/compare/weddingvenue'} target="_blank" />
    <button className='planning_contbtn' onClick={() => document.getElementById('comparevenuelink').click()}>Continue</button>
</div>

const Preweddinglocationcurate = <div id='planningedit_root' style={{ display: 'flex', placeContent: 'flex-end', margin: 10 }}>
    <Link id='preweddinglocationcruate' style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/map'} target="_blank" />
    <button className='planning_contbtn' onClick={() => document.getElementById('preweddinglocationcruate').click()}>Continue</button>
</div>

const DTSTR = new Date().toLocaleDateString().split('/')

function Tools(props) {
    const { token } = Retrieve_personal_info()
    const { authuser } = props
    const device = Checkdevice()
    const [abbrev_userlocation, setAbbrev_userloaction] = useState(EMPTY)
    const [page, setPage] = useState('community')
    const [name, setName] = useState('')
    const [spousename, setSpousename] = useState('')
    const [date_w, setDate_w] = useState('')

    const [plobj, setPlobj] = useState({})

    const [firsttime, setFirsttime] = useState(false)
    const [usertype, setUsertype] = useState(false)

    // for auscipious date
    const [prevmthyr, setPrevmthyr] = useState(DTSTR[1] + '/' + DTSTR[2])
    const [mthyr, setMthyr] = useState(DTSTR[1] + '/' + DTSTR[2])

    const [PAGE_DOM_MAP, setPAGE_DOM_MAP] = useState({})

    useEffect(() => {
        const getPage = () => {
            // if (props && props.match && props.match.params && props.match.params.app) localpage = RAWLINK_VARNAME_MAP[props.match.params.app]
            // else localpage = 'Community'
            const { app } = props.match.params
            if (app && app.length > 0) {
                let lowercased_arr = Object.keys(PAGE_DOM_MAP).map((e) => e.toLowerCase())
                return lowercased_arr.includes(app.toLowerCase()) ? app : ''
            }
            else {
                return ''
            }
        }

        const currpage = getPage()
        if (currpage) {
            setPage(currpage) // Community, Auspicious Date, Budget Estimator
        }

        var new_PLOBJ = {}
        for (let i = 0; i < Object.keys(PLOBJ).length; i++) {
            const key = Object.keys(PLOBJ)[i]
            new_PLOBJ[key] = PLOBJ[key]
            new_PLOBJ[key]['dom'] = PLOBJ[key]
        }
        setPlobj(new_PLOBJ)

        if (authuser) { // is auth
            try {
                axios({
                    method: 'POST',
                    url: READ_USER_TOOLDETAILS,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                    data: { title: 'main' }
                }).then((result) => {
                    const { data } = result
                    if (authuser) setAbbrev_userloaction(data.location)
                    setDate_w(data.date_w)
                    setSpousename(data.spousename)
                    setName(data.name)
                }).catch((e) => { })
            }
            catch (e) { }
        }

        // try {
        //     const options = getOptions(currpage)
        //     console.log('options', options)
        //     axios(options).then((result) => {
        //         const { data } = result
        //         setData(data.result)
        //         if (authuser) setAbbrev_userloaction(data.location)
        //         setDate_w(data.date_w)
        //         setSpousename(data.spousename)
        //         setName(data.name)
        //     }).catch((e) => { })
        // }
        // catch (e) { console.log(e)}


    }, [])

    useEffect(() => {
        let local_PAGE_DOM_MAP = guestbookbool
            ? {
                'community': <Community device={device} />,
                'auspiciousdate': <Auspicious />,
                'budgetestimator': <Budgetestimator />,
                'checklist': <Checklistmaker {...props} />,
                'budget': <Budgetmaker {...props} />,
                'itinerary': <Itinerarymaker  {...props} />,
                'invitation': <Invitationmaker {...props} />,
                'seatingchart': <Seatingchart {...props} />,
                'guestbook': <Guestbookbuilder {...props} />,
                'qr': <Qrmaker {...props} />,
                'comparevenue': Comparevenue,
                'preweddinglocationcurate': Preweddinglocationcurate
            }
            : {
                'community': <Community device={device} />,
                'auspiciousdate': <Auspicious />,
                'budgetestimator': <Budgetestimator />,
                'checklist': <Checklistmaker {...props} />,
                'budget': <Budgetmaker {...props} />,
                'itinerary': <Itinerarymaker  {...props} />,
                'invitation': <Invitationmaker {...props} />,
                'seatingchart': <Seatingchart {...props} />,
                'qr': <Qrmaker {...props} />,
                'comparevenue': Comparevenue,
                'preweddinglocationcurate': Preweddinglocationcurate
            }

        setPAGE_DOM_MAP(local_PAGE_DOM_MAP)
    }, [guestbookbool])

    // const getOptions = (currpage) => {
    //     const USERPAGE_API_MAP = {
    //         // 'Community': { url: READ_USER_MAINTOOL, data: { title: 'main' } },
    //         // 'Auspiciousdate': { url: READ_USER_AUSCIPIOUSTOOL, data: { title: mthyr } },
    //         // 'Budgetestimator': { url: READ_USER_BUDGETESTIMATORTOOL, data: { title: 'budgetpa' } },
    //         // 'Housing': { url: READ_NONUSER_BUDGETESTIMATORTOOL, data: { title: 'budgetpa' } }
    //     }
    //     const NONUSERPAGE_API_MAP = {
    //         // 'Community': { url: READ_NONUSER_MAINTOOL, data: { title: 'main' } },
    //         // 'Auspiciousdate': { url: READ_NONUSER_AUSCIPIOUSTOOL, data: { title: mthyr } },
    //         // 'Budgetestimator': { url: READ_NONUSER_BUDGETESTIMATORTOOL, data: { title: 'budgetpa' } },
    //         // 'Housing': { url: READ_NONUSER_BUDGETESTIMATORTOOL, data: { title: 'budgetpa' } }
    //     }
    //     let options = { method: 'POST' }
    //     if (authuser) { // is auth
    //         const { url, data } = USERPAGE_API_MAP[currpage]
    //         options['url'] = url
    //         options['data'] = data
    //         options['headers'] = { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } //need token from the web
    //     }
    //     else { // not authenticated
    //         const { url, data } = NONUSERPAGE_API_MAP[currpage]
    //         options['url'] = url
    //         options['data'] = data
    //     }
    //     return options
    // }

    const { fullname, txt } = PLOBJ[page]

    const renderAppCard = (key, name, icon) => (
        <div key={key + '_id'} style={{ display: 'flex', flexDirection: 'row' }}>
            <Appcard
                id={key + '_id'}
                device={device}
                onClick={() => {
                    setPage(key);
                    props.history.push(`/tools/${key}`);
                }}
                icon={icon}
                content={name}
                selected={key === page}
            />
            <div
                key={'border_key'}
                style={{
                    height: device === 'WEB' ? 145 : 140,
                    width: 1,
                    borderLeft: '1px solid var(--subgrey)',
                    margin: '0px 10px',
                }}
            />
        </div>
    );

    return (
        <div className="Tools_root">
            {authuser
                ? <Header
                    {...props}
                    usertype={'Tools'}
                    wedorevent={props.wedorevent}
                    abbrev_userlocation={abbrev_userlocation}
                    name={name}
                    spousename={spousename}
                    date_w={date_w}
                >
                    <MainMenu {...props} selectedMainCategory={'Tools'} usertype={props.usertype} />
                </Header>
                : <div className="Tools_unauthheader" style={{ backgroundImage: "url(" + tools + ")" }}>
                    {/* <div className="Tools_unauthheadertransparency" /> */}
                </div>}

            {/* <div className="planning_apps_strip" style={{ width: '100%', backgroundColor: '#fff5f5' }}>
                {Object.keys(plobj).map((key, index) => {
                    const { name, icon } = plobj[key]
                    if ((name === 'Auspicious' + `\n` + 'Date') && !auspiciousbool) {
                        return
                    }
                    if (name === 'Auspicious' + `\n` + 'Date' && auspiciousbool) {
                        return <div key={key + '_id'} style={{ display: 'flex', flexDirection: 'row' }} >
                            <Appcard
                                id={key + '_id'}
                                device={device}
                                onClick={() => {
                                    setPage(key)
                                    props.history.push(`/tools/${key}`)
                                }}
                                icon={icon}
                                content={name}
                                selected={key === page}
                            />
                            <div key={'border_key'} style={{ height: device === 'WEB' ? 145 : 140, width: 1, borderLeft: '1px solid var(--subgrey)', margin: '0px 10px' }} />
                        </div>
                    }
                    else if (name === 'Compare' + `\n` + 'Venue') {
                        return <div key={key + '_id'} style={{ display: 'flex', flexDirection: 'row' }} >
                            <Appcard
                                id={key + '_id'}
                                device={device}
                                onClick={() => {
                                    setPage(key)
                                    props.history.push(`/tools/${key}`)
                                }}
                                icon={icon}
                                content={name}
                                selected={key === page}
                            />
                            <div key={'border_key'} style={{ height: device === 'WEB' ? 145 : 140, width: 1, borderLeft: '1px solid var(--subgrey)', margin: '0px 10px' }} />
                        </div>
                    }
                    else if (name === 'Pre-Wedding' + '\n' + 'Location' + '\n' + 'Curate') {
                        return null
                        return <div key={key + '_id'} style={{ display: 'flex', flexDirection: 'row' }} >
                            <Appcard
                                id={key + '_id'}
                                device={device}
                                onClick={() => {
                                    setPage(key)
                                    props.history.push(`/tools/${key}`)
                                }}
                                icon={icon}
                                content={name}
                                selected={key === page}
                            />
                            <div key={'border_key'} style={{ height: device === 'WEB' ? 145 : 140, width: 1, borderLeft: '1px solid var(--subgrey)', margin: '0px 10px' }} />
                        </div>
                    }
                    else {
                        return <Appcard
                            id={key + '_id'}
                            key={key + '_id'}
                            device={device}
                            onClick={() => {
                                setPage(key)
                                props.history.push(`/tools/${key}`)
                            }}
                            icon={icon}
                            content={name}
                            selected={key === page}
                        />
                    }
                })}
            </div> */}
            <div className="planning_apps_strip" style={{ width: '100%', backgroundColor: '#fff5f5' }}>
                {Object.entries(plobj).map(([key, { name, icon }]) => {
                    if (name === 'Auspicious\nDate' && !auspiciousbool) return null;
                    if (['Auspicious\nDate', 'Compare\nVenue'].includes(name)) {
                        return renderAppCard(key, name, icon);
                    }
                    if (name === 'Pre-Wedding\nLocation\nCurate') return null;
                    return (
                        <Appcard
                            id={key + '_id'}
                            key={key + '_id'}
                            device={device}
                            onClick={() => {
                                setPage(key);
                                props.history.push(`/tools/${key}`);
                            }}
                            icon={icon}
                            content={name}
                            selected={key === page}
                        />
                    );
                })}
            </div>

            <div className='planning_tool_title'>
                <div>{fullname}</div>
                <div className='planning_tool_subtitle'>{txt}</div>
            </div>

            <div className="Tools_authitem">
                {authuser || ['auspiciousdate', 'community', 'comparevenue'].includes(page)
                    ? (PAGE_DOM_MAP[page])
                    : (<div className="planning_tool_subtitle planning_tool_login">Please Login</div>)}
            </div>

        </div>
    )
}

export default Tools