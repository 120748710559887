import React, { useEffect, useState } from 'react'
import { Retrieve_personal_info, iconwrapper } from '../../../../../../config/commonfunctions';
import { ADD_ICON, PENCIL_ICON, CROSSPENCIL_ICON, BLOCKLARGE_ICON, CROSS_ICON, DRAG_ICON } from '../../../../../../config/svgicons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Modcfm, Portalmodal } from '../../../../../../components';
import Explain from "../Explain";
import { inputdescribe_dom, partialtitle_dom } from '../Commonelements/Commonelements';
import { EDIT_ICON } from '../../../../../../config/constants';
import './Party.css'
import { S3CLOUD_CLOUDFRONT, S3CLOUD_CLOUDFRONT_ASSET } from '../../../../../../config/s3directories';

const dragsvgcss = { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5, pointerEvents: 'none' }
const editsvgcss = { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5, pointerEvents: 'none' }
const crosssvgcss = { ...dragsvgcss, color: 'var(--mainpink)', pointerEvents: 'none' }
const addsvgcss = { width: 23, height: 23, display: 'flex', placeContent: 'center', pointerEvents: 'none', pointerEvents: 'none' }
const blocksvgcss = { ...dragsvgcss, color: 'var(--subdark)', pointerEvents: 'none' }

const TITLE = 'Wedding Party'
const TEXT = `Whether it’s a close friend or a bridesmaid or groomsman, acknowledging their role on your big day is a nice touch. Highlight them on your wedding website to show you care and keep guests informed.`

const mobileportalcss = { width: '100%', height: '100%', backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)', overflowY: 'auto' }
const webtabletportalcss = { width: 600, height: 690, backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }

const DEFAULT_PAGE = 0
const SWAP_OR_EDIT_PAGE = 1

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'white',
    padding: isDraggingOver ? 5 : 0,
});

function Party(props) {
    const { device, partialwt, showsave, setShowsave, updateBackup, clearBackup, restoreBackup, saveSinglepartymember } = props

    const [firsttime, setFirsttime] = useState(true)
    const [page, setPage] = useState(DEFAULT_PAGE) // 0 : default party page, 1 : swapping page, 2 : create schedule page, 3 : edit page
    const [editmode, setEditmode] = useState(false)
    const [editdata, setEditdata] = useState({})

    // Party arr
    const [partydata, setPartydata] = useState([])
    const [partyproperties, setPartyproperties] = useState({})

    // remove 
    const [rmvindex, setRmvindex] = useState(-1) // index to remove
    const [rmvcfm, setRmvcfm] = useState(false) // user click cfm or not cfm delete
    const [showrmvmod, setShowrmvmod] = useState(false) // toggle rmv modal to title user


    useEffect(() => {
        if (firsttime) {
            setPartydata(props.partydata.slice())

            setPartyproperties(JSON.parse(JSON.stringify(props.partyproperties)))
            setFirsttime(false)
            setPage(DEFAULT_PAGE)
        }
    }, [])

    useEffect(() => {
        if (!firsttime) {
            setPartydata(props.partydata.slice())
        }
    }, [props.partydata])

    useEffect(() => {
        if (!firsttime) {
            setPartyproperties(JSON.parse(JSON.stringify(props.partyproperties)))
        }
    }, [props.partyproperties])

    useEffect(() => {
        if (rmvcfm) {
            const id = JSON.parse(JSON.stringify(partydata))[rmvindex]['id']
            saveSinglepartymember({ deleteid: id }, 'REMOVE').then((result) => {
                if (result === 'SUCCESS') {
                    setPage(DEFAULT_PAGE)
                    setShowsave(false)
                    setRmvcfm(false)
                }
            })
        }
    }, [rmvcfm])

    const onDragend = (result) => {
        const { source, destination } = result
        if (!destination) { return }
        else {
            if (page !== SWAP_OR_EDIT_PAGE && source.index !== destination.index) {
                setPage(SWAP_OR_EDIT_PAGE)
            }
            else if (page === SWAP_OR_EDIT_PAGE && source.index === destination.index) {
                return
            }
            else if (source.index === destination.index) {
                return
            }

            if (!showsave) { updateBackup(); setShowsave(true); }
            const local_partydata = JSON.parse(JSON.stringify(partydata))
            let curr_partydata = local_partydata.slice()
            const [removed] = curr_partydata.splice(source.index, 1);
            curr_partydata.splice(destination.index, 0, removed);
            setPartydata(curr_partydata)
        }
    }

    const revert = () => {
        setShowsave(false)
        restoreBackup()
        setPage(DEFAULT_PAGE)
    }

    const create = (action) => {
        saveSinglepartymember(partydata, action).then((result) => {
            if (result === 'SUCCESS') {
                setPage(DEFAULT_PAGE)
                setShowsave(false)
                clearBackup()
            }
        })
    }

    const update = (action) => {
        saveSinglepartymember({ party: partydata, partyproperties: partyproperties }, action).then((result) => {
            if (result === 'SUCCESS') {
                setShowsave(false)
                restoreBackup()
                setPage(DEFAULT_PAGE)
            }
        })
    }

    const updatePartypropertiesformat = (e) => {
        const { value } = e.target
        if (!showsave) { updateBackup(); setShowsave(true); setPage(SWAP_OR_EDIT_PAGE); }
        const new_partyproperties = JSON.parse(JSON.stringify(props.partyproperties))
        new_partyproperties['format'] = parseInt(value, 10)
        props.updatePartyproperties(new_partyproperties)
    }

    const updatePartypropertiesimgshape = (e) => {
        const { value } = e.target
        if (!showsave) { updateBackup(); setShowsave(true); setPage(SWAP_OR_EDIT_PAGE); }
        const new_partyproperties = JSON.parse(JSON.stringify(props.partyproperties))
        new_partyproperties['imageshape'] = parseInt(value, 10)
        props.updatePartyproperties(new_partyproperties)
    }

    const remove = (i) => { // see useEffect
        setShowrmvmod(() => {
            setRmvindex(i)
            return true
        })
    }

    const btmbarstyle = {
        'MOBILE': { position: 'fixed', bottom: 0, left: 0, width: '100%' },
        'TABLET': { position: 'fixed', bottom: 10, left: 0, width: partialwt - 1 },
        'WEB': { position: 'fixed', bottom: 0, left: 0, width: partialwt - 1 }
    }[device]

    const btmbar = {
        // display question
        0: <div className='iedit_swaproot' style={btmbarstyle}>
            <button className='iedit_add' onClick={() => { if (!showsave) { updateBackup(); setShowsave(true); }; create('ADD'); }}>{iconwrapper(ADD_ICON, addsvgcss)}</button>
        </div>,
        // swap or edit question
        1: <div className='iedit_swaproot' style={btmbarstyle}>
            <button
                className='iedit_cancel'
                disabled={!showsave}
                style={{ opacity: !showsave ? 0.5 : 1 }}
                onClick={revert}
            >
                Revert
            </button>
            <button className='iedit_save' onClick={() => { update('SWAP_CHANGE_PROPERTIES') }}>Save</button>
        </div>
    }[page]

    const showEdit = (e) => {
        setEditmode(true)
        const { photo, title, name, number, email, info, id } = partydata[e.target.value]
        setEditdata({ photo, title, name, number, email, info, id, index: parseInt(e.target.value, 10) })
    }

    // [
    //     {
    //         photo: 'https://weaveblisscloud.s3.ap-southeast-1.amazonaws.com/assets/invitation/party/f1.png',
    //         title: 'Bridesmaid',
    //         name: 'Michelle Lombard',
    //         number: '',
    //         email: '',
    //         info: 'Michelle met Hanna in University when they were both studying for their undergrad. Emily is a free-spirit and is always down for an adventure. They’ve had their fair share of spontaneous getaways and late nights together in the library to catch up on assignments. Emily is currently living and working in Peru as a travel guide.'
    //     },
    //     {
    //         photo: 'https://weaveblisscloud.s3.ap-southeast-1.amazonaws.com/assets/invitation/party/m1.png',
    //         title: 'Groomsman',
    //         name: 'Ben Stern',
    //         number: '',
    //         email: '',
    //         info: 'Caleb’s obsession with mechanical keyboards is thanks to this guy. Ben is originally from Melbourne, but moved to Silicon Valley to pursue his own start-up venture. We miss him a lot, but we are always able to pick up from where we left off and can’t wait to discover more craft brews when he’s back in town!'
    //     }
    // ]



    const dnd = <div className='Party_root'>
        <div className='Details_grp'>
            {partialtitle_dom('Info Presentation', {})}
            {inputdescribe_dom('Select how you want the info text to be presented.')}
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                <button
                    className={`${(partyproperties && partyproperties['format'] === 0) ? 'Party_selectedbtn' : 'Party_selectbtn'}`}
                    onClick={updatePartypropertiesformat}
                    value={0}
                >
                    Show directly
                </button>
                <button
                    className={`${(partyproperties && partyproperties['format'] === 1) ? 'Party_selectedbtn' : 'Party_selectbtn'}`}
                    onClick={updatePartypropertiesformat}
                    value={1}
                >
                    Show in portal
                </button>
            </div>
        </div>
        <div className='Details_grp'>
            {partialtitle_dom('Image Shape', {})}
            {inputdescribe_dom('Define the shape of the image: circular or square.')}
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                <button
                    className={`${(partyproperties && partyproperties['imageshape'] === 0) ? 'Party_selectedbtn' : 'Party_selectbtn'}`}
                    onClick={updatePartypropertiesimgshape}
                    value={0}
                >
                    Square
                </button>
                <button
                    className={`${(partyproperties && partyproperties['imageshape'] === 1) ? 'Party_selectedbtn' : 'Party_selectbtn'}`}
                    onClick={updatePartypropertiesimgshape}
                    value={1}
                >
                    Circular
                </button>
            </div>
        </div>
        <div className='Details_grp'>
            {partialtitle_dom('Your party members', {})}
            {inputdescribe_dom('Add party members, upload a favorite photo, and share additional information about them.')}
            <div style={{ height: 5 }} />

            <DragDropContext onDragEnd={onDragend} style={{ overflow: 'visible' }}>
                <Droppable droppableId={'droppable'} >
                    {(provided, snapshot) => (
                        <div className='Detailsdrag_root' ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                            {partydata.map((item, i) => {
                                const { photo, title, name, number, email, info, } = item
                                return <Draggable key={`${i}_`} draggableId={`${i}id`} index={i}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                            <div className="Party_itemroot" key={i + 'qna'} >

                                                {page === DEFAULT_PAGE
                                                    ? <span className='Detailsdrag_closeicon' onClick={() => remove(i)}>{iconwrapper(CROSS_ICON, crosssvgcss)}</span>
                                                    : <span className='Detailsdrag_closeicon'>{iconwrapper(BLOCKLARGE_ICON, blocksvgcss)}</span>}

                                                <div className='Detailsdrag_col' style={{ backgroundColor: '#ffffff', borderTop: '1px solid rgb(204, 204, 204)', borderBottom: '1px solid rgb(204, 204, 204)' }}>
                                                    <div className='Party_row'>
                                                        <img className='Party_img' src={photo} />
                                                        <div className='Party_col'>
                                                            <div className='Party_name'>{name}</div>
                                                            <div className='Party_title'>{title}</div>
                                                            <div className='Party_info'>{info}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid rgb(204, 204, 204)', borderRadius: '0px 5px 5px 0px' }}>

                                                    <div className='Party_shifticon'  {...provided.dragHandleProps}>
                                                        {iconwrapper(DRAG_ICON, dragsvgcss)}
                                                    </div>

                                                    <button className='Party_editicon' value={i} onClick={showEdit}>
                                                        {page === DEFAULT_PAGE
                                                            ? iconwrapper(PENCIL_ICON, editsvgcss)
                                                            : iconwrapper(CROSSPENCIL_ICON, blocksvgcss)}
                                                    </button>

                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

        </div>
        {btmbar}
    </div>

    const updateSingleprofile = () => {
        if (Object.keys(editdata).includes('photofile')) {
            const formdata = new FormData();
            const file = editdata['photofile']
            formdata.append('title', editdata.title)
            formdata.append('name', editdata.name)
            formdata.append('number', editdata.number)
            formdata.append('email', editdata.email)
            formdata.append('info', editdata.info)
            formdata.append('id', editdata.id)
            formdata.append('file', new File([file], file.name, { type: file.type }))
            saveSinglepartymember(
                {
                    index: editdata['index'],
                    formdata: formdata,
                    editdata: editdata,
                    closeportal: () => {
                        setEditmode(editmode ? false : true)
                        setEditdata({})
                    }
                },
                'GTIMG_SINGLEPARTYMEMBER')

        }
        else {// without img
            let data = JSON.parse(JSON.stringify(editdata))
            saveSinglepartymember(
                {
                    singlememberdata: data,
                    closeportal: () => {
                        setEditmode(editmode ? false : true)
                        setEditdata({})
                    }
                },
                'NOIMG_SINGLEPARTYMEMBER')
        }
    }

    const portal = <Portalmodal
        portaltype={'commonportal'}
        open={editmode}
        onClose={() => setEditmode(false)}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ 'WEB': webtabletportalcss, 'TABLET': webtabletportalcss, 'MOBILE': mobileportalcss }[device]}
        closestyle={{ right: 0 }}
        floatingclosecolor='var(--maindark)'
    >
        <div style={{ marginLeft: 10, height: 60, minHeight: 60 }} />

        {/* photo, title, name, number, email, info  */}
        <div className='Party_portalroot'>
            {/* 
            <input
                id="Partyimginput"
                style={{ maxWidth: 0, maxHeight: 0, backgroundColor: 'transparent', border: 'none' }}
                type="file"
                accept="image/*" // Accept only image files
                onChange={(e) => {
                    const file = e.target.files[0]; // Get the uploaded file
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            
                            setEditdata((prev) => ({ ...prev, photo: event.target.result, photofile: new_file }));
                        };
                        reader.readAsDataURL(file); // Convert the file to a data URL
                    }
                }}
            /> */}
            <input
                id="Partyimginput"
                style={{ maxWidth: 0, maxHeight: 0, backgroundColor: 'transparent', border: 'none' }}
                type="file"
                accept="image/*" // Accept only image files
                onChange={(e) => {
                    const file = e.target.files[0]; // Get the first (and typically only) file from the FileList
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                            setEditdata((prev) => ({
                                ...prev,
                                photo: event.target.result,
                                photofile: file
                            }))
                        };
                        reader.readAsDataURL(file); // Read the file as a data URL for preview
                    }
                }}
            />

            <img
                src={editdata['photo']}
                className="Party_img"
                alt="Preview"
                onClick={() => document.getElementById("Partyimginput").click()}
            />

            <div className='Details_grp'>
                {partialtitle_dom('Name', {})}
                <input
                    type="text"
                    name="comment"
                    className="ig_input"
                    onChange={(e) => {
                        const { value } = e.target;
                        setEditdata((prev) => ({ ...prev, name: value }));
                    }}
                    value={editdata['name']}
                />
            </div>

            <div className='Details_grp'>
                {partialtitle_dom('Title', {})}
                {inputdescribe_dom('Birdesmaid or Groomsman or something else')}
                <input
                    name="title"
                    className='ig_input'
                    onChange={(e) => {
                        const { value } = e.target;
                        setEditdata((prev) => ({ ...prev, title: value }));
                    }}
                    value={editdata['title']}
                />
            </div>

            <div className='Details_grp'>
                {partialtitle_dom('Number', {})}
                <input
                    name="number"
                    className="ig_input"
                    placeholder="Enter your contact number"
                    onChange={(e) => {
                        const { value } = e.target;
                        // Allow only numbers
                        if (/^\d*$/.test(value)) {
                            setEditdata((prev) => ({ ...prev, number: value }));
                        }
                    }}
                    value={editdata['number']}
                />
            </div>

            <div className='Details_grp'>
                {partialtitle_dom('Email', {})}
                <input
                    type="email" // Change to email type
                    name="email" // Update name to reflect its purpose
                    className="ig_input"
                    placeholder="Enter your email address" // Change placeholder
                    onChange={(e) => {
                        const { value } = e.target;
                        setEditdata((prev) => ({ ...prev, email: value })); // Update state
                    }}
                    value={editdata['email']} // Use 'email' key in state
                />
            </div>

            <div className='Details_grp'>
                {partialtitle_dom('Info', {})}
                <textarea
                    rows="4"
                    cols="50"
                    name="comment"
                    form="usrform"
                    className='Details_txtarea'
                    onChange={(e) => {
                        const { value } = e.target;
                        setEditdata((prev) => ({ ...prev, info: value }));
                    }}
                    value={editdata['info']}
                />
            </div>
            <div className='Party_updatediv'>
                <button className='Party_portalupdate' onClick={updateSingleprofile}>Update</button>
            </div>


        </div>
    </Portalmodal>

    return (
        <div className='Ieditcomp_div'
            style={{
                height: {
                    'WEB': 'calc(100% - 70px - 65px)',
                    'TABLET': 'calc(100% - 70px - 65px)',
                    'MOBILE': 'calc(100% - 70px)'
                }[device],
                marginBottom: {
                    'MOBILE': 65
                }[device],
            }}>
            <Explain title={TITLE} txt={TEXT} />
            {dnd}
            {portal}
            {showrmvmod
                ? <Modcfm
                    onHide={() => { setShowrmvmod(false) }}
                    onOk={() => { setShowrmvmod(false); setRmvcfm(true); }}
                    onNo={() => { setShowrmvmod(false); setRmvcfm(false); setRmvindex(-1); }}
                    txt={'Once you delete this, there is no going back. Please be certain.'}
                />
                : null}
        </div>
    )
}

export default Party