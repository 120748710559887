import React, { useEffect, useState } from 'react'
import { Checkdevice, ScreenWidth } from '../../../../../../components'
import { T1sim, T2sim, T3sim, T1simmobile, T2simmobile, T3simmobile } from './components'
import { Togglebutton } from '../../../../../../basic_components'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { LAPTOP_ICON, MOBILE_ICON } from '../../../../../../config/svgicons'
import './Simulator.css'


function Simulator(props) {
    const { devicewt, displayseq, displayonoffmap, displaytxtmap, templatetype, templatecolor, name, spousename, textures, styleborder, styleborderwt, styleborderbr, stylebordercolor, css_map, styletype, styleformation, styleformationmobile, styleorientation, styleorientationmobile, stylesep, itype, ntw, ntm, maintext, subtext, date_w, date_e, dt_type, location, location_map,time, fc, priff, secff, locationff, datetimeff, lat, long, images, qnas, schedules, locurl, whowwemet, wproposal, rsvpactive, socialmedias, promote, mapmode, locdescript, menutype, menuseq, btmbarff, menustyle, svgoverlay_map, imgcss_map, menuicongate, party, partyproperties } = props // templatebgcolor, titletext,ntt, naw, nat, nam, urlhandler,
    const [simulatordevice, setSimulatordevice] = useState('WEB')
    const [wt, setWt] = useState(-1) // simulator wt
    const [ht, setHt] = useState(-1) // simulator ht
    const device = Checkdevice()

    useEffect(() => {
        if (device === 'WEB') {
            if (simulatordevice === 'WEB') {
                if (devicewt < 1190) {
                    setWt(400 * 1.4)
                    setHt(280 * 1.6)
                }
                else {
                    setWt(400 * 1.5)
                    setHt(280 * 1.7)
                }
            }
            else if (simulatordevice === 'MOBILE') {
                setWt(380 * 0.8)
                setHt(600 * 0.8)
            }
        }
        else if (device === 'TABLET') {
            if (simulatordevice === 'WEB') {
                // setWt(410)
                // setHt(300)
                setWt(400 * 1.4)
                setHt(280 * 1.6)
            }
            else if (simulatordevice === 'MOBILE') {
                setWt(380 * 0.8)
                setHt(600 * 0.8)
            }
        }
    }, [simulatordevice, device])

    useEffect(() => {
        if (device === 'WEB') {
            if (simulatordevice === 'WEB') {
                if (devicewt < 1190) {
                    setWt(400 * 1.4)
                    setHt(280 * 1.6)
                }
                else {
                    setWt(400 * 1.5)
                    setHt(280 * 1.7)
                }
            }
            else if (simulatordevice === 'MOBILE') {
                setWt(380 * 0.8)
                setHt(600 * 0.8)
            }
        }
        else if (device === 'TABLET') {
            if (simulatordevice === 'WEB') {
                setWt(400 * 1.4)
                setHt(280 * 1.6)
            }
            else if (simulatordevice === 'MOBILE') {
                setWt(380 * 0.8)
                setHt(600 * 0.8)
            }
        }
    }, [devicewt])

    return <div className={`simulator_root`}>
        <div className='simulator_deviceroot'>
            <Togglebutton
                singlebtnstyle={{ width: 40, height: 40 }}
                funcs={[
                    () => { setSimulatordevice('WEB') },
                    () => { setSimulatordevice('MOBILE') },
                ]}
                txts={[
                    iconwrapper(LAPTOP_ICON, { width: 25, height: 25 }),
                    iconwrapper(MOBILE_ICON, { width: 25, height: 25 })
                ]}
                btnselected={{ 'WEB': 0, 'MOBILE': 1 }[simulatordevice]}
            />
        </div>

        {{
            'WEB':
                {
                    1: <T1sim wt={wt} ht={ht} displayseq={displayseq} displayonoffmap={displayonoffmap} displaytxtmap={displaytxtmap} templatecolor={templatecolor} textures={textures} styleborder={styleborder} styleborderwt={styleborderwt} styleborderbr={styleborderbr} stylebordercolor={stylebordercolor} name={name} spousename={spousename} ntw={ntw} fc={fc} priff={priff} secff={secff} locationff={locationff} btmbarff={btmbarff} datetimeff={datetimeff} images={images} itype={itype} maintext={maintext} css_map={css_map} location={location} location_map={location_map} lat={lat} long={long} date_e={date_e} date_w={date_w} time={time} dt_type={dt_type} whowwemet={whowwemet} stylesep={stylesep} wproposal={wproposal} schedules={schedules} locurl={locurl} qnas={qnas} styleformation={styleformation} styleorientation={styleorientation} styletype={styletype} rsvpactive={rsvpactive} subtext={subtext} socialmedias={socialmedias} promote={promote} mapmode={mapmode} locdescript={locdescript} menutype={menutype} menuseq={menuseq} menustyle={menustyle} svgoverlay_map={svgoverlay_map} imgcss_map={imgcss_map}menuicongate={menuicongate} party={party} partyproperties={partyproperties}/>,
                    2: <T2sim />,
                    3: <T3sim />,
                }[templatetype],
            'MOBILE':
                {
                    1: <T1simmobile wt={wt} ht={ht} displayseq={displayseq} displayonoffmap={displayonoffmap} displaytxtmap={displaytxtmap} templatecolor={templatecolor} textures={textures} styleborder={styleborder} styleborderwt={styleborderwt} styleborderbr={styleborderbr} stylebordercolor={stylebordercolor} name={name} spousename={spousename} ntm={ntm} fc={fc} priff={priff} secff={secff} locationff={locationff} btmbarff={btmbarff} datetimeff={datetimeff} images={images} itype={itype} maintext={maintext} css_map={css_map} location={location} location_map={location_map} lat={lat} long={long} date_e={date_e} date_w={date_w} time={time} dt_type={dt_type} whowwemet={whowwemet} stylesep={stylesep} wproposal={wproposal} schedules={schedules} locurl={locurl} qnas={qnas} styleformation={styleformation} styleformationmobile={styleformationmobile} styleorientationmobile={styleorientationmobile} styletype={styletype} rsvpactive={rsvpactive} subtext={subtext} socialmedias={socialmedias} promote={promote} mapmode={mapmode} locdescript={locdescript} menutype={menutype} menuseq={menuseq} menustyle={menustyle} svgoverlay_map={svgoverlay_map} imgcss_map={imgcss_map} menuicongate={menuicongate} party={party} partyproperties={partyproperties}/>,
                    2: <T2simmobile />,
                    3: <T3simmobile />
                }[templatetype]
        }[simulatordevice]}
    </div>
}

export default Simulator