import React, { useEffect, useState } from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions';
import { DRAG_ICON, THINTELEGRAM_ICON, THINWHATSAPP_ICON, THINWECHAT_ICON, THININSTAGRAM_ICON, RSVPTELEPHONE_ICON, OPENEYE_ICON, CLOSEEYE_ICON, } from '../../../../../../config/svgicons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Modcfm, Portalmodal } from '../../../../../../components';
import Explain from "../Explain";

const dragsvgcss = { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5 }
const openeyesvgcss = { width: 25, height: 25, display: 'flex', placeContent: 'center', margin: 5 }
const cfmopeneyesvgcss = { width: 25, height: 25, display: 'flex', placeContent: 'center', margin: 5, color: '#aaaaaa' }
const addsvgcss = { width: 23, height: 23, display: 'flex', placeContent: 'center', }

const TITLE = 'Sequence'
const TEXT = `Drag to reorder pages.`

const DEFAULT_PAGE = 0
const SWAP_OR_EDIT_PAGE = 1

const iconcss = { width: 25, height: 25, margin: '0px 5px 0px 0px', userSelect: 'none', pointerEvents: 'none', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'white',
    padding: isDraggingOver ? 5 : 0,

});

function Sequence(props) {
    const { device, partialwt, showsave, setShowsave, revert, updateBackup, clearBackup, updateDisplayseq, updateDisplayonoffmap, updateDisplaytxtmap, save, displayseq, displayonoffmap, displaytxtmap } = props

    const [firsttime, setFirsttime] = useState(true)

    useEffect(() => {
        if (firsttime) {
            setFirsttime(false)
            setShowsave(false)
        }
    }, [])

    const onDragend = (result) => {
        const { source, destination } = result
        if (!destination) { return }
        else {
            if(source.index === destination.index) return 
            if (!showsave) { updateBackup(); setShowsave(true); }
            let curr_displayseq = props.displayseq.slice()
            const [removed] = curr_displayseq.splice(source.index, 1);
            curr_displayseq.splice(destination.index, 0, removed);
            updateDisplayseq(curr_displayseq)
        }
    }

    const btmbarstyle = {
        'MOBILE': { position: 'fixed', bottom: 0, left: 0, width: '100%' },
        'TABLET': { position: 'fixed', bottom: 10, left: 0, width: partialwt - 1 },
        'WEB': { position: 'fixed', bottom: 0, left: 0, width: partialwt - 1 }
    }[device]

    const btmbar = <div
        className='iedit_swaproot'
        style={btmbarstyle}
    >
        <button
            className='iedit_cancel'
            disabled={!showsave}
            style={{ opacity: !showsave ? 0.5 : 1 }}
            onClick={revert}>
            Revert
        </button>
        <button
            className='iedit_save'
            style={{ opacity: !showsave ? 0.5 : 1 }}
            disabled={!showsave}
            onClick={() => {
                save().then((message) => {
                    if (message === 'SUCCESS') {
                        clearBackup();
                        setShowsave(false)
                    }
                    else {
                        setShowsave(true)
                    }
                })
            }}
        >
            Save
        </button>
    </div>

    const SEQNAME_NAME_MAP = {
        'maintext': 'Main Text ( Description ) ',
        'location': 'Location',
        'date': 'Date',
        'howwemet': 'How We Met',
        'proposal': 'Proposal',
        'schedule': 'Schedule',
        'qna': 'Questions & Answers ( Q&A )',
        'map': 'Map',
        'party':'Party',
        'rsvp': 'RSVP'
    }

    const UNCHANGABLE_NAME_MAP = ['maintext', 'location', 'date', 'rsvp']

    const dnd = <div>
        <DragDropContext onDragEnd={onDragend}>
            <Droppable droppableId={'droppable'} >
                {(provided, snapshot) => (
                    <div className='Detailsdrag_root' ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        {displayseq.map((seqname, x) => {
                            const i = x
                            return <Draggable key={x + '_'} draggableId={x + 'id'} index={i}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <div style={{ display: 'flex', flexDirection: 'row', height: 90, border: '1px solid #cccccc', backgroundColor: '#ffffff', marginBottom: 15, borderRadius: 10 }} key={i + 'items'} >
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', borderRadius: '10px 0px 0px 10px', overflow: 'hidden' }}>
                                                <div className='Detailsdrag_col' style={{ height: 45, padding: 5, textAlign: 'start', placeContent: 'center', backgroundColor: '#efeff0' }}>{SEQNAME_NAME_MAP[seqname]}</div>
                                                {!UNCHANGABLE_NAME_MAP.includes(seqname)
                                                    ? <input
                                                        style={{ display: 'flex', alignItems: 'center', height: 45, padding: 5, margin: 0, outline: 'none', border: 'none', borderTop: '1px solid #cccccc', }}
                                                        onChange={(e) => {
                                                            if (!showsave) { updateBackup(); setShowsave(true); }
                                                            updateDisplaytxtmap(seqname, e.target.value)
                                                        }}
                                                        value={props.displaytxtmap[seqname]}
                                                    />
                                                    : <div style={{ display: 'flex', alignItems: 'center', height: 45, padding: 5, margin: 0, backgroundColor: '#efeff0', outline: 'none', border: 'none', borderTop: '1px solid #cccccc', }} >
                                                        -
                                                    </div>}
                                            </div>
                                            <button
                                                onClick={(e) => {
                                                    if (!UNCHANGABLE_NAME_MAP.includes(seqname)) {
                                                        if (!showsave) { updateBackup(); setShowsave(true); }
                                                        updateDisplayonoffmap(seqname)
                                                    }
                                                }}
                                                style={{ border: 'none', borderLeft: '1px solid #cccccc', backgroundColor: UNCHANGABLE_NAME_MAP.includes(seqname) ? '#efeff0' : '#ffffff', padding: 0 }}
                                            >
                                                {UNCHANGABLE_NAME_MAP.includes(seqname)
                                                    ? iconwrapper(OPENEYE_ICON, cfmopeneyesvgcss)
                                                    : (displayonoffmap && displayonoffmap[seqname])
                                                        ? iconwrapper(OPENEYE_ICON, openeyesvgcss)
                                                        : iconwrapper(CLOSEEYE_ICON, openeyesvgcss)}
                                            </button>
                                            <span className='Detailsdrag_dragicon' style={{ border: 'none', borderLeft: '1px solid #cccccc', }} {...provided.dragHandleProps}>
                                                {iconwrapper(DRAG_ICON, dragsvgcss)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        {btmbar}
    </div>

    return (
        <div className='Ieditcomp_div'
            style={{
                height: {
                    'WEB': 'calc(100% - 70px - 65px)',
                    'TABLET': 'calc(100% - 70px - 65px)',
                    'MOBILE': 'calc(100% - 70px)'
                }[device],
                marginBottom: {
                    'MOBILE': 65
                }[device]
            }}>
            <Explain title={TITLE} txt={TEXT} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {dnd}
            </div>

        </div>
    )
}

export default Sequence