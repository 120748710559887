// import React, { useState, useEffect } from 'react'
// import { Checkdevice } from '../../../components'
// import { EMPTYSPACE, SEARCH_LARGE_ICON, hideseatcorner, CLOSE_ICON } from '../../../config/constants'
// import { str_similarity } from '../../../config/commonfunctions';
// import { u1F4BA } from 'react-icons-kit/noto_emoji_regular/u1F4BA'
// import Icon from 'react-icons-kit'
// import './Slookcontent.css'

// function Searchitem(props) {
//     const [text, setText] = useState('')
//     const [allnames, setAllnames] = useState([])
//     const [alltables, setAlltables] = useState([])
//     const [similaroutput, setSimilaroutput] = useState([])

//     useEffect(() => {
//         const { tobjs } = props
//         var allnames = []
//         var alltables = []
//         for (let i = 0; i < tobjs.length; i++) {
//             const { seatnames, name } = tobjs[i]
//             const unique_table_name = name
//             for (let i = 0; i < seatnames.length; i++) {
//                 const s_name = seatnames[i].toLowerCase()
//                 allnames.push(s_name)
//                 alltables.push(unique_table_name)
//             }
//         }
//         setAllnames(allnames)
//         setAlltables(alltables)
//     }, [props])

//     const txtchange = (e) => {
//         setText(e.target.value)
//         var similaroutput = []
//         for (let i = 0; i < allnames.length; i++) {
//             if (str_similarity(e.target.value, allnames[i]) > 0.5) {
//                 similaroutput.push(allnames[i])
//             }
//         }
//         setSimilaroutput(similaroutput)
//     }

//     return props.device === 'MOBILE'
//         ? <div className="Slookctablepop_rootm" >
//             <div className="Slooktablepopupm">
//                 <div className="Ilookup_closeroot">
//                     <button className={'Ilookup_close'} onClick={props.close} >{CLOSE_ICON}</button>
//                 </div>
//                 <div className='RSVP_subroot' style={{ height: '100vh', padding: '0px 10px' }}>
//                     <div className="RSVP_largetitle" style={{ fontFamily: 'Lato', fontSize: 24 }}>Search</div>
//                     <input className="Slooktableinput" onChange={txtchange} placeholder="Your name" value={text} />
//                     {similaroutput.map((s, ind) => {
//                         return <div key={ind + Math.random()} style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
//                             <div style={{ width: '50%', overflowX: 'scroll' }}>{s}</div>
//                             <div style={{ width: '50%', overflowX: 'scroll' }}>{alltables[ind]}</div>
//                         </div>
//                     })}
//                 </div>
//             </div>

//         </div>
//         : <div className="Slookctablepop_root" >
//             <div className="Ilookup_closeroot">
//                 <button className={'Ilookup_close'}  onClick={props.close} >{CLOSE_ICON}</button>
//             </div>
//             <div className="Slookctablepopbg" />
//             <div className="Slooktablepopup" >
//                 <input className="Slooktableinput" onChange={txtchange} placeholder="Your name" value={text} />
//                 {similaroutput.map((s, ind) => {
//                     return <div key={ind + Math.random()} style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
//                         <div style={{ width: '50%', overflowX: 'scroll' }}>{s}</div>
//                         <div style={{ width: '50%', overflowX: 'scroll' }}>{alltables[ind]}</div>
//                     </div>
//                 })}
//             </div>
//         </div>
// }


// function Slookcontent(props) {
//     const device = Checkdevice()
//     const [eventname, setEventname] = useState('') // eslint-disable-line
//     const [tobjs, setTobjs] = useState([]) // table objects
//     const [maxht, setMaxht] = useState(0)
//     const [searchgate, setSearchgate] = useState(false)
//     const [imgs, setImgs] = useState([])
//     const [style, setStyle] = useState({})

//     useEffect(() => {
//         const { tableobjs, name, imgs, style } = props.item
//         setEventname(name)
//         setTobjs(tableobjs)
//         setImgs(imgs)
//         setStyle(style)
//         // calculate the largest number of person in the table and determine a single table height
//         var max_index = 0
//         for (let i = 0; i < tableobjs.length; i++) {
//             const { seatnames } = tableobjs[i]
//             if (seatnames.length > max_index) max_index = seatnames.length
//         }
//         setMaxht(max_index * 43)
//     }, [props])

//     const searchtoggle = () => setSearchgate(true)

//     const m_nameandbg = <div style={{ display: 'flex', flexDirection: 'column', placeContent: 'center', position: 'relative', overflow: 'hidden' }}>
//         <div style={{ display: 'flex', margin: 10, borderRadius: 10, alignItems: 'center', placeContent: 'center', flexDirection: 'column', border: 'thin solid #707070', position: 'relative', width: 'calc( 100% - 20px )', height: 'calc( 100vh - 20px )', }}>
//             <div style={{ boxShadow: '3px 3px 3px -1px rgb(0 0 0 / 25%)', filter: 'drop-shadow(2px 4px 6px pink)', borderRadius: 45, height: 45, width: 45, display: 'flex', placeContent: 'center', alignItems: 'center', background: 'drop-shadow(2px 4px 6px pink)', backgroundColor: '#fef5f6', border: 'thin solid #c0c0c03d', marginBottom: 10 }}>
//                 <Icon size={28} icon={u1F4BA} />
//             </div>
//             <div
//                 className={`SlooktabletopnameMOBILE`}
//                 style={{
//                     textAlign: 'center',
//                     fontFamily: style && style['fontFamily'] ? style['fontFamily'] : '',
//                     fontSize: 'x-large',
//                 }}>{`${eventname}`}</div>
//         </div>
//         <div style={{
//             backgroundImage: "url(" + imgs[1] + ")",
//             backgroundSize: 'contain',
//             backgroundRepeat: 'no-repeat',
//             width: '100%',
//             height: '100%',
//             transform: 'scaleY(-1) scaleX(-1)',
//             placeSelf: 'flex-end',
//             position: 'absolute',
//             left: '40%',
//             top: '-70%'
//         }} />
//         <div style={{
//             backgroundImage: "url(" + imgs[1] + ")",
//             backgroundSize: 'contain',
//             backgroundRepeat: 'no-repeat',
//             width: '100%',
//             height: '100%',
//             transform: "scaleY(1) scaleX(1)",
//             position: 'absolute',
//             bottom: '-70%',
//             right: '40%',
//         }} />
//     </div>

//     const t_nameandbg = <div className="Slookctablenandbg_root">
//         <div className="SlooktableimgTABLET" style={{ backgroundImage: "url('" + imgs[0] + "')" }} />
//         <div className={`SlooktabletopnameTABLET`} style={{ fontFamily: style && style['fontFamily'] ? style['fontFamily'] : '' }}>{`${eventname}`}</div>
//     </div>

//     const w_nameandbg = <div className="Slookctablenandbg_root">
//         <div className="SlooktableimgWEB" style={{ backgroundImage: "url('" + imgs[0] + "')" }} />
//         <div className={`SlooktabletopnameWEB`} style={{ fontFamily: style && style['fontFamily'] ? style['fontFamily'] : '' }}>{`${eventname}`}</div>
//     </div>
//     return searchgate
//         ? <Searchitem tobjs={tobjs} close={() => setSearchgate(false)} device={device} />
//         : <div className={`Slookc${device}_root`}>

//             {device !== 'MOBILE'
//                 ? <div className='Slooktablecorners'>
//                     <div className="Slooktablecornertopleft" style={{}} />
//                     <div className="Slooktablecornertopright" style={{}} />
//                 </div>
//                 : <div className='Slooktablecorners'>
//                     <div className="Slooktablecornertopleft_mobile" style={{}} />
//                     <div className="Slooktablecornertopright_mobile" style={{}} />
//                 </div>}

//             {hideseatcorner
//                 ? null
//                 : device !== 'MOBILE'
//                     ? <div className='Slooktablecorners'>
//                         <div className="Slooktablecornertopleft" style={{ backgroundImage: 'url(' + imgs[2] + ')' }} />
//                         <div className="Slooktablecornertopright" style={{ backgroundImage: 'url(' + imgs[2] + ')' }} />
//                     </div>
//                     : <div className='Slooktablecorners'>
//                         <div className="Slooktablecornertopleft_mobile" style={{ backgroundImage: 'url(' + imgs[2] + ')' }} />
//                         <div className="Slooktablecornertopright_mobile" style={{ backgroundImage: 'url(' + imgs[2] + ')' }} />
//                     </div>
//             }

//             <div style={{ display: 'flex', flexDirection: 'column', placeContent: 'center' }}>
//                 {device === 'MOBILE'
//                     ? m_nameandbg
//                     : device === 'TABLET'
//                         ? t_nameandbg
//                         : w_nameandbg}

//                 {hideseatcorner
//                     ? null
//                     : device !== 'MOBILE'
//                         ? null
//                         : <div className='Slooktablecorners'>
//                             <div className="Slooktablecornerbtmleft_mobile" style={{ backgroundImage: 'url(' + imgs[2] + ')' }} />
//                             <div className="Slooktablecornerbtmright_mobile" style={{ backgroundImage: 'url(' + imgs[2] + ')' }} />
//                         </div>}
//             </div>

//             <div className={`Slooktableassemble${device}`} id="capture">
//                 {tobjs.map(({ cats, seatnames, max, name, relas }, i) => <div className={"Slookctable_root" + device} key={'t' + i} style={{ height: maxht }}>
//                     <div className={`Slooktablename${device}`} style={{ fontFamily: style ? style.fontFamily : '', fontWeight: 'bold' }}>{name}</div>
//                     {seatnames.map((paxname, j) => <div className={`Slookseat${device}`} key={'pax' + j}>{paxname}</div>)}
//                 </div>)}
//             </div>
//             {device === 'WEB'
//                 ? <div className={"SlooktablesearchWEB"} onClick={searchtoggle}>
//                     <button className="Slooktabsearchicon" > {SEARCH_LARGE_ICON} </button>
//                     <div>Search</div>
//                 </div>
//                 : <button className={"Slooktablesearch" + device} onClick={searchtoggle}> {SEARCH_LARGE_ICON} </button>
//             }

//             {EMPTYSPACE}

//             {device !== 'MOBILE'
//                 ? <div className='Slooktablecorners'>
//                     <div className="Slooktablecornerbtmleft" />
//                     <div className="Slooktablecornerbtmright" />
//                 </div>
//                 : null}

//         </div>
// }

// export default Slookcontent

import React, { useState, useEffect } from 'react'
import { Textfit } from 'react-textfit';
import { Checkdevice, Portalmodal, ScreenWidth } from '../../../components';
import { CLOSE_ICON } from '../../../config/constants';
import { str_similarity } from '../../../config/commonfunctions';
import './Slookcontent.css'

function Slookcontent(props) {

    // const [onlineselectedpg, setOnlineselectedpg] = useState(-1) // page design that online seatchart inherit
    const [onlineselectedpgprops, setOnlineselectedpgprops] = useState({}) // page design that online seatchart inherit
    const [wt, setWt] = useState(0)
    const [maintxt, setMaintxt] = useState('') // string
    const [maintxtarr, setMaintxtarr] = useState('') // array 
    const [toptxt, setToptxt] = useState('')
    const [onlinemaintxtliner, setOnlinemaintxtliner] = useState(1) // 0 - oneliner 1 - twoliner
    const [layer1ready, setLayer1ready] = useState(false)
    const [ready, setReady] = useState(false)
    const [txtfit, setTxtfit] = useState(false)
    const [txtfit1, setTxtfit1] = useState(false)
    const [tableobjs, setTableobjs] = useState([]) // array of table and seat related data
    const [maincss, setMaincss] = useState({})
    const [topcss, setTopcss] = useState({})
    const [tabletitlecss, setTabletitlecss] = useState({})
    const [seatscontainercss, setSeatcontainercss] = useState({})
    const [templatecolor, setTemplatecolor] = useState('')

    // for search
    const [text, setText] = useState('')
    const [allnames, setAllnames] = useState([])
    const [alltables, setAlltables] = useState([])
    const [similaroutput, setSimilaroutput] = useState([])
    const [searchgate, setSearchgate] = useState(false)

    const device = Checkdevice()
    const screenwt = ScreenWidth()

    useEffect(() => {
        setTimeout(() => {
            setWt(screenwt)
            setReady(true)
        }, 500)
        // setTimeout(() => {
        //     setTxtfit(true)
        // }, 700)
    }, [])

    useEffect(() => {
        try {
            console.log('props.item', props.item)
            const { onlineselectedpgprops, tableobjs } = props.item
            const {
                defaultmain,
                defaulttop,
                topcss,
                maincss,
                tabletitlecss,
                seatscontainercss,
                templatecolor,
                onlinemaintxtliner
            } = onlineselectedpgprops
            setOnlineselectedpgprops(onlineselectedpgprops)
            setOnlinemaintxtliner(onlinemaintxtliner)
            setMaincss(maincss)
            setTopcss(topcss)
            setTabletitlecss(tabletitlecss)
            setSeatcontainercss(seatscontainercss)
            setTableobjs(tableobjs)
            setToptxt(defaulttop)
            setTemplatecolor(templatecolor)

            const regex = /&amp;/i // replace all '&amp;' to '&'
            var maintxt = defaultmain.replace(regex, '&')
            setMaintxt(defaultmain.replace(regex, '&'))

            maintxt = maintxt.split('&')
            setMaintxtarr(maintxt)

            var allnames = []
            var alltables = []
            for (let i = 0; i < tableobjs.length; i++) {
                const { seatnames, name } = tableobjs[i]
                const unique_table_name = name
                for (let i = 0; i < seatnames.length; i++) {
                    const s_name = seatnames[i].toLowerCase()
                    allnames.push(s_name)
                    alltables.push(unique_table_name)
                }
            }
            setAllnames(allnames)
            setAlltables(alltables)

        }
        catch (e) { }

    }, [props])

    useEffect(() => {
        if (templatecolor) {
            document.body.style = `background: ${templatecolor};`;
        }
        return () => {
            document.body.style = `background: "";`;
        }
    }, [templatecolor])

    const oneliner_webdom = <Textfit style={{ width: '60vw', fontFamily: maincss.fontFamily, fontWeight: maincss.fontWeight, color: maincss.color, }} mode="single" >
        {maintxt}
    </Textfit>

    const twoliner_webdom = <div style={{ display: 'flex', flexDirection: 'column', fontWeight: maincss.fontWeight, }}>
        <Textfit style={{ width: '55vw', fontFamily: maincss.fontFamily, }} mode="single" onReady={(e) => {
            setLayer1ready(e)
        }} >
            {`${maintxtarr[0]}`}
        </Textfit>
        {layer1ready
            ? <div style={{ fontSize: layer1ready, width: '55vw', fontFamily: maincss.fontFamily, }}>
                {`& ${maintxtarr[1]}`}
            </div>
            : null}
    </div>

    const oneliner_mobiledom = <Textfit style={{ width: '85vw', fontFamily: maincss.fontFamily, color: maincss.color, }} mode="single" >
        {maintxt}
    </Textfit>

    const twoliner_mobiledom = <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Textfit style={{ width: '80vw', fontFamily: maincss.fontFamily, }} mode="single" onReady={(e) => {
            setLayer1ready(e)
        }} >
            {`${maintxtarr[0]} &`}
        </Textfit>
        {layer1ready
            ? <div style={{ fontSize: layer1ready, width: '80vw', fontFamily: maincss.fontFamily, }}>
                {`${maintxtarr[1]}`}
            </div>
            : null}
    </div>

    const banner_dom = {
        'WEB': <img style={{ width: '25vw' }} src={onlineselectedpgprops.banner} className="sclookcontent_banner" alt="" />,
        'TABLET': <img style={{ width: '45vw' }} src={onlineselectedpgprops.banner} className="sclookcontent_banner" alt="" />,
        'MOBILE': <img style={{ width: '85vw' }} src={onlineselectedpgprops.banner} className="sclookcontent_banner" alt="" />
    }[device]

    const tablechart_dom = <div className='sclookcontent_colrow' style={{ backgroundColor: 'inherit' }}>
        {tableobjs.map((table, tableindex) => {
            const { name, seatnames } = table
            return <div className="sclookcontent_tableandseat" key={"table" + tableindex} >
                <div className='sclookcontent_table' style={{ fontFamily: tabletitlecss.fontFamily, color: tabletitlecss.color, fontWeight: tabletitlecss.fontWeight }} >
                    {name.length > 0 ? name : '-'}
                </div>
                <div className='sclookcontent_seat' style={{ fontFamily: seatscontainercss.fontFamily, color: seatscontainercss.color, fontWeight: seatscontainercss.fontWeight }} >
                    {seatnames.map((sn, seatindex) => {
                        return <div key={tableindex + 'sn' + seatindex}> {sn} </div>
                    })}
                </div>
            </div>
        })}
    </div>

    const btmbarwt = {
        'MOBILE': '100%',
        'TABLET': 650,
        'WEB': 750
    }
    const vb_ = "0 0 1024 1024"
    const v_ = "1.1"
    const x_ = "http://www.w3.org/2000/svg"


    const devicedim = { 'WEB': 27, 'TABLET': 26, 'MOBILE': 26 }[device]
    const iconcss = { width: devicedim, height: devicedim, margin: 4, verticalAlign: 'middle', fill: tabletitlecss.color, overflow: 'hidden', display:'flex', placeSelf:'center' }

    const btmbar_dom = <div
        className='sclookcontent_btmbar'
        style={{ width: btmbarwt[device], backgroundColor: templatecolor, color: tabletitlecss.color, border: `1px solid ${tabletitlecss.color}`, borderBottom: 'none', fontFamily: 'Lato' }}
    >
        <div
            className='ilookup_btmbaritem'
            style={{display:'flex', placeContent:'center', flexDirection:'column'}}
            onClick={() => {
                document.body.style.overflow = 'hidden'
                setSearchgate(true)
            }}
        >
            {/* <svg className="icon" style={{ width: 23, height: 23, margin: 4, verticalAlign: 'middle', fill: tabletitlecss.color, overflow: 'hidden' }} viewBox={vb_} version={v_} xmlns={x_}> <path d="M351.2 699.2c-88.8 0-177.6-33.6-245.6-101.6-135.2-135.2-135.2-356 0-491.2s356-135.2 491.2 0 135.2 356 0 491.2c-67.2 67.2-156 101.6-245.6 101.6z m0-663.2C270.4 36 189.6 67.2 128 128 5.6 251.2 5.6 451.2 128 574.4c123.2 123.2 323.2 123.2 446.4 0 123.2-123.2 123.2-323.2 0-446.4C512.8 67.2 432 36 351.2 36zM907.2 1020h-4.8c-31.2-1.6-60.8-16-80.8-39.2L527.2 634.4c-3.2-3.2-4-8-4-12.8 0.8-4.8 3.2-8.8 6.4-11.2 16-11.2 30.4-23.2 44-36.8s25.6-28 36.8-44c2.4-4 6.4-6.4 11.2-6.4 4.8-0.8 8.8 0.8 12.8 4l345.6 294.4c24 20 38.4 49.6 39.2 80.8 1.6 31.2-10.4 61.6-32.8 84-20.8 21.6-49.6 33.6-79.2 33.6z m-344-392.8l283.2 332.8c14.4 17.6 35.2 27.2 58.4 28 22.4 0.8 44-7.2 60-23.2s24.8-37.6 23.2-60c-0.8-22.4-11.2-43.2-28-58.4L627.2 563.2c-9.6 12-19.2 24-30.4 34.4s-21.6 20-33.6 29.6zM351.2 590.4c-60.8 0-122.4-23.2-168.8-69.6-92.8-92.8-92.8-244.8 0-337.6 92.8-92.8 244.8-92.8 337.6 0C613.6 276 613.6 427.2 520 520c-46.4 47.2-107.2 70.4-168.8 70.4z m0-461.6c-56.8 0-114.4 21.6-157.6 64.8-87.2 87.2-87.2 228 0 315.2 87.2 87.2 228 87.2 315.2 0 87.2-87.2 87.2-228 0-315.2-43.2-43.2-100-64.8-157.6-64.8zM351.2 472c-1.6 0-4-0.8-5.6-2.4-12.8-12-122.4-116.8-122.4-164 0-40.8 33.6-74.4 74.4-74.4 20.8 0 40 8.8 54.4 23.2 13.6-15.2 33.6-23.2 54.4-23.2 40.8 0 74.4 33.6 74.4 74.4 0 46.4-110.4 152-122.4 164-3.2 1.6-4.8 2.4-7.2 2.4z m-53.6-224.8c-32 0-58.4 26.4-58.4 58.4s76.8 112.8 112 147.2c36-34.4 112-115.2 112-147.2 0-32-26.4-58.4-58.4-58.4-19.2 0-36.8 9.6-47.2 24.8-3.2 4-10.4 4-12.8 0-10.4-15.2-28.8-24.8-47.2-24.8z" p-id="4256"></path></svg> */}
            {/* <svg className="icon" style={{ width: 23, height: 23, margin: 4, verticalAlign: 'middle', fill: tabletitlecss.color, overflow: 'hidden' }} viewBox={vb_} version={v_} xmlns={x_}><path d="M416 192C537.6 192 640 294.4 640 416S537.6 640 416 640 192 537.6 192 416 294.4 192 416 192M416 128C256 128 128 256 128 416S256 704 416 704 704 576 704 416 576 128 416 128L416 128zM832 864c-6.4 0-19.2 0-25.6-6.4l-192-192c-12.8-12.8-12.8-32 0-44.8s32-12.8 44.8 0l192 192c12.8 12.8 12.8 32 0 44.8C851.2 864 838.4 864 832 864z" p-id="4662"></path></svg> */}
            {/* <svg className="icon" style={iconcss}  viewBox={vb_} version={v_} xmlns={x_}><path d="M416 192C537.6 192 640 294.4 640 416S537.6 640 416 640 192 537.6 192 416 294.4 192 416 192M416 128C256 128 128 256 128 416S256 704 416 704 704 576 704 416 576 128 416 128L416 128zM832 864c-6.4 0-19.2 0-25.6-6.4l-192-192c-12.8-12.8-12.8-32 0-44.8s32-12.8 44.8 0l192 192c12.8 12.8 12.8 32 0 44.8C851.2 864 838.4 864 832 864z" p-id="4662"></path></svg>  */}
            {/* <svg className="icon" style={iconcss} viewBox={vb_} version={v_} xmlns={x_}><path d="M432 704a272 272 0 1 0 0-544 272 272 0 0 0 0 544z m0 32a304 304 0 1 1 0-608 304 304 0 0 1 0 608zM432 768C617.568 768 768 617.568 768 432S617.568 96 432 96 96 246.432 96 432 246.432 768 432 768z m0 32C228.768 800 64 635.232 64 432S228.768 64 432 64 800 228.768 800 432 635.232 800 432 800zM567.776 429.248c24.96-24.896 24.96-65.568 0-90.496a64.064 64.064 0 0 0-90.496 0L432.032 384l-45.248-45.248a64.128 64.128 0 0 0-90.528 0c-24.96 24.96-24.96 65.536 0 90.496l135.776 135.776 135.744-135.776z m22.656-113.12a96.128 96.128 0 0 1 0 135.776l-158.4 158.4-158.4-158.4a96.064 96.064 0 0 1 0-135.776 96.128 96.128 0 0 1 135.776 0l22.624 22.624 22.624-22.624a96.064 96.064 0 0 1 135.776 0zM712.896 646.08l11.904-11.872a16 16 0 0 1 22.624 0l220.896 220.896a79.744 79.744 0 0 1-0.128 113.024 79.936 79.936 0 0 1-112.992 0.128l-220.928-220.928a16 16 0 0 1 0-22.624l11.584-11.552 22.368 22.848 209.6 209.6a47.936 47.936 0 0 0 67.744-0.128c18.88-18.848 18.88-48.992 0.128-67.744l-209.6-209.6-23.2-22.048z" ></path></svg> */}
            <svg className="icon" style={iconcss} viewBox={vb_} version={v_} xmlns={x_}><path d="M785.664823 352.59243c-35.701107-102.302991-119.413694-180.216501-223.932142-208.419843-11.665691-3.115967-23.76731 3.770883-26.936489 15.490809-3.158946 11.714809 3.776 23.772426 15.490809 26.936489 90.49199 24.416086 162.977885 91.88164 193.895036 180.474375 3.158946 9.05933 11.655458 14.734586 20.741393 14.734586 2.397606 0 4.843308-0.397043 7.240915-1.234108 11.45182-3.996011 17.502629-16.525372 13.500478-27.982308zM1009.833349 959.539896L791.889595 741.596142c66.61621-74.767867 107.202581-173.206854 107.202581-280.988626 0-233.200226-189.721992-422.922219-422.922219-422.922219s-422.922219 189.721992-422.922219 422.922219 189.721992 422.922219 422.922219 422.922219c109.644189 0 209.662137-41.954531 284.883329-110.63475L978.765773 990.606449a21.890566 21.890566 0 0 0 15.533788 6.436596 21.89159 21.89159 0 0 0 15.533788-6.436596c8.581446-8.576329 8.581446-22.490224 0-31.066553zM476.17098 839.58999c-208.972429 0-378.981451-170.010045-378.981451-378.981451s170.010045-378.981451 378.981451-378.98145 378.981451 170.010045 378.981451 378.98145-170.009022 378.981451-378.981451 378.981451z" p-id="24218"></path></svg>
            <div className='ilookup_btmtxt' >Search</div>
        </div>
    </div>

    const searchinputchange = (e) => {
        setText(e.target.value)
        var similaroutput = []
        for (let i = 0; i < allnames.length; i++) {
            if (str_similarity(e.target.value, allnames[i]) > 0.5) {
                similaroutput.push({ name: allnames[i], table: alltables[i] })
            }
        }
        setSimilaroutput(similaroutput)
    }

    const search_modal_old = searchgate
        ? <div className='sclookcontent_searchmodal'>
            <div className="Ilookup_closeroot">
                <button className="Ilookup_close" style={{ backgroundColor: seatscontainercss.color, color: templatecolor, fill: templatecolor }} onClick={() => setSearchgate(false)}>
                    {CLOSE_ICON}
                </button>
            </div>
            <div className='sclookcontent_searchmodalcenter' style={{ backgroundColor: templatecolor, color: seatscontainercss.color }}>
                <input className="sclookcontent_searchinput" style={{ backgroundColor: templatecolor, color: seatscontainercss.color }} onChange={searchinputchange} placeholder="Search Your Name..." value={text} />
                <div className="sclookcontent_searchresultcol">
                    {similaroutput.map((s, ind) => {
                        const { name, table } = s
                        return <div className="sclookcontent_searchresult" key={ind + Math.random()}>
                            <div className='sclookcontent_searchresultcontent'>{name}</div>
                            <div className='sclookcontent_searchresultcontent'>{table}</div>
                        </div>
                    })}
                </div>
                <button className='sclookcontent_modalsearchbtn' style={{ color: seatscontainercss.color }}>Search</button>
            </div>
        </div>
        : null

    const portalmidcss = { width: { 'WEB': 500, 'TABLET': 450, 'MOBILE': '100%' }[device], height: { 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[device], backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }

    const search_modal = <Portalmodal
        portaltype={'commonportal'}
        open={searchgate}
        onClose={() => { 
            document.body.style.overflow = ''
            setSearchgate(false)
         }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ ...portalmidcss, backgroundColor: templatecolor, color: seatscontainercss.color }}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'}>
            <div className="Rsvpexplorer_portaltitle" style={{ position: 'absolute', top: 0, left: 0 }}>Search</div>
            <input className="sclookcontent_searchinput" style={{ backgroundColor: templatecolor, color: seatscontainercss.color }} onChange={searchinputchange} placeholder="Search Your Name..." value={text} />
            <div className="sclookcontent_searchresultcol">
                {similaroutput.map((s, ind) => {
                    const { name, table } = s
                    return <div className="sclookcontent_searchresult" key={ind + Math.random()}>
                        <div className='sclookcontent_searchresultcontent'>{name}</div>
                        <div className='sclookcontent_searchresultcontent'>{table}</div>
                    </div>
                })}
            </div>
            <button className='sclookcontent_modalsearchbtn' style={{ color: seatscontainercss.color }}>Search</button>
        </div>
    </Portalmodal>


    if (screenwt < 250 && ready) {
        return <div>Show Search Only</div>
    }
    else if (wt > 0) {

        const prerender_dom = <div id='sclookcontent_toptexts' key={wt + 'txtfit1' + ready + '10'} style={{ position: 'absolute', visibility: 'hidden' }}>
            <Textfit onReady={() => setTxtfit(true)} style={{ width: { 'WEB': '30vw', 'TABLET': '30vw', 'MOBILE': '50vw' }[device], fontFamily: topcss.fontFamily, fontWeight: topcss.fontWeight, color: topcss.color, display: 'flex', placeContent: 'center', alignItems: 'center' }} mode="single" >
                {toptxt}
            </Textfit>
            <Textfit onReady={() => setTxtfit1(true)} style={{ width: '60vw', fontFamily: maincss.fontFamily, fontWeight: maincss.fontWeight, color: maincss.color, }} mode="single" >
                {maintxt}
            </Textfit>
        </div>

        return <div id="sclookcontent_root" style={{ backgroundColor: templatecolor }}>
            <div id="sclookcontent_topportion" className='sclookcontent_topht'>
                {onlineselectedpgprops.cornerimg
                    ? <img
                        id="sclookcontent_leftcorner"
                        className="sclookcontent_corner sclookcontent_leftpartial"
                        src={onlineselectedpgprops.cornerimg}
                        alt=""
                    />
                    : <div id="sclookcontent_rightcorner" className="sclookcontent_corner sclookcontent_rightpartial" />}
                {prerender_dom}
                {txtfit && txtfit1
                    ? device === 'MOBILE'
                        ? <div id='sclookcontent_toptexts' key={wt + 'txtfit2' + ready + 'MOBILE'}>
                            <Textfit style={{ fontFamily: topcss.fontFamily, fontWeight: topcss.fontWeight, color: topcss.color, width: '50vw', height: '20vw', display: 'flex', placeContent: 'center', alignItems: 'center' }} mode="single" >
                                {toptxt}
                            </Textfit>
                            {{ 0: oneliner_mobiledom, 1: twoliner_mobiledom }[onlinemaintxtliner]}
                        </div>
                        : <div id='sclookcontent_toptexts' key={wt + 'txtfit3' + ready + '10'}>
                            <Textfit style={{ fontFamily: topcss.fontFamily, fontWeight: topcss.fontWeight, color: topcss.color, width: '30vw', display: 'flex', placeContent: 'center', alignItems: 'center', marginTop: onlinemaintxtliner === 0 ? 0 : 40 }} mode="single" >
                                {toptxt}
                            </Textfit>
                            {{ 0: oneliner_webdom, 1: twoliner_webdom }[onlinemaintxtliner]}
                        </div>
                    : null}
                {onlineselectedpgprops.cornerimg
                    ? <img
                        id="sclookcontent_rightcorner"
                        className="sclookcontent_corner sclookcontent_rightpartial"
                        src={onlineselectedpgprops.cornerimg} alt=""
                    />
                    : <div id="sclookcontent_rightcorner" className="sclookcontent_corner sclookcontent_rightpartial" />}
            </div>
            {banner_dom}
            {tablechart_dom}
            {btmbar_dom}
            {search_modal}
        </div>
    }
    else {
        return <div id="sclookcontent_root" style={{ backgroundColor: templatecolor }} />
    }

}
export default Slookcontent