import { production } from "./constants"
import { S3CLOUD_CLOUDFRONT } from "./s3directories"
var http = production ? 'https://' : 'http://'
// export const B_W = production ? http + 'api.weaverank.com:3000/api/' : http + 'localhost:3000/api/' 
export const B_W = production ? http + 'api.weaverank.com:3000/api/' : http + '192.168.1.75:3000/api/'

// All
export const ALL_VENDOR_READ_DETAIL_LINK = B_W + 'all/all_vendor_read_detail'
export const ALL_VENDOR_READ_PARTNER = B_W + 'all/all_vendor_read_partner'
export const ALL_VENDOR_SEND_MSG = B_W + 'all/all_sendvendor_msg'

// Project
export const PROJECT_PROJECT_READ_DETAIL_LINK = B_W + 'project/read_project_specificproject'

// Projectinterface
export const PROJECT_INTERFACE_INFINITESCROLL_DATA = B_W + 'projectinterface/read_projectinterface_infinitescrolldata'
export const PROJECTINTERFACE_READ = B_W + 'projectinterface/read_projectinterface'
export const PROJECT_READ_SPECIFICIDS = B_W + 'projectinterface/read_specific_projectids'

// Review
export const READ_REVIEW_OF_A_VENDOR = B_W + 'review/read_reviews'
export const READ_REVIEW_OF_A_VENDOR_BY_VENDOR = B_W + 'review/read_v_reviews'

// User
export const USER_LOGIN = B_W + 'user/user_login'
export const USER_LOGINFB = B_W + 'user/user_fblogin'
export const USER_CREATE = B_W + 'user/user_register'
export const USER_CHECKUSERNAME = B_W + 'user/user_checkusername'
export const USER_FORGTPW = B_W + 'user/user_forgtpw'
export const USER_FORGTPWRESETCHECK = B_W + 'user/user_forgtpwresetcheck'
export const USER_FORGTPWRESET = B_W + 'user/user_forgtpwreset'
export const USER_VALIDATE = B_W + 'user/user_validate'
export const USER_READ_PROFILE = B_W + 'user/user_read_profile'
export const USER_UPDATE_PERSONAL_PROFILEIMG = B_W + 'user/user_upload_profileimg'
export const USER_UPDATE_PERSONAL = B_W + 'user/user_update_personal'
export const USER_CHANGEPASSWORD_UPDATE = B_W + 'user/update_userchangepassword'
export const USER_SEND_VERIFIC = B_W + 'user/send_user_acct_verification'
export const USER_CHECK_VERIFIC = B_W + 'user/check_user_acct_verification'
export const USER_RESEND_VERIFIC = B_W + 'user/resend_user_acct_verification'
export const USER_RESEND_VERIFICLP = B_W + 'user/resend_user_acct_verificationlp'
export const USER_REVIEWING_VENDOR = B_W + 'user/send_user_review_of_vendor'
export const USER_UPDATE_SITE_WEDDING = B_W + 'user/user_update_site_wedding'
export const USER_UPDATE_SITE_EVENT = B_W + 'user/user_update_site_event'
export const USER_UPDATE_SITE_GUEST = B_W + 'user/user_update_site_guest'
export const READ_USERHOMECONTENT_BULKVT = B_W + 'user/read_userhomecontent_bulkvt'
export const READ_USERHOMECONTENT_SINGLEVT = B_W + 'user/read_userhomecontent_singlevt'
export const USER_FAV_A_VENDOR = B_W + 'user/user_fav_a_vendor'
export const USER_UNFAV_A_VENDOR = B_W + 'user/user_unfav_a_vendor'
export const USER_FAVOURITE_VENDOR = B_W + 'user/user_read_favouritevendor'
export const USER_FAVOURITE_PROJECT = B_W + 'user/user_read_favouriteproject'
export const USER_UPDATE_FAVVTGRADINGSCHEMA = B_W + 'user/user_update_favouritevtgradingschema'
export const USER_UPDATE_FAVVTISCORE = B_W + 'user/user_save_score'
export const USER_FAVOURITE_VENDOR_VT_BASED = B_W + 'user/user_read_favouritevendorvtbased'
export const USER_UPDATER_FILTER = B_W + 'user/update_userfilter'
export const USER_UNFAV_PROJ_MULTIPLE = B_W + 'user/remove_favoruiteproject_multiple'
export const USER_PROJECT_PROJECT_READ_DETAIL_LOGGED_LINK = B_W + 'user/read_project_specificproject_logged'
export const USER_READ_BOARD = B_W + 'user/read_board'
export const READ_U_ALL_BOARDS = B_W + 'user/read_user_all_board'
export const USER_READ_WEDANDEVENT_QN = B_W + 'user/read_user_qn'
export const USER_SUBMIT_ANS = B_W + 'user/update_user_qn'
export const USER_CHANGE_LOC = B_W + 'user/update_user_loc'
export const USER_PL_FIRST_READ_ALL = B_W + 'pricelist/readfirst0'
export const USER_PL_SECOND_READ_ALL = B_W + 'pricelist/readsecond0'
export const USER_PL_FIRST_READ_VTS = B_W + 'pricelist/readfirst1'
export const USER_PL_SECOND_READ_VTS = B_W + 'pricelist/readsecond1'
export const USER_PL_SAVESETTINGSW = B_W + 'pricelist/savesettingsw'
export const USER_PL_SAVESETTINGSE = B_W + 'pricelist/savesettingse'
export const USER_PL_APPLYFILTER = B_W + 'pricelist/applyfilter'

export const USER_READ_SINGLEPL = B_W + 'pricelist/readby2u'
export const USER_READ_SINGLEPLNONUSER = B_W + 'pricelist/readby2'
export const USER_GET_DASHBOARD = B_W + 'user/get_userdb'
export const USER_READMAP = B_W + 'all/all_map'

// Vendortypeinterface & Vendortype
export const VENDORTYPEINTERFACE_READ_RANDOMVENDORTYPE = B_W + 'vendortypeinterface/read_randomvendortypeinterface' // directly request from vendortypeinterface
const rviw = 'read_vendortype_infinitescrolldata_wed'
export const vendors_contentsizelink_wedding_map = { //get count and id etc
    All: B_W + 'vendortypeinterface/read_vendortypeinterface_infinitescrolldata_wed',
    Accessories: B_W + 'accessories/' + rviw,
    Bakeryanddesserts: B_W + 'bakeryanddesserts/' + rviw,
    Beautyhairandmakeup: B_W + 'beautyhairandmakeup/' + rviw,
    Bridalboutiques: B_W + 'bridalboutiques/' + rviw,
    Catering: B_W + 'catering/' + rviw,
    Dance: B_W + 'dance/' + rviw,
    Decoration: B_W + 'decoration/' + rviw,
    Dj: B_W + 'dj/' + rviw,
    Dressattire: B_W + 'dressattire/' + rviw,
    Emcees: B_W + 'emcees/' + rviw,
    Entertainment: B_W + 'entertainment/' + rviw,
    Eventrentals: B_W + 'eventrentals/' + rviw,
    Florists: B_W + 'florists/' + rviw,
    Gifts: B_W + 'gifts/' + rviw,
    Honeymoon: B_W + 'honeymoon/' + rviw,
    Jewellery: B_W + 'jewellery/' + rviw,
    Menswear: B_W + 'menswear/' + rviw,
    Music: B_W + 'music/' + rviw,
    Paperstationary: B_W + 'paperstationary/' + rviw,
    Photobooths: B_W + 'photobooths/' + rviw,
    Photographers: B_W + 'photographers/' + rviw,
    Transport: B_W + 'transport/' + rviw,
    Venue: B_W + 'venue/' + rviw,
    Videographers: B_W + 'videographers/' + rviw,
    Planners: B_W + 'planners/' + rviw,
}

const rvie = 'read_vendortype_infinitescrolldata_event'
export const vendors_contentsizelink_event_map = { //get count and id etc
    All: B_W + 'vendortypeinterface/read_vendortypeinterface_infinitescrolldata_event',
    Accessories: B_W + 'accessories/' + rvie,
    Beautyhairandmakeup: B_W + 'beautyhairandmakeup/' + rvie,
    Bridalboutiques: B_W + 'bridalboutiques/' + rvie,
    Catering: B_W + 'catering/' + rvie,
    Dance: B_W + 'dance/' + rvie,
    Decoration: B_W + 'decoration/' + rvie,
    Dj: B_W + 'dj/' + rvie,
    Dressattire: B_W + 'dressattire/' + rvie,
    Emcees: B_W + 'emcees/' + rvie,
    Entertainment: B_W + 'entertainment/' + rvie,
    Eventrentals: B_W + 'eventrentals/' + rvie,
    Florists: B_W + 'florists/' + rvie,
    Gifts: B_W + 'gifts/' + rvie,
    Honeymoon: B_W + 'honeymoon/' + rvie,
    Jewellery: B_W + 'jewellery/' + rvie,
    Menswear: B_W + 'menswear/' + rvie,
    Music: B_W + 'music/' + rvie,
    Paperstationary: B_W + 'paperstationary/' + rvie,
    Photobooths: B_W + 'photobooths/' + rvie,
    Photographers: B_W + 'photographers/' + rvie,
    Transport: B_W + 'transport/' + rvie,
    Venue: B_W + 'venue/' + rvie,
    Videographers: B_W + 'videographers/' + rvie,
    Bakeryanddesserts: B_W + 'bakeryanddesserts/' + rvie,
    Planners: B_W + 'planners/' + rvie,
}
// Vendortype
const uvs = 'update_vendortype_specific'
export const VENDORTYPE_VENDORSPECIFIC_UPDATE_MAP = {// vendorprofile vendor style and services filter tag map - {vendortype : api link}
    'Accessories': B_W + 'accessories/' + uvs,
    'Beautyhairandmakeup': B_W + 'beautyhairandmakeup/' + uvs,
    'Bridalboutiques': B_W + 'bridalboutiques/' + uvs,
    'Catering': B_W + 'catering/' + uvs,
    'Dance': B_W + 'dance/' + uvs,
    'Decoration': B_W + 'decoration/' + uvs,
    'Dj': B_W + 'dj/' + uvs,
    'Dressattire': B_W + 'dressattire/' + uvs,
    'Emcees': B_W + 'emcees/' + uvs,
    'Entertainment': B_W + 'entertainment/' + uvs,
    'Eventrentals': B_W + 'eventrentals/' + uvs,
    'Florists': B_W + 'florists/' + uvs,
    'Gifts': B_W + 'gifts/' + uvs,
    'Honeymoon': B_W + 'honeymoon/' + uvs,
    'Jewellery': B_W + 'jewellery/' + uvs,
    'Menswear': B_W + 'menswear/' + uvs,
    'Music': B_W + 'music/' + uvs,
    'Paperstationary': B_W + 'paperstationary/' + uvs,
    'Photobooths': B_W + 'photobooths/' + uvs,
    'Photographers': B_W + 'photographers/' + uvs,
    'Transport': B_W + 'transport/' + uvs,
    'Venue': B_W + 'venue/' + uvs,
    'Videographers': B_W + 'videographers/' + uvs,
    'Bakeryanddesserts': B_W + 'bakeryanddesserts/' + uvs,
    'Planners': B_W + 'videographers/' + uvs,
}

// Quote User
export const READ_U_QUOTE = B_W + 'quote/read_u_quote'
export const U_ACCEPT_QUOTE = B_W + 'quote/accept_u_quote'
export const U_REJECT_QUOTE = B_W + 'quote/reject_u_quote'
export const U_ARCHIVE_QUOTE = B_W + 'quote/archive_u_quote'
export const READARCHIVE_U_QUOTE = B_W + 'quote/u_archive_read'

// Board 
export const CREATE_BOARD = B_W + 'board/create_board'
export const UPDATE_BOARD = B_W + 'board/update_board'
export const REMOVE_BOARD = B_W + 'board/remove_board'
export const EDIT_BOARD_NAME = B_W + 'board/edit_boardname'
export const REMOVE_PI_FROM_BOARD = B_W + 'board/remove_pi_from_board'

// User Host
export const USER_READHOST = B_W + 'userhost/read_current'
export const USER_CREATEHOST = B_W + 'userhost/create_hosting'
export const USER_DELETESINGLEHOST = B_W + 'userhost/delete_host'
export const USER_ENQHOST = B_W + 'userhost/enq_host'
export const USER_ACCEPTUSER = B_W + 'userhost/accept_user'
export const USER_REJECTUSER = B_W + 'userhost/reject_user'
export const USER_READHOSTPUBLIC = B_W + 'userhost/read_single_public'

// Timeline
export const READ_SINGLETL = B_W + 'timeline/read_user_tl'
export const READ_TLSPROJ = B_W + 'timeline/read_user_tls'
export const EDIT_TLSPROJ = B_W + 'timeline/edit_user_tlpc'
export const CREATE_TIMELINE = B_W + 'timeline/createtl'
export const REMOVE_SINGLETIMELINE = B_W + 'timeline/remvsingletl'

export const READ_TIMELINE = B_W + 'timeline/readtl'
export const UPDATE_TIMELINE = B_W + 'timeline/updatetl'
export const UPDATE_TLPROFILEIMGLEFT = B_W + 'timeline/updatepimgleft'
export const UPDATE_TLPROFILEIMGRIGHT = B_W + 'timeline/updatepimgright'
export const UPDATE_TLPROFILEIMGONE = B_W + 'timeline/updatepimgone'
export const UPDATE_TLPROFILEIMGNONE = B_W + 'timeline/updateimgnone'
export const UPDATE_TLUPDATEHEART = B_W + 'timeline/updateheart'
export const UPDATE_TLPASSCODE = B_W + 'timeline/passcode'

export const UPDATE_TLCOUPLEIMG1 = B_W + 'timeline/updatecoupleimg1'
export const UPDATE_TLCOUPLEIMG2 = B_W + 'timeline/updatecoupleimg2'
export const UPDATE_TLONEIMG = B_W + 'timeline/updateoneimg'
export const REMOVE_TLCOMP = B_W + 'timeline/removetlcomp'
export const SAVE_TL = B_W + 'timeline/savetlcomp'
export const READ_ITINERARYLOOKUP = B_W + 'timeline/itinerarylookup'
export const LOGIN_TL = B_W + 'timeline/login'

// Invitation 
export const USER_READINVITATIONSPROJECTS = B_W + 'user/read_user_ics'// for user editable
export const USER_EDITICPASSCODE = B_W + 'user/edit_user_passcode'// for user editable
export const INVITATION_CREATE = B_W + 'invitationcard/createcard'// for user editable
export const INVITATION_DESTROY = B_W + 'user/remov_single_ic'// for user editable
export const INVITATION_SAVE = B_W + 'invitationcard/savecard'// for user editable
export const INVITATION_SAVENAV = B_W + 'invitationcard/savenav'// for user editable

export const INVITATION_READEDITINIT = B_W + 'invitationcard/readeditinit'// for user editable
export const INVITATION_LOOKUP = B_W + 'invitationcard/readspecificcard'
export const INVITATION_LOOKUP_GTPASS = B_W + 'invitationcard/readspecificcardpass'
export const INVITATION_LOOKUP_GTPASSGTUID = B_W + 'invitationcard/readspecificcarduidpass'
export const INVITATION_GUESTLIST_READ = B_W + 'invitationcard/guestlistread'
export const INVITATION_GUESTLIST_ADDGUEST = B_W + 'invitationcard/addguest'
export const INVITATION_GUESTLIST_RMVGUEST = B_W + 'invitationcard/removeguest'
export const INVITATION_GUESTLIST_EDITGUEST = B_W + 'invitationcard/editguest'
export const INVITATION_GUESTLIST_SAVERSVPQN = B_W + 'invitationcard/saversvpqn'
export const INVITATION_GUESTLIST_EDITRSVPQN = B_W + 'invitationcard/editrsvpqn'
export const INVITATION_GUESTLIST_SAVERSVPFOLLOWUPQN = B_W + 'invitationcard/saversvpfollowupqn'
export const INVITATION_GUESTLIST_EDITRSVPFOLLOWUPQN = B_W + 'invitationcard/editrsvpfollowupqn'

export const INVITATION_GUESTLIST_DELFOLLOWUPQN = B_W + 'invitationcard/delrsvpfollowupqn'
export const INVITATION_GUESTLIST_DELMAINQN = B_W + 'invitationcard/delrsvpqn'

export const INVITATION_GUESTLIST_SWAP = B_W + 'invitationcard/qnsswap'
export const INVITATION_GUESTLIST_ADDNEWTEMPLATE = B_W + 'invitationcard/addnewtemplates'
export const INVITATION_GUESTLIST_ADDCAT = B_W + 'invitationcard/addcat'
export const INVITATION_GUESTLIST_ADDGRP = B_W + 'invitationcard/addgrp'
export const INVITATION_GUESTLIST_ADDMSG = B_W + 'invitationcard/addmsg'
export const INVITATION_GUESTLIST_CHKGUESTID = B_W + 'invitationcard/checkguestid'// use to check guest id exist or not

export const INVITATION_GUESTLIST_RSVPANSUPDATE = B_W + 'invitationcard/answerrsvpupdate' // use to reply rsvp in ic 
export const INVITATION_GUESTLIST_RSVPANSADDNEW = B_W + 'invitationcard/answerrsvpaddnew' // use to reply rsvp in ic 

export const INVITATION_GUESTLIST_UDPATEEXISTINGRSVP = B_W + 'invitationcard/updateexistingrsvp' // use to reply rsvp in ic 
// export const INVITATION_GUESTLIST_RSVPANSADDNEW = B_W + 'invitationcard/answerrsvpaddnew' // use to reply rsvp in ic 

export const INVITATION_GUESTLIST_RSVPON = B_W + 'invitationcard/rsvpon'
export const INVITATION_GUESTLIST_RSVPOFF = B_W + 'invitationcard/rsvpoff'
export const INVITATION_GUESTLIST_CLEARALLCELLS = B_W + 'invitationcard/clearallcells'
export const INVITATION_GUESTLIST_CLEARPARTIALCELLS = B_W + 'invitationcard/clearpartialcells'

export const INVITATION_GUESTLIST_IMPORTCSV = B_W + 'invitationcard/importcsv'
export const INVITATION_ADDIMG = B_W + 'invitationcard/addimg'
export const INVITATION_RMVIMG = B_W + 'invitationcard/rmvimg'
export const INVITATION_SAVEIMGSWAP = B_W + 'invitationcard/swapimg'
export const INVITATION_SAVEMAINIMGPROP = B_W + 'invitationcard/savemainimgprop'
export const INVITATION_SAVETEMPLATE = B_W + 'invitationcard/savetemplate'
export const INVITATION_SAVETEMPLATEFIRSTTIME = B_W + 'invitationcard/savetemplatefirsttime'
export const INVITATION_SAVEBACKGROUND = B_W + 'invitationcard/savebackground'
export const INVITATION_SAVEBORDER = B_W + 'invitationcard/saveborder'
export const INVITATION_SAVEBANNER = B_W + 'invitationcard/savebanner'
export const INVITATION_SAVEBGCOLOR = B_W + 'invitationcard/savebgcolor'
export const INVITATION_RSVPALT = B_W + 'invitationcard/rsvpalt'
export const INVITATION_READNAVAPP = B_W + 'invitationcard/readnavapp'
export const INVITATION_UPDATETABLCOLWIDTH = B_W + 'invitationcard/tablecolumnwidth'
export const INVITATION_GUESTLIST_UPDATESTATUS = B_W + 'invitationcard/updatestatus'
export const INVITATION_SWAPSAVING = B_W + 'invitationcard/updateswapguestqns'
export const INVITATION_SETTINGUPDATE = B_W + 'invitationcard/updatesetting'
export const INVITATION_SCHEDULESAVE = B_W + 'invitationcard/updateschedule'
export const INVITATION_SCHEDULERMV = B_W + 'invitationcard/rmvsingleschedule'
export const INVITATION_SCHEDULESWAP = B_W + 'invitationcard/swapsingleschedule'
export const INVITATION_SCHEDULEEDIT = B_W + 'invitationcard/editexistingschedule'
export const INVITATION_RETRIEVEITINERARIES = B_W + 'invitationcard/retrieveitineraries'

export const INVITATION_DNAME = B_W + 'invitationcard/dname' // Details 
export const INVITATION_DFONTS = B_W + 'invitationcard/dfonts' // Details 
export const INVITATION_DJOURNEY = B_W + 'invitationcard/djourney' // Details 
export const INVITATION_DLOCATION = B_W + 'invitationcard/dlocation' // Details 
export const INVITATION_DDATETIME = B_W + 'invitationcard/ddatetime' // Details 
export const INVITATION_DQNA = B_W + 'invitationcard/dqna' // Details 
export const INVITATION_DCONTACTS = B_W + 'invitationcard/dcontacts' // Details 
export const INVITATION_DPROMOTE = B_W + 'invitationcard/dpromote' // Details 
export const INVITATION_DNOTIFICATION = B_W + 'invitationcard/dnotif' // Details 
export const INVITATION_DSHARE = B_W + 'invitationcard/dshare' // Details 
export const INVITATION_DSCHEDULES = B_W + 'invitationcard/dschedules' // Details 
export const INVITATION_DOPENINGS = B_W + 'invitationcard/dopenings' // Details 
export const INVITATION_DREGISTRY = B_W + 'invitationcard/dregistry' // Details 
export const INVITATION_DSEQUENCE = B_W + 'invitationcard/dsequence' // Details


export const INVITATION_UPDATESINGLEPARTYMEMBER = B_W + 'invitationcard/updatesinglepartymember' // Details
export const INVITATION_EDITEXISTINGPARTYMEMBER = B_W + 'invitationcard/editexistingpartymember' // Details


// in use
export const INVITATION_DSINGLEPARTYMEMBERCREATE = B_W + 'invitationcard/createsinglepartymember' // Details
export const INVITATION_DSINGLEPARTYMEMBERREMOVE = B_W + 'invitationcard/removesinglepartymember' // Details
export const INVITATION_DSINGLEPARTYSWAPANDPROP = B_W + 'invitationcard/updatepartyswapandprop' // Details
export const INVITATION_UPDATESINGLEPARTYMEMBERWITHIMG = B_W + 'invitationcard/updatesinglepartymemberwithimg' // Details
export const INVITATION_UPDATESINGLEPARTYMEMBERWITHOUTIMG = B_W + 'invitationcard/updatesinglepartymemberwithoutimg' // Details




// QR code
export const USER_READQRPROJS = B_W + 'user/read_user_qrs' // for  qrmaker
export const QR_CREATE = B_W + 'qr/createqr' // for qrmaker
export const READ_SINGLEQR = B_W + 'qr/readqr' // qredit
export const SAVE_SINGLEQR = B_W + 'qr/saveqr' // qredit
export const REMOVE_QRCOMP = B_W + 'qr/rmvqrcomp'
export const QR_DELETE = B_W + 'qr/deleteqr'
export const UPDATE_QRPASSCODE = B_W + 'qr/updatepasscode'
export const LOGIN_QR = B_W + 'qr/login'
export const READ_QRLOOKUP = B_W + 'qr/readqrlookup'

// Search
export const SEARCH_V = B_W + 'all/searchv'
export const SEARCH_PL = B_W + 'all/searchpl'
export const SEARCH_PROJ = B_W + 'all/searchproj'

// Faq
export const READ_FAQ = B_W + 'faq/readuser'

// Blog
export const READ_BLOG = B_W + 'blog/readall'

// Budgetmaker
export const READ_BUDGET = B_W + 'budget/readbudget'
export const READ_SINGLEBUDGET = B_W + 'budget/readsinglebudget'
export const UPDATE_BUDGET = B_W + 'budget/updatebudget'
export const CREATE_BUDGET = B_W + 'budget/createbudget'
export const REMOVE_SINGLEBUDGET = B_W + 'budget/removesinglebudget'

// Budget
export const UPDATE_BUDGETADDITEM = B_W + 'budget/additem'
export const UPDATE_BUDGETADDSECTION = B_W + 'budget/addsection'
export const UPDATE_BUDGETARCHIVEITEM = B_W + 'budget/archiveitem'
export const UPDATE_BUDGETITEM = B_W + 'budget/updateitem'
export const UPDATE_BUDGETSECTION = B_W + 'budget/updatesection'
export const UPDATE_BUDGETNAME = B_W + 'budget/updatename'
export const UPDATE_BUDGETSETTINGS = B_W + 'budget/updatesettings'
export const REMOVE_BUDGETDELETEITEM = B_W + 'budget/deleteitem'
export const REMOVE_BUDGETDELETESECTION = B_W + 'budget/deletesection'
export const UPDATE_BUDGETSWAP = B_W + 'budget/updateswap'
export const UPDATE_BUDGETUNARCHIVEITEM = B_W + 'budget/unarchiveitem'
export const REMOVE_BUDGETDELETEARCHIVEITEM = B_W + 'budget/deletearchiveitem'
export const UPDATE_BUDGETCHANGETEMPLATE = B_W + 'budget/changetemplate'
export const UPDATE_BUDGETTOTAL = B_W + 'budget/updatetotalbudget'

// Checklist
export const READ_CHECKLIST = B_W + 'checklist/readchecklist'
export const READ_SINGLECHECKLIST = B_W + 'checklist/readsinglechecklist'
export const UPDATE_CHECKLIST = B_W + 'checklist/updatechecklist'
export const CREATE_CHECKLIST = B_W + 'checklist/createchecklist'
export const REMOVE_SINGLECHECKLIST = B_W + 'checklist/remvsinglechecklist'
export const READ_CHECKLISTLOOKUP = B_W + 'checklist/checklistlookup'
export const UPDATE_CHECKLISTSETTING = B_W + 'checklist/updatesettings'

export const UPDATE_CHECKLISTITEM = B_W + 'checklist/updatechecklistitem'
export const UPDATE_CHECKLISTNEWITEM = B_W + 'checklist/updatechecklistnewitem'
export const UPDATE_CHECKLISTNEWSEC = B_W + 'checklist/updatechecklistnewsec'
export const UPDATE_CHECKLISTCAT = B_W + 'checklist/updatechecklistcat'
export const UPDATE_CHECKLISTNAME = B_W + 'checklist/updatechecklistname'
export const DELETE_CHECKLISTITEM = B_W + 'checklist/deletechecklistitem'
export const DELETE_CHECKLISTCAT = B_W + 'checklist/deletechecklistcat'
export const UPDATE_CHECKLISTSWAP = B_W + 'checklist/updatechecklistswap'

export const UPDATE_CHECKLISTDONE = B_W + 'checklist/updatechecklistdone'
export const UPDATE_CHECKLISTARCHIVE = B_W + 'checklist/updatechecklistarchive'
export const UPDATE_CHECKLISTPORTALARCHIVE = B_W + 'checklist/updatechecklistportalarchive'

export const UPDATE_REVERTARCHIVEITEM = B_W + 'checklist/updaterevertarchiveitem'
export const UPDATE_DELETEARCHIVEITEM = B_W + 'checklist/updatedeletearchiveitem'
export const UPDATE_CHECKLISTTEMPLATE = B_W + 'checklist/updatechecklisttemplate'

// Seatchart
export const READ_SC = B_W + 'seat/readseat'
export const UPDATE_SC = B_W + 'seat/updateseat'
export const CREATE_SC = B_W + 'seat/createseat'
export const REMOVE_SINGLESC = B_W + 'seat/remvsingleseat'
export const USER_EDITSEATPASSCODE = B_W + 'user/edit_user_seatpasscode'
export const READ_SCEDIT = B_W + 'seat/readeditinit'
export const READ_SCLOOKUP = B_W + 'seat/sclookup'
export const READ_SCLOOKUPGTPASS = B_W + 'seat/sclookupgtpass'
export const READ_INVGUEST = B_W + 'seat/readinvguestlist'
export const REPLACE_SCDATA = B_W + 'seat/replacescdata'

export const UPDATE_SINGLEGUEST = B_W + 'seat/updatesingleguest'
export const UPDATE_SINGLETABLE = B_W + 'seat/updatesingletable'
export const RMV_SINGLEGUEST = B_W + 'seat/rmvsingleguest'
export const UPDATE_SWAP = B_W + 'seat/updateswap'
export const RMV_SINGLETABLE = B_W + 'seat/rmvsingletable'
export const UPDATE_TEMPLATE = B_W + 'seat/updatedummy'

// Timelinelookup
export const READ_TLLOOKUP = B_W + 'timeline/tllookup'
export const READ_TLLOOKUPGTPASS = B_W + 'timeline/tllookupgtpass'

// Guestbook
export const READ_GB = B_W + 'guestbook/readinit'
export const RMV_GB = B_W + 'guestbook/remove'
export const EDITPW_GB = B_W + 'guestbook/editpw'
export const CREATE_GB = B_W + 'guestbook/create'
export const EDITPWMOD_GB = B_W + 'guestbook/editpwmod'

// Guestbookcast
export const READ_GBC1 = B_W + 'guestbook/gbconeinit'
export const READ_GBC2 = B_W + 'guestbook/gbctwoinit'
export const READ_NEWGBC1 = B_W + 'guestbook/gbcnewone'
export const READ_NEWGBC2 = B_W + 'guestbook/gbcnewtwo'

// Guestbook
export const ADDMSG_GBINIT = B_W + 'guestbook/initgb'
export const ADDMSG_GB = B_W + 'guestbook/addnewmsg'
export const ADDMSG_GBSINGLEMEDIA = B_W + 'guestbook/addnewmsgone'
export const ADDMSG_GBTWOMEDIA = B_W + 'guestbook/addnewmsgtwo'

// Guestbookmod
export const READ_GBMOD = B_W + 'guestbook/modinit'
export const RMV_GBMODMSG = B_W + 'guestbook/modrmvmsg'
export const ADD_GBMODHIDEMSG = B_W + 'guestbook/modaddhide'
export const READ_GBMODNEW = B_W + 'guestbook/modreadnew'
export const UPDATE_GBMODCT = B_W + 'guestbook/modupdatecasttype'
export const UPDATE_GBMODMT = B_W + 'guestbook/modupdatemsgtype'

// Guestbookedit
export const READ_GBEDIT = B_W + 'guestbook/gbeditinit'
export const RMV_GBEDITMSG = B_W + 'guestbook/gbeditrmvmsg'
export const UPDATE_GBEDITMODPC = B_W + 'guestbook/gbupdatemodepc'
export const UPDATE_GBEDITCASTPC = B_W + 'guestbook/gbupdatecastpc'
export const UPDATE_GBEDITCASTTYPE = B_W + 'guestbook/gbupdatecasttype'
export const UPDATE_GBEDITMSGTYPE = B_W + 'guestbook/gbupdatemsgtype'
export const UPDATE_GBEDITGALLERYIMG = B_W + 'guestbook/gbupdategallery'
export const RMV_GBEDITGALLERYIMG = B_W + 'guestbook/gbrmvgalleryimg'
export const ADD_GBARTIMG = B_W + 'guestbook/gbaddartimg'
export const RMV_GBARTIMG = B_W + 'guestbook/gbrmvartimg'
export const UPDATE_GBARTSAVE = B_W + 'guestbook/gbupdateartsave'
export const PURCHASE_GBS = B_W + 'guestbook/gbupdatepurchases' // purchase soft
export const PURCHASE_GBSH = B_W + 'guestbook/gbupdatepurchasesh' // purchase softandhard
export const PURCHASE_GBDISCOUNT = B_W + 'guestbook/gbupdatepurchasediscount' // purchase soft 100% discount
export const PURCHASE_MM = B_W + 'guestbook/gbupdatemmpurchase' // purchase with metamask
export const UPDATE_ARTTYPE = B_W + 'guestbook/gbupdatearttype'

// Guestbooklookup
export const READ_GBLOOKUP = B_W + 'guestbook/readlookup'
export const LOGIN_GBLOOKUP = B_W + 'guestbook/login'

// Planning
export const UPDATE_PLANNINGSEQ = B_W + 'user/planningseq'

// Tools
export const READ_USER_TOOLDETAILS = B_W + 'tool/readdetails'

export const READ_NONUSER_AUSCIPIOUSTOOL = B_W + 'tool/readaustool'

export const READ_USER_MAINTOOL = B_W + 'tool/userreadtoolmain'
export const READ_NONUSER_MAINTOOL = B_W + 'tool/nonuserreadtoolmain'

export const READ_USER_BUDGETESTIMATORTOOL = B_W + 'tool/userreadtoolbud'
export const READ_NONUSER_BUDGETESTIMATORTOOL = B_W + 'tool/nonuserreadtoolbud'

// Market
export const READ_MARKETLISTING = B_W + 'market/readlistings'
export const READ_MARKETLISTINGU = B_W + 'market/readlistingsu'
export const READ_USERMARKETLISTING = B_W + 'market/readuserlisting'
export const DELETE_USERMARKETLISTINGSINGLE = B_W + 'market/removesinglelisting'
export const ADDLISTING_MARKETLISTINGIMG = B_W + 'market/addsinglelistingimg'
export const ADDLISTING_MARKETLISTING = B_W + 'market/addsinglelisting'

// Marketlookup
export const READ_MARKETLOOKUP = B_W + 'market/readsingleentity'

// Quiz
export const UPDATE_QUIZUSERINTERPRET = B_W + 'quiz/interpretuserquiz'

// External script
export const ETHERJS = S3CLOUD_CLOUDFRONT + 'scripts/ethers-5.2.esm.min.js'

// Comparevenue
export const READ_COMPAREWEDDINGVENUE = B_W + 'compare/readcompareweddingvenue'
export const READ_COMPAREWEDDINGVENUEUSER = B_W + 'compare/readcompareweddingvenueuser'
export const UPDATE_ENQUIRENONUSER = B_W + 'compare/enquirenonuser'
export const UPDATE_ENQUIREUSER = B_W + 'compare/enquireuser'

// Comparevenue based on social media src
export const READ_COMPAREWEDDINGVENUENONUSER = B_W + 'compare/readcompareweddingvenuenonuser' // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_TIKTOK= B_W + 'compare/readcompareweddingvenuenonusertt' // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_INSTAGRAM = B_W + 'compare/readcompareweddingvenuenonuseri' // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_THREAD = B_W + 'compare/readcompareweddingvenuenonuserth' // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_FACEBOOK = B_W + 'compare/readcompareweddingvenuenonuserf' // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_YOUTUBE = B_W + 'compare/readcompareweddingvenuenonusery' // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_REDDIT = B_W + 'compare/readcompareweddingvenuenonuserr' // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_PINTEREST = B_W + 'compare/readcompareweddingvenuenonuserp' // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_LINKEDIN = B_W + 'compare/readcompareweddingvenuenonuserl' // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_TWITTER = B_W + 'compare/readcompareweddingvenuenonusert'  // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_TELEGRAM = B_W + 'compare/readcompareweddingvenuenonuserte'  // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_WARPCAST = B_W + 'compare/readcompareweddingvenuenonuserwc'  // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_BLUESKY = B_W + 'compare/readcompareweddingvenuenonuserbs'  // Comparevenue based on social media
export const READ_COMPAREWEDDINGVENUENONUSER_HWZ = B_W + 'compare/readcompareweddingvenuenonuserthwz'  // Comparevenue based on social media


// Inbox - Inquiries
export const READ_INQUIRIES = B_W + 't/readinquiries'


// Inbox - Message
export const READ_CONV_VENDOR_START = B_W + 'conversation/vendorstart'
export const READ_CONV_USER_START = B_W + 'conversation/userstart'
export const READ_CONV_SPECIFIC_RM_VENDOR = B_W + 'conversation/vendorreadconvspecificrm'
export const READ_CONV_SPECIFIC_RM_USER = B_W + 'conversation/userreadconvspecificrm'
export const INIT_CONV_USER = B_W + 'conversation/initconvbyuser'
export const INIT_CONV_VENDOR = B_W + 'conversation/initconvbyvendor'
export const REMOV_CONV_USER = B_W + 'conversation/removeconvuser'
export const REMOV_CONV_VENDOR = B_W + 'conversation/removeconvvendor'