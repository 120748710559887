import React, { useState } from 'react'
import './Numtable.css'

function Numtable(props) {
    const [totaltables, setTotaltables] = useState(3) // 3 - 12 tables
    const [tablecss, setTablecss] = useState('SMALL')  // SMALL or LARGE
    const [tableformat, setTableformat] = useState('3_0') // table format 

    const tablearrfunc = (e) => {
        const { value } = e.target
        setTableformat(value)
    }

    const table_arrangment = {
        3: [
            <button key="Numtable3_0" style={{ margin: '0px 5px 0px 10px' }} className={`Numtable3_0 Numtable_singletable ${tableformat === '3_0' ? 'Numtable_selected' : ''}`} value={'3_0'} onClick={tablearrfunc}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50%', pointerEvents: 'none' }}>
                    <div style={{ width: '50%', height: '100%', borderRight: '1px solid black', }} />
                    <div style={{ width: '50%', height: '100%', pointerEvents: 'none' }} />
                </div>
                <div style={{ width: '100%', height: '50%', borderTop: '1px solid black', pointerEvents: 'none' }} />
            </button>,
            <button key="Numtable3_1" style={{ margin: '0px 10px 0px 5px' }} className={`Numtable3_1 Numtable_singletable ${tableformat === '3_1' ? 'Numtable_selected' : ''}`} value={'3_1'} onClick={tablearrfunc}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', pointerEvents: 'none' }}>
                    <div style={{ width: '50%', height: '100%', borderRight: '1px solid black' }} />
                    <div style={{ width: '50%', height: '100%', borderRight: '1px solid black' }} />
                    <div style={{ width: '50%', height: '100%' }} />
                </div>
            </button>
        ],
        4: [
            <button key="Numtable4_0" className={`Numtable4_1 Numtable_singletable ${tableformat === '4_0' ? 'Numtable_selected' : ''}`} value={'4_0'} onClick={tablearrfunc}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '50%', height: '50%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '50%', height: '50%', borderBottom: '1px solid black' }} />
                    <div style={{ width: '50%', height: '50%', borderRight: '1px solid black' }} />
                    <div style={{ width: '50%', height: '50%' }} />
                </div>
            </button>
        ],
        5: [
            <button key="Numtable5_0" className={`Numtable5_0 Numtable_singletable ${tableformat === '5_0' ? 'Numtable_selected' : ''}`} value={'5_0'} onClick={tablearrfunc}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '33.33%', height: '50%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '50%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '50%', borderBottom: '1px solid black' }} />
                    <div style={{ width: '50%', height: '50%', borderRight: '1px solid black' }} />
                    <div style={{ width: '50%', height: '50%' }} />
                </div>
            </button>
        ],
        6: [
            <button key="Numtable6_0" className={`Numtable6_0 Numtable_singletable ${tableformat === '6_0' ? 'Numtable_selected' : ''}`} value={'6_0'} onClick={tablearrfunc}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '33.33%', height: '50%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '50%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '50%', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '50%', borderRight: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '50%', borderRight: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '50%' }} />
                </div>
            </button>
        ],
        7: [
            <button key="Numtable7_0" className={`Numtable7_0 Numtable_singletable ${tableformat === '7_0' ? 'Numtable_selected' : ''}`} value={'7_0'} onClick={tablearrfunc}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', }} />
                </div>
            </button>,
        ],
        8: [
            <button key="Numtable8_0" style={{ margin: '0px 5px 0px 10px' }} className={`Numtable8_0 Numtable_singletable ${tableformat === '8_0' ? 'Numtable_selected' : ''}`} value={'8_0'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1 1
                    1 1 1 1
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '25%', height: '50%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '50%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '50%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '50%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '50%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '50%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '50%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '50%' }} />
                </div>
            </button>,
            <button key="Numtable8_1" style={{ margin: '0px 10px 0px 5px' }} className={`Numtable8_1 Numtable_singletable ${tableformat === '8_1' ? 'Numtable_selected' : ''}`} value={'8_1'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1
                    1 1 1
                    1 1 
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', }} />
                </div>
            </button>
        ],
        9: [
            <button key="Numtable9_0" style={{ margin: '0px 5px 0px 10px' }} className={`Numtable9_0 Numtable_singletable ${tableformat === '9_0' ? 'Numtable_selected' : ''}`} value={'9_0'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1
                    1 1 1
                    1 1 1
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '33.33%', height: '33.33%', }} />
                </div>
            </button>,
            <button key="Numtable9_1" style={{ margin: '0px 10px 0px 5px' }} className={`Numtable9_1 Numtable_singletable ${tableformat === '9_1' ? 'Numtable_selected' : ''}`} value={'9_1'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1 1
                    1 1 1 1
                    1 
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%' }} />
                </div>
            </button>
        ],
        10: [
            <button key="Numtable10_0" className={`Numtable10_0 Numtable_singletable ${tableformat === '10_0' ? 'Numtable_selected' : ''}`} value={'10_0'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1 1
                    1 1 1 1
                    1 1
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%' }} />
                </div>
            </button>
        ],
        11: [
            <button key="Numtable11_0" className={`Numtable11_0 Numtable_singletable ${tableformat === '11_0' ? 'Numtable_selected' : ''}`} value={'11_0'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1 1
                    1 1 1 1
                    1 1 1
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%' }} />
                </div>
            </button>
        ],
        12: [
            <button key="Numtable12_0" className={`Numtable12_0 Numtable_singletable ${tableformat === '12_0' ? 'Numtable_selected' : ''}`} value={'12_0'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1 1
                    1 1 1 1
                    1 1 1 1
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '33.33%' }} />
                </div>
            </button>
        ],
        13:[
            <button key="Numtable12_0" className={`Numtable12_0 Numtable_singletable ${tableformat === '13_0' ? 'Numtable_selected' : ''}`} value={'13_0'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1 1
                    1 1 1 1
                    1 1 1 1
                    1 
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%' }} />
                </div>
            </button>
        ],
        14:[
            <button key="Numtable12_0" className={`Numtable12_0 Numtable_singletable ${tableformat === '14_0' ? 'Numtable_selected' : ''}`} value={'14_0'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1 1
                    1 1 1 1
                    1 1 1 1
                    1 1
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%' }} />
                </div>
            </button>
        ],
        15:[
            <button key="Numtable12_0" className={`Numtable12_0 Numtable_singletable ${tableformat === '15_0' ? 'Numtable_selected' : ''}`} value={'15_0'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1 1
                    1 1 1 1
                    1 1 1 1
                    1 1 1
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%' }} />
                </div>
            </button>
        ],
        16:[
            <button key="Numtable12_0" className={`Numtable12_0 Numtable_singletable ${tableformat === '16_0' ? 'Numtable_selected' : ''}`} value={'16_0'} onClick={tablearrfunc}>
                {/* {` 
                    1 1 1 1
                    1 1 1 1
                    1 1 1 1
                    1 1 1 1
                `} */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap', pointerEvents: 'none' }}>
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black', borderBottom: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderBottom: '1px solid black' }} />

                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%', borderRight: '1px solid black' }} />
                    <div style={{ width: '25%', height: '25%' }} />
                </div>
            </button>
        ]
    }

    return <div className='Numtable_root'>
        <div className='Scdesign_seatmodal'>
            <div>
                <div className='Scdesign_seatmodalcol'>
                    <div className='Scdesign_title'>Number Of Tables</div>
                    <div className='Scdesign_seatmodalrow'>
                        <button className='Scdesign_seatminus' onClick={() => {
                            const val = totaltables < 4 ? 16 : totaltables - 1
                            setTotaltables(val)
                            setTableformat(table_arrangment[val][0].props.value)
                        }}>-</button>
                        <div className='Scdesign_searmodalnum'>{totaltables}</div>
                        <button className='Scdesign_seatplus' onClick={() => {
                            const val = totaltables > 15 ? 3 : totaltables + 1
                            setTotaltables(val)
                            setTableformat(table_arrangment[val][0].props.value)
                        }}>+</button>
                    </div>
                </div>
                <div className='Scdesign_seatmodalcol' style={{ borderTop: '1px solid black', display:'none' }}>
                    <div className='Scdesign_title'>Font Size</div>
                    <div className='Scdesign_seatmodalrow'>
                        <button className={`Numtable_smalllarge ${tablecss === 'SMALL' ? 'Numtable_selected' : ''}`} style={{ borderRight: 'none' }} value='SMALL' onClick={(e) => { setTablecss(e.target.value) }}>Small</button>
                        <button className={`Numtable_smalllarge ${tablecss === 'LARGE' ? 'Numtable_selected' : ''}`} style={{ borderRadius: 5, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderRight: '1px solid black', borderLeft: 'none' }} value='LARGE' onClick={(e) => { setTablecss(e.target.value) }}>Large</button>
                    </div>
                </div>
                <div className='Numtable_seatcontainer'>
                    <div className='Scdesign_title' style={{ paddingBottom: 15 }}>Table Format</div>
                    <div className='Numtable_seatgrid'>
                        {table_arrangment[totaltables].map((tb, index) => {
                            return <div key={index + 'tb'} className='Numtable_tableselectroot'>{tb}</div>
                        })}
                    </div>
                </div>
            </div>
            <div className='Numtable_btnrow'>
                <button className='Numtable_cancel' onClick={() => { props.cancel() }}>Cancel</button>
                <button className='Numtable_create' onClick={() => { props.create(totaltables, tablecss, tableformat) }}>Create</button>
            </div>

        </div>
    </div>
}
export default Numtable