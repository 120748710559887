import React, { useEffect, useState } from 'react'
import { iconwrapper } from '../../../../../../config/commonfunctions';
import { ADD_ICON, BLOCKLARGE_ICON, CROSS_ICON, DRAG_ICON } from '../../../../../../config/svgicons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Modcfm } from '../../../../../../components';
import Explain from "../Explain";

const dragsvgcss = { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5 }
const crosssvgcss = { ...dragsvgcss, color: 'var(--mainpink)' }
const addsvgcss = { width: 23, height: 23, display: 'flex', placeContent: 'center', }
const blocksvgcss = { ...dragsvgcss, color: 'var(--subdark)' }

const TITLE = 'Promote'
const TEXT = `Give credit to the vendors who have helped in your wedding.`

const DEFAULT_PAGE = 0
const SWAP_OR_EDIT_PAGE = 1

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#707070' : 'white',
    padding: isDraggingOver ? 5 : 0,
});

function Promote(props) {
    const { device, partialwt, showsave, setShowsave, updateBackup, clearBackup, restoreBackup, updatePromote, savePromote } = props

    const [firsttime, setFirsttime] = useState(true)
    const [page, setPage] = useState(DEFAULT_PAGE) // 0 : default schedule page, 1 : swapping page, 2 : create schedule page, 3 : edit page

    // Promote obj
    const [promotedata, setPromotedata] = useState([])

    // remove 
    const [rmvindex, setRmvindex] = useState(-1) // index to remove
    const [rmvcfm, setRmvcfm] = useState(false) // user click cfm or not cfm delete
    const [showrmvmod, setShowrmvmod] = useState(false) // toggle rmv modal to qn user

    useEffect(() => {
        if (firsttime) {
            setPromotedata(props.promotedata.slice())
            setFirsttime(false)
            setPage(DEFAULT_PAGE)
        }
    }, [])

    useEffect(() => {
        if (!firsttime) {
            setPromotedata(props.promotedata.slice())
        }
    }, [props.promotedata])

    useEffect(() => {
        if (rmvcfm) {
            var newpromote = JSON.parse(JSON.stringify(promotedata))
            newpromote.splice(rmvindex, 1)
            newpromote = flattenpromote(newpromote)
            savePromote(newpromote, 'remove').then((result) => {
                if (result === 'SUCCESS') {
                    setPage(DEFAULT_PAGE)
                    setShowsave(false)
                    clearBackup()
                    setRmvcfm(false)
                }
            })
        }
    }, [rmvcfm])

    const flattenpromote = (data) => {
        var new_promote = []
        for (let i = 0; i < data.length; i++) {
            const { vendorname, link, vendortype } = data[i]
            new_promote.push(vendorname)
            new_promote.push(link)
            new_promote.push(vendortype)
        }
        return new_promote
    }

    const onDragend = (result) => {
        const { source, destination } = result
        if (!destination) { return }
        else {
            if (page !== SWAP_OR_EDIT_PAGE && source.index !== destination.index) {
                setPage(SWAP_OR_EDIT_PAGE)
            }
            else if (page === SWAP_OR_EDIT_PAGE && source.index === destination.index) {
                return
            }
            if (!showsave) { updateBackup(); setShowsave(true); }
            let curr_promotedata = promotedata.slice()
            const [removed] = curr_promotedata.splice(source.index, 1);
            curr_promotedata.splice(destination.index, 0, removed);
            updatePromote(flattenpromote(curr_promotedata))
            setPromotedata(curr_promotedata)
        }
    }

    const revert = () => {
        setShowsave(false)
        restoreBackup()
        setPage(DEFAULT_PAGE)
    }

    const create = (action) => {
        savePromote([...flattenpromote(promotedata), "", "", ""], action).then((result) => {
            if (result === 'SUCCESS') {
                setPage(DEFAULT_PAGE)
                setShowsave(false)
                clearBackup()
            }
        })
    }

    const update = (action) => {
        savePromote(flattenpromote(promotedata), action).then((result) => {
            if (result === 'SUCCESS') {
                setPage(DEFAULT_PAGE)
                setShowsave(false)
                clearBackup()
            }
        })
    }

    const remove = (i) => {
        setRmvindex(i);
        setShowrmvmod(true)
    }

    const btmbarstyle = {
        'MOBILE': { position: 'fixed', bottom: 0, left: 0, width: '100%' },
        'TABLET': { position: 'fixed', bottom: 10, left: 0, width: partialwt - 1 },
        'WEB': { position: 'fixed', bottom: 0, left: 0, width: partialwt - 1 }
    }[device]

    const btmbar = {
        // display schedule
        0: <div className='iedit_swaproot' style={btmbarstyle}>
            <button className='iedit_add' onClick={() => create('add')}>{iconwrapper(ADD_ICON, addsvgcss)}</button>
        </div>,
        // swap schedule
        1: <div className='iedit_swaproot' style={btmbarstyle}>
            <button className='iedit_cancel' value={'swapcancel'} onClick={revert}>Revert</button>
            <button className='iedit_save' onClick={() => update('update')}>Save</button>
        </div>,
    }[page]

    const dnd = <div>
        <DragDropContext onDragEnd={onDragend}>
            <Droppable droppableId={'droppable'} >
                {(provided, snapshot) => (
                    <div className='Detailsdrag_root' ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        {promotedata.map((item, x) => {
                            const { vendorname, link, vendortype } = item
                            const i = x
                            return <Draggable key={x + '_'} draggableId={x + 'id'} index={i}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <div className="Iedit_threeinputs" key={i + 'promote'} >
                                            {page !== SWAP_OR_EDIT_PAGE
                                                ? <span className='Detailsdrag_closeicon' onClick={() => remove(i)}>{iconwrapper(CROSS_ICON, crosssvgcss)}</span>
                                                : <span className='Detailsdrag_closeicon'>{iconwrapper(BLOCKLARGE_ICON, blocksvgcss)}</span>
                                            }
                                            <div className='Detailsdrag_col'>
                                                <input
                                                    className="Iedit_input Iedit_topinput"
                                                    value={vendorname}
                                                    placeholder={'Vendor Name'}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        if (!showsave) { updateBackup(); setShowsave(true); setPage(SWAP_OR_EDIT_PAGE) }
                                                        setPromotedata((promotedata) => {
                                                            var new_promotedata = JSON.parse(JSON.stringify(promotedata.slice()))
                                                            new_promotedata[i]['vendorname'] = e.target.value
                                                            updatePromote(flattenpromote(new_promotedata))
                                                            return new_promotedata
                                                        })

                                                    }}
                                                />
                                                <input
                                                    className="Iedit_input Iedit_midinput"
                                                    value={link}
                                                    placeholder={'Url / Instagram IG / Telegram etc'}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        if (!showsave) { updateBackup(); setShowsave(true); setPage(SWAP_OR_EDIT_PAGE) }
                                                        setPromotedata((promotedata) => {
                                                            var new_promotedata = JSON.parse(JSON.stringify(promotedata.slice()))
                                                            new_promotedata[i]['link'] = e.target.value
                                                            updatePromote(flattenpromote(new_promotedata))
                                                            return new_promotedata
                                                        })
                                                    }}
                                                />
                                                <input
                                                    className="Iedit_input Iedit_btminput"
                                                    value={vendortype}
                                                    placeholder={'Vendor Type eg. Photographer, Videographer ...'}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        if (!showsave) { updateBackup(); setShowsave(true); setPage(SWAP_OR_EDIT_PAGE) }
                                                        setPromotedata((promotedata) => {
                                                            var new_promotedata = JSON.parse(JSON.stringify(promotedata.slice()))
                                                            new_promotedata[i]['vendortype'] = e.target.value
                                                            updatePromote(flattenpromote(new_promotedata))
                                                            return new_promotedata
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <span className='Detailsdrag_dragicon' {...provided.dragHandleProps}>
                                                {iconwrapper(DRAG_ICON, dragsvgcss)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        {btmbar}
    </div>

    // const maincontent = {// 0 : default schedule page, 1 : swapping page, 2 : create schedule page, 3 : edit page
    //     0: dnd,
    //     1: dnd,
    //     2: <div style={{ display: 'flex', flexDirection: 'column' }}>
    //         <div style={{ padding: 10, margin: '5px 0px 5px 0px', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', border: '1px solid rgb(230, 230, 230)', borderRadius: 5 }}>

    //         </div>
    //         {btmbar}
    //     </div>,
    //     3: <div style={{ display: 'flex', flexDirection: 'column' }}>
    //         <div style={{ padding: 10, margin: '5px 0px 5px 0px', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', border: '1px solid rgb(230, 230, 230)', borderRadius: 5 }}>

    //         </div>
    //         {btmbar}
    //     </div>
    // }

    return (
        <div
            className='Ieditcomp_div'
            style={{
                height: {
                    'WEB': 'calc(100% - 70px - 65px)',
                    'TABLET': 'calc(100% - 70px - 65px)',
                    'MOBILE': 'calc(100% - 70px)'
                }[device],
                marginBottom: {
                    'MOBILE': 65
                }[device]
            }}>
            <Explain title={TITLE} txt={TEXT} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {dnd}
            </div>
            {showrmvmod
                ? <Modcfm
                    onHide={() => { setShowrmvmod(false) }}
                    onOk={() => { setShowrmvmod(false); setRmvcfm(true); }}
                    onNo={() => { setShowrmvmod(false); setRmvcfm(false); setRmvindex(-1); }}
                    txt={'Once you delete this, there is no going back. Please be certain.'}
                />
                : null}
        </div>
    )
}

export default Promote